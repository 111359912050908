.contact_us_page {
   h6 {
      color: #475467;
   }
   h5 {
      color: #0c111d;
      font-size: 34px;
      font-weight: 400;
      text-transform: capitalize;
      margin: 10px 0;
      span {
         color: #7b5ea5;
         font-size: 36px;
         font-weight: 800;
      }
   }
   p {
      color: #667085;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      line-height: 24px;
   }
}
.hr_tag {
   margin: 15px 0;
   background-color: #edeff2;
   position: relative;
   &:after {
      content: '';
      background-color: #7c5fa6;
      width: 50px;
      height: 5px;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      position: absolute;
      left: 0;
      top: -2.1px;
   }
}
form {
   .cms-contact {
      margin-bottom: 20px;
      label {
         color: #667085;
         font-weight: 400;
         margin: 0;
      }
      :where(input, textarea) {
         width: 100%;
         border: none;
         border-bottom: 2px solid #c5cad5;
         background-color: transparent;
         &::placeholder {
            color: #98a2b3;
            font-size: 14px;
         }
      }
      textarea {
         height: 100px;
         resize: none;
      }
   }
   .terms_condotion {
      display: flex;
      gap: 10px;
      align-items: center;
      a {
         display: inline-block;
         text-decoration: underline;
         text-decoration-line: 2px;
         -moz-text-decoration-line: 2px;
      }
   }
   button {
      display: block;
      background-color: #7b5ea5;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
      border: none;
      color: #fff;
      width: 140px;
      height: 40px;
      margin-top: 20px;
   }
}
.map-lists {
   height: 670px;
   position: relative;
   img {
      height: 100%;
   }
}
.loc_mail_phone {
   position: absolute;
   left: 0px;
   bottom: 0px;
   background-color: #ffffff96;
   width: 100%;
   padding: 20px;

   a {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 15px;
      img {
         width: unset;
      }
      span {
         color: #0c111d;
         font-weight: 400;
         line-height: 24px;
      }
   }
}

@media screen and (max-width: 992px) {
   .map-lists {
      margin-top: 30px;
      height: unset;
   }
   .loc_mail_phone {
      position: unset;
   }
}
