.cm-center1{
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    border-radius: 0; 
    box-shadow: 0 15px 40px rgba(63,58,79,.3); 
    overflow: hidden; 
    min-width:320px
}
.compare-sub-notify1 .cm-sec1 .close-btn1 {
    cursor: pointer;
    background: #9E9E9E;
    width: 24px;
    height: 24px;
    color: #fff;
    font-weight: normal;
    border-radius: 50%;
    line-height: 18px;
    position: absolute;
    right: -16px;
    top: -16px;
    text-align: center;
    font-size: 26px;
}
#iframe{
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}

.details-right-img ul.det-list li.pricing-works {
    margin-left: auto;
    margin-right: 0;
}
.details-right-img ul.det-list li.pricing-works .btn-outline-danger {
    border-radius: 5px;
    border-color: #F77D24;
    color: #F77D24;
    outline: none !important;
    box-shadow: none !important;
}

/********/
.video-iframe .modal-content {
    border-radius: 11px;
}
.video-iframe span.close-btn1 {
    right: -26px !important;
    top: -24px !important;
    /* background: #e95d5d !important; */
}
.video-iframe .modal-content {
    padding: 15px !important;
    box-shadow: none !important;
    height: auto !important;
    border-radius: 11px !important;
    width: 100% !important;
    border: 1px solid rgba(0,0,0,.2) !important;
    z-index: 1;
}

.compare-sub-notify1.video-iframe .cm-sec1 {
    position: initial;
    
}
.video-iframe .cm-center1 {
    width: 100%;
}
.details-right-img ul.det-list li.pricing-works .btn-outline-danger:hover {
    background: #F77D24;
    color: #fff;
}
.video-iframe {
    max-width: 900px !important;
}

/********/
@media screen and (max-width:767px) {
    .cm-sec1 {
        width: 100%;
    }
    
    .cm-center1 {
        padding: 0;
        height: auto;
        width: 100%;
        min-width: auto;
    }
    
    .cm-center1 iframe#iframe {
        position: relative;
        width: 100%;
    }
    
    .video-iframe .modal-body {
        padding: 0;
    }
    .d-detail-page .d-licence-left {
        width: 248px;
    }
}

/*********/
/* tablet responsive page */
@media screen and (min-width:768px) and (max-width:1023px) {
    .new-detail.detail-page .details-left-img img {
        width: 100%;
    }
    .video-iframe {
        transform: initial !important;
    }
}

@media only screen and (min-width:  2700px) and (max-width: 3000px)  {.video-iframe {
    max-width: 1100px!important;
}}
@media only screen and (min-width:  2000px) and (max-width: 2699px)  {.video-iframe {
    max-width: 1000px!important;
}}
@media only screen and (min-width:  1800px) and (max-width: 1999px)  {
     .video-iframe {
    max-width: 950px!important;
} 
}
@media only screen and (min-width:  1700px) and (max-width: 1799px)  {
   .video-iframe {
    max-width: 900px!important;
} 
}
@media only screen and (min-width:  1500px) and (max-width: 1699px)  {
    .video-iframe {
    max-width: 833px!important;
}
}

.video-iframe:before {
    position: absolute;
    width: 200%;
    height: 100%;
    left: -150%;
    background: #9595958c;
    top: -50%;
    height: 200%;
    content: '';
}
.video-iframe:after {
    position: absolute;
    width: 200%;
    height: 100%;
    background: #9595958c;
    top: -50%;
    height: 200%;
    right: -150%;
    content: '';
}

@media screen and (max-width:767px){
    /* .video-iframe:before,.video-iframe:after {
        display: none;
    } */
}

@media screen and (min-width:768px) and (max-width:1023px){
    .video-iframe {
        max-width: 670px !important;
    }
}