.resource_section {
   text-align: center;
   .row{
      align-items: center;
   }
   :where(.faq, .free_training, .help_center, .blog, .roadmap) {
      background-color: #f9fafb;
      padding: 15px;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
      display: flex;
      flex-direction: column;

      h5 {
         color: #0c111d;
         font-size: 28px;
         font-weight: 500;
         margin-bottom: 10px;
         line-height: 46px;
         span {
            color: #7b5ea5;
         }
      }
      h4 {
         color: #0c111d;
         font-size: 20px;
         font-weight: 500;
         margin-top: 10px;
      }
      p {
         color: #475467;
         font-weight: 500;
         line-height: 24px;
         font-size: 15.4px;
      }
      a {
         color: #7b5ea5;
         font-size: 16px;
         svg {
            margin-left: 15px;
         }
      }
   }
   .help_center{
      img{
         width: 60%;
         align-self: center;
      }
   }
}

@media screen and (max-width: 992px) {
   .resource_section {
      .row {
         justify-content: center;
      }
      .help_center {
         margin: 24px 0;
      }
   }
   .roadmap.mt-4 {
      margin: 0 !important;
   }
}
@media screen and (max-width: 768px) {
   .roadmap.mt-4 {
      margin: 20px !important;
   }
}
