@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
* {
   font-family: 'Outfit', serif !important;
}

.purple_bg {
   background: #491495 0% 0% no-repeat padding-box;
   border-radius: 8px;
   color: #fff !important;
   height: 40px;
   width: 180px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   transition: all 0.3s ease-in-out;
   -webkit-transition: all 0.3s ease-in-out;
   -moz-transition: all 0.3s ease-in-out;
   -ms-transition: all 0.3s ease-in-out;
   -o-transition: all 0.3s ease-in-out;
   span {
      font-size: 12px;
   }
   &:hover {
      font-weight: 500;
      color: #491495 !important;
      border: 1px solid #491495;
      background-color: #fff;
      img {
         filter: brightness(0) saturate(100%) invert(8%) sepia(63%) saturate(6620%) hue-rotate(266deg) brightness(109%) contrast(99%);
      }
   }
   img {
      margin-left: 20px;
   }
}
.orange_outline {
   border: 1px solid #fe5a4f;
   border-radius: 8px;
   color: #fe5a4f;
   background: transparent;
   font-size: 14px;
   height: 40px;
   width: 180px;
   &:hover {
      background: #fe5a4f;
      color: #fff;
   }
   a:hover {
      color: #fff;
   }
}
.get_start_book_demo {
   display: flex;
   gap: 8px;
   justify-content: center;
}
.df {
   display: flex;
}
.jcc {
   justify-content: center;
}
.jcs{
   justify-content: start;
}
.aic {
   align-items: center;
}
.new_home_header.home-header {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   // position: absolute;
   // z-index: 9;
   position: sticky !important;
   width: 100%;
   top: 0;
   z-index: 2;
   &.front-header {
      position: relative;
   }
   .header-sections {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      ul {
         margin: 0;
         display: inline-flex;
         align-items: center;
         padding: 0;
         li {
            height: 37px;
            a {
               color: #667085;
               font-size: 16px !important;
               padding: 0px 8px;
               cursor: pointer;
               font-weight: 500;
               height: 37px;
               display: inline-flex;
               align-items: center;
               img {
                  max-width: 180px;
                  position: unset;
               }
            }
            &.men-clicks {
               padding: 0px 0 0 4px;
               span {
                  width: 30px;
                  height: 30px;
                  padding: 0;
                  display: inline-block;
                  border-radius: 50%;
                  text-align: center;
                  background-color: #fff;
                  color: #9575cd;
                  cursor: pointer;
               }
            }
         }
      }
   }
   .nav-log {
      display: none;
   }
   .nav-log-start {
      display: block;
      &.with_view_demo {
         gap: 0.5rem;
         .book_a_demo_view_btn {
            background: #ffffff !important;
            padding: 5px 8px !important;
            border-radius: 2px;
            font-size: 15px !important;
            color: #667085 !important;
         }
      }
   }
}
.new_home_header .header-sections {
   ul {
      li {
         .dropdown {
            .dropdown-toggle {
               padding: 0;
               background: transparent;
               border: 0;
               &:active {
                  background: transparent;
                  border: 0;
               }
               &::after {
                  border-top: 0.3em solid #585858;
                  margin: 0 5px 0 -3px;
               }
            }
            &.show {
               padding: 0 !important;
               .dropdown-menu {
                  padding: 5px 0 !important;
                  .dropdown-item {
                     border: none;
                     font-size: 14px;
                     padding: 0;
                     a {
                        width: 100%;
                        display: block;
                        padding: 10px 20px;
                        font-size: 15px;
                        color: #525252;
                     }
                     &.active {
                        background: transparent;
                        a {
                           color: #8061b3;
                        }
                     }
                     &:active {
                        background: #8061b3;
                        a {
                           color: #fff;
                        }
                     }
                  }
               }
            }
            .dropdown-menu {
               .dropdown-item {
                  &.active {
                     .dropdown-toggle {
                        a {
                           color: #8061b3;
                        }
                     }
                  }
               }
            }
         }
         &.ags-signin {
            a {
               border: 0.5px solid #8061b3;
               border-radius: 5px;
               color: #fff;
               padding: 8px 9px;
               background-color: #8061b3;
               &:hover {
                  background-color: #541cb8 !important;
                  color: #fff;
                  border: 0.5px solid #541cb8;
               }
            }
         }
      }
   }
}

.mobile_menu .mobile-logins li span img {
   display: none;
}
@media screen and (max-width: 992px) {
   .mobile_menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
   }
   .mobile_menu .mobile-logins li span img {
      display: block;
   }
}
.bg_play_video {
   position: absolute;
   left: 0;
   top: 0px;
   width: 100%;
   z-index: -1;
}
// Home Section -- Start
.home_banner {
   text-align: center;
   position: relative;

   h5 {
      font-size: 64px;
      color: #0c111d;
      line-height: 80px;
      font-weight: 500;
      span {
         background: linear-gradient(to right, #6839ab, #491495);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         font-weight: 600;
      }
   }
   p {
      font-size: 15.5px;
      color: #475467;
      margin-top: 14px;
      font-weight: 500;
      line-height: 24px;
   }

   .nocredit {
      font-size: 13px;
      font-weight: 300;
      color: #667085;
   }
   .video_run {
      width: 78%;
      // height: 460px;
      margin: 20px auto;
      img {
         width: 100%;
         height: 100%;
      }
   }
}
// Home Section -- End

// Client Logo Section - Start
.client_logos {
   background-color: #f9fafb;
   .clients_child {
      p {
         font-size: 16px;
         color: #0c111d;
         margin: 0;
         font-weight: 500;
      }
      .our-clients {
         .client-lists {
            width: 150px !important;
            height: 100px !important;
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            img {
               width: 40% !important;
            }
         }
      }
   }
}
// Client Logo Section - End

// Powerful Manage Business Section - Start
.powerful_manage_business {
   .right_side {
      h6 {
         font-size: 27px;
         color: #0c111d;
         line-height: 46px;
         font-weight: 400;
      }
      .powersec {
         display: flex;
         align-items: center;
         span {
            margin-left: 10px;
            color: #000;
            line-height: 18px;
         }
      }
   }
}
// Powerful Manage Business Section - End

// Tabing Slider Section - Start
.tabbing_scroll {
   position: relative;

   &::after {
      content: '';
      background-image: url('../../../images/home_new_img/tab_scroll_overlay.png');
      position: absolute;
      bottom: -17px;
      width: 100%;
      height: 100%;
      z-index: -1;
   }
   ul {
      justify-content: space-between;
      white-space: nowrap;
      flex-wrap: nowrap;

      overflow-x: auto;
      li {
         a {
            padding: 0;
            font-size: 16px;
            color: #fff !important;
            width: 170px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #6359a1;
            border: none;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            z-index: 1;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;
            &:before {
               content: '';
               width: 0;
               height: 3em;
               position: absolute;
               top: 0;
               left: 0;
               background-image: linear-gradient(to right, #4b447a 0%, #4b447a 100%);
               transition: 0.5s ease;
               display: block;
               color: #fff !important;
               z-index: -1;
               -webkit-transition: 0.5s ease;
               -moz-transition: 0.5s ease;
               -ms-transition: 0.5s ease;
               -o-transition: 0.5s ease;
            }
            &:hover::before {
               color: #fff !important;
               width: 170px;
            }
         }
      }
   }
   .tab_head {
      border: 1px solid #d1d4d700;
      backdrop-filter: blur(9px);
      background: linear-gradient(to top, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.3));
      margin-top: 50px;
      border-radius: 16px;
   }
   .tab-content {
      border: 1px solid #d1d4d700;
      border-radius: 12px;
      z-index: 1;
      position: relative;
      .slick-initialized .slick-slide {
         img {
            margin: auto;
            border-radius: 26px;
            margin-top: 40px;
         }
      }
      .shift-arrag {
         margin: 20px 0;
         p {
            text-align: center;
            width: 60%;
            margin: auto;
            line-height: 24px;
            color: #000;
            font-weight: 400;
         }
      }
   }
}
// Tabing Slider Section - End

// Supercharge Your Growth Section - Start
.supercharges_growth {
   text-align: center;
   h4 {
      font-size: 32px;
      font-weight: 600;
      color: #0c111d;
   }
   p {
      font-size: 16px;
      font-weight: 500;
      color: #0c111d;
   }
   .lists_clients {
      display: flex;
      justify-content: center;
      .clients_cates {
         width: 300px;
         height: 240px;
         animation: d;
         display: flex;
         align-items: center;
         justify-content: center;
         &:nth-child(1) {
            background-color: #ffefef;
            width: 35%;
            justify-content: end;
            padding-right: 60px;
         }
         &:nth-child(2) {
            background-color: #effffe;
         }
         &:nth-child(3) {
            background-color: #effff3;
         }
         &:nth-child(4) {
            background-color: #fffeef;
            width: 35%;
            justify-content: start;
            padding-left: 60px;
         }
         h3 {
            font-size: 36px;
            line-height: 54px;
            font-weight: 600;
            color: #0c111d;
            margin-top: 8px;
         }
      }
   }
}
// Supercharge Your Growth Section - End

// Don't Only Listen To Us Section - Start
.only_listen {
   h6 {
      color: #475467;
      font-size: 24px;
      font-weight: 500;
   }
   h5 {
      color: #0c111d;
      font-size: 43px;
      font-weight: 600;
      line-height: 60px;
      span {
         color: #7b5ea5;
      }
   }
   .testimonial-tabs {
      margin-top: 10px;
      .card {
         height: unset;
         background-color: #fff;
         box-shadow: unset;
         transition: all 0.3s;
         border: none;
         ul {
            display: flex;
            border-bottom: none;
            li {
               .nav-link {
                  font-size: 16px;
                  font-weight: 500;
                  color: #98a2b3;
                  padding: 0 9px;
                  &.active {
                     color: #7b5ea5;
                     border: none;
                     border-bottom: 3px solid #7b5ea5 !important;
                  }
                  &:hover {
                     border: none;
                     border-bottom: 3px solid #7b5ea5 !important;
                  }
               }
            }
         }
         .tab-content {
            margin-top: unset;
            background: #fff;
            border: none;

            .card-title {
               color: #98a2b3;
               font-size: 17px;
               text-align: end;
               margin: 0;
               line-height: 40px;
            }
            .card-text {
               margin: 0;
               font-size: 18px;
               font-weight: 400;
               color: #0c111d;
            }
            .owner {
               text-align: end;
               color: #98a2b3;
               font-size: 17px;
               margin: 0;
               font-weight: 500;
            }
            .double_qot_bot {
               display: flex;
               margin-left: auto;
               margin-top: 20px;
            }
         }
      }
   }
}
// Don't Only Listen To Us Section - End

// Shift Scheduling- Centralized Timesheets- Centralized Timesheets Section - Start
.ssctotaa {
   .row {
      row-gap: 30px;
      :where(.shiftscheduling, .onlinetimeandattendance, .centralisedtimesheets) {
         padding: 20px;
         border-radius: 22px;
         h4 {
            font-size: 28px;
            margin: 20px 0;
            color: #000;
            font-weight: 500;
         }
         p {
            font-size: 15px;
            color: #000;
            font-weight: 400;
         }
         img {
            border-radius: 12px;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            -ms-border-radius: 12px;
            -o-border-radius: 12px;
         }
      }
      .shiftscheduling {
         background-color: #f6f9ff;
      }
      .onlinetimeandattendance {
         background-color: #f6fffe;
      }
      .centralisedtimesheets {
         background-color: #fffef6;
      }
   }
}
// Shift Scheduling- Centralized Timesheets- Centralized Timesheets Section - End

//  Automated Time -- Easily Compare Section - Start
.automatedtime_easilycompare {
   h5 {
      color: #0c111d;
      font-size: 24px;
      margin-bottom: 10px;
   }
   p {
      color: #0c111d;
      font-size: 16px;
      width: 330px;
      font-weight: 400;
   }
}
//  Automated Time -- Easily Compare Section - End

// Discover Get Shift Section - Start
.discover_getshift {
   background-color: #45494d;
   position: relative;
   margin-top: 100px;
   p {
      color: #fff;
      font-size: 25px;
      line-height: 36px;
      margin-bottom: 24px;
   }
   .right_side {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      img {
         width: 74%;
      }
   }
   :where(.purple_bg, button) {
      border: 1px solid #fff;
   }
}
// Discover Get Shift Section - End

// Notification Clock Time - Section - Start
.notification_clock_time {
   text-align: center;
   :where(.notification, .clock, .time) {
      padding: 10px;
      height: 360px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      h5 {
         color: #000;
         font-size: 22px;
         font-weight: 500;
         line-height: 30px;
         margin: 20px 0;
      }
      p {
         line-height: 28px;
         color: #0c111d;
         font-weight: 400;
         font-size: 16px;
      }
   }
   .notification {
      background-image: linear-gradient(to top, #fffcf3, #fff);
   }
   .clock {
      background-image: linear-gradient(to top, #e6f2ff, #fff);
   }
   .time {
      background-image: linear-gradient(to top, #fff2ee, #fff);
   }
}
// Notification Clock Time - Section - End

// Exclusive App Section - Start
.exclusive_app {
   position: relative;
   margin-top: 50px;
   padding-top: 50px;
   margin-bottom: 170px;

   .app_play {
      display: flex;
      justify-content: center;
      gap: 15px;
   }
   .i_phone {
      width: 50%;
      margin: auto;
      margin-top: 50px;
      display: flex;
   }
   .app_content {
      background-color: RGBA(255, 255, 255, 0.3);
      backdrop-filter: blur(20px);
      text-align: center;
      padding: 10px 0;
      position: absolute;
      left: 0;
      bottom: -150px;
      right: 0;
      height: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h5 {
         color: #0c111d;
         font-size: 28px;
         font-weight: 500;
      }
      p {
         color: #0c111d;
         font-size: 16px;
         font-weight: 400;
         margin-top: 20px;
      }
   }
}
// Exclusive App Section - End

// Footer Section - Start
.footer-section {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   padding: 50px 0 0;
   align-items: flex-start;
   justify-content: flex-start;
   margin: 50px 0 0 0;
   border-top: 1px solid #ccc;
}
.footer-logwid ul li {
   color: #000000;
   font-size: 14px;
}

.footer-logwid ul li span {
   display: inline-table;
}
.footer-widths ul li a {
   color: #000000;
   font-size: 14px;
   margin: 0;
   padding: 0;
   line-height: 0;
   font-weight: 300;
}
.footer-lists .footer-widths {
   display: flex;
   flex-wrap: wrap;
}
.footer-widths h5 {
   font-size: 16px;
   color: #000000;
   margin: 0;
}

.footer-copyright p {
   color: #000000;
   text-align: center;
   width: 90%;
   margin: 0 auto;
   border-top: 1px solid #9a9a9a;
   padding: 18px 0;
   font-size: 16px;
}

.powered_tt {
   border-top: none !important;
   padding: 0 !important;
   padding-bottom: 18px !important;
   font-size: 14px !important;
   color: #000 !important;
   letter-spacing: unset !important;
}
.powered_tt a {
   color: #000 !important;
}
.powered_tt a:hover {
   color: #000 !important;
   text-decoration: none !important;
}

.footer-widths ul {
   padding: 0;
   margin: 0;
   width: 100%;
}
.footer-widths ul li {
   width: 100%;
}

.footer-logwid {
   width: 100%;
   flex-wrap: wrap;
   display: flex;
   .foot-logo {
      width: 200px;
      position: relative;
      left: -5px;
   }
}
.footer-widths ul li a:hover {
   color: #8a6cbd;
}
.footer-copyright {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
}

.footer-copyright {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   margin: 25px 0 0;
}
.right-banner {
   width: 100%;
}
.footer-logwid ul {
   padding: 0;
   margin: 0;
   width: 100%;
}
.footer-lists .footer-widths.last-footers li {
   display: inline-block;
   margin-right: 10px;
   width: auto;
}
span.fts-left {
   width: 10%;
}
.footer-lists .footer-widths.midls-footers {
   width: 18%;
}
// Footer Section - End

// Responsive -- Start

@media screen and (max-width: 1200px) {
   .tabbing_scroll ul {
      gap: 30px;
   }
   .only_listen h5 {
      font-size: 30px;
      line-height: 48px;
   }
}
@media screen and (max-width: 992px) {
   .home_banner h5 {
      font-size: 54px;
   }
   .powerful_manage_business {
      .right_side {
         h6 {
            font-size: 27px;
            br {
               display: none;
            }
         }
         .powersec {
            span {
               font-size: 14px;
            }
         }
      }
   }
   .tabbing_scroll .tab-content .shift-arrag p {
      width: unset;
   }
   .discover_getshift {
      .left_side {
         position: relative;
         z-index: 1;
         background-color: #ffffff45;
         backdrop-filter: blur(4px);
         padding: 10px 0;
         p {
            font-size: 19px;
            margin-bottom: 24px;
            text-align: center;
         }
      }
      .right_side img {
         width: 43%;
      }
   }
   .ssctotaa .row .onlinetimeandattendance {
      text-align: end;
   }
}
@media screen and (max-width: 840px) {
   .home_banner h5 {
      font-size: 44px;
      line-height: 60px;
   }
   .powerful_manage_business {
      .right_side {
         h6 {
            font-size: 22px;
         }
      }
   }
}
@media screen and (max-width: 768px) {
   .fdcr {
      flex-direction: column-reverse;
   }
   .home_banner h5 {
      font-size: 34px;
   }
   .supercharges_growth {
      .lists_clients {
         flex-wrap: wrap;
      }
   }
   .supercharges_growth {
      .lists_clients {
         flex-wrap: wrap;
         .clients_cates {
            &:nth-child(4),
            &:nth-child(1) {
               justify-content: center;
               padding-right: unset;
               padding-left: unset;
            }
         }
      }
   }
   .automatedtime_easilycompare {
      h5 {
         br {
            display: none;
         }
      }
      p {
         width: unset;
      }
   }
   .exclusive_app {
      .app_content {
         p {
            br {
               display: none;
            }
         }
      }
   }
}
@media screen and (max-width: 678px) {
   .home_banner {
      p {
         font-size: 14px;
         br {
            display: none;
         }
      }
   }
   .supercharges_growth p {
      br {
         display: none;
      }
   }
   .exclusive_app .i_phone {
      width: 70%;
   }
}
@media screen and (max-width: 540px) {
   .get_start_book_demo.mt-5 {
      margin-top: 0 !important;
   }
   .exclusive_app .app_content {
      height: unset;
   }
}
@media screen and (max-width: 480px) {
   .home_banner h5 {
      font-size: 22px;
      line-height: 40px;
   }
   .supercharges_growth {
      h4 {
         font-size: 20px;
         margin-bottom: 10px;
      }
      .lists_clients {
         .clients_cates {
            width: 100%;
            &:nth-child(4),
            &:nth-child(1) {
               width: 100%;
            }
         }
      }
   }
   .only_listen h5 {
      font-size: 21px;
      line-height: 34px;
      br {
         display: none;
      }
      .testimonial-tabs .card {
         .tab-content .tab-pane,
         .card-body {
            padding: 0;
         }
      }
   }
   .automatedtime_easilycompare p {
      width: unset;
   }
   .discover_getshift .left_side p {
      font-size: 14px;
      line-height: 28px;
   }
   .exclusive_app .i_phone {
      width: 90%;
   }
}

// Responsive -- Ebd
