.compare-hensive {
  width: 100%;
  background: linear-gradient(rgba(255, 0, 0, 0), rgb(0, 0, 0)),
    url("../../../images/pricing-new/pricing_banner.png") no-repeat;

  height: 90vh;
  display: flex;
  align-items: end;
  background-size: cover;
  background-position: center;
}

.price-compare h5 {
  color: #fff;
  font-size: 48px;
  font-family: system-ui;
}

.price-compare h2 {
  color: #fff;
  font-size: 62px;
  font-weight: 600;
}

.price-compare p {
  color: #fff;
  font-size: 16px;
  width: 700px;
}

.price-compare button,
.price-compare a {
  color: #fff;
  background-color: #5f4b95;
  border: none;
  border-radius: 5px;
  padding: 8px 24px;
  font-size: 15px;
}

.price-compare {
  padding-bottom: 50px;
}


.pricing_txt_color {
  color: #212121 !important;
}



.pricing_module_box {

  .module_cards {

    background-color: #F7FAFC;
    border-radius: 24px;

    .MuiGrid-grid-lg-4 {
      max-width: 31.933333% !important;
    }

    .MuiGrid-container {
      gap: 15px;
    }

    .MuiCardContent-root {
      padding: 0 !important;
    }

    .pricing_module_summary {
      width: 300px;
      height: 80%;
      border-color: #cbd5e0;
      border-width: 0.5px;
      border-style: solid;
      border-radius: 16px;
      background: #f7fafc;
      padding: 16px;
    }

    .module_plan_card {
      // width: 248px;
      height: 280px;
      // height: 100%;
      border-radius: 16px !important;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      padding: 12px;

      .moduel_plan_title {
        display: flex;
        gap: 15px;
        align-items: center;
        min-height: 64px;

        .price_content {
          font-size: 12px;
          color: #2E83FF;
          text-wrap: nowrap;
        }
      }



      .price-title {
        font-size: 16px !important;
        color: #000000;
      }

      .moidule_plan_button {
        width: 120px;
        height: 40px;
        border-radius: 8px;
        margin: 0;
        color: #2E83FF;
        border: 1px solid #2e83ff;
        background-color: #f5f9ff;

        &.added {
          color: #ffff;
          background: #4b9557;
          border: 1px solid #4b9557;

        }

      }

      // .added

      .price_amount_box {
        // flex-direction: column;
        // align-items: flex-start;
        // gap: 0.5rem;
        padding: 0 !important;
        display: unset !important;

        .price_amount {
          margin-bottom: 10px;

          h5 {
            color: #000000;
            font-weight: 600;
          }
        }

        .price_amount span {
          color: #757575;
          font-size: 14px;
          font-weight: normal;
        }
      }

      .price-truncated-text {
        overflow: hidden;
        position: relative;
        max-height: 4.5em;
        /* Adjust based on the number of lines */
        line-height: 1.5em !important;
        font-size: 14px !important;
        margin-top: 10px !important;
        color: #718096;
      }

      .price-read-more-text {
        position: absolute;
        right: 0;
        bottom: 0;
        padding-left: 5px;
        background: white;
        /* Background to match the container */
        color: #2E83FF;
        cursor: pointer;
      }

      .price-expanded {
        max-height: none;
        /* Allows the full text to be shown */
      }



    }



  }

  .pricing_summary_card {

    .module_count {
      display: flex;
      justify-content: space-between;

      h6 {
        font-size: 14px;
      }

      h4 {
        font-size: 24px;
      }
    }
  }

  .addedplanlist {
    .MuiBox-root {
      margin: 10px 0;
      gap: 12px;
    }

    .tot_mon {
      display: flex;
      justify-content: space-between;

      h5 {
        font-size: 14px;
      }

      h4 {
        font-size: 24px;
      }
    }

    .MuiCardContent-root:last-child {
      padding-bottom: 0 !important;
    }

    .MuiCardContent-root {
      padding: 0px !important;
    }

    .subscribe_btn {}
  }



  .price_calculated_popover {
    h6 {
      text-align: center;
      font-weight: 700;
      color: #8061b3;
    }

    p {
      font-size: 14px;
      text-indent: 10px;
      color: #000;

      &::before {
        content: '*';
        font-size: 20px;
        font-weight: bold;
        position: relative;
        top: 6px;
        color: #8061b3;
        right: 8px;
      }
    }
  }
}

.no_of_emp {
  .text_range {
    margin-left: auto;
    display: flex;
    width: 80px;

    input {
      border: 1px solid #5f4b95 !important;
      border-radius: 8px !important;
    }
  }

  .enter_no_of_emp {
    color: #000000;
    font-size: 24px;
  }
}









// ---------------------------------- New Tire style -----------------------------
.n-pricing-heading {
  text-align: center;
  line-height: 2px;
}
.pricing_loading{
  text-align: center;
  font-size: 1.5rem;

}
.n-pricing-heading h1 {
  font-size: 60px;
  color: black;
  font-weight: 600;
}

.n-pricing-heading h1 span {
  color: #7B5EA5;
}

.n-pricing-heading p {
  font-size: 35px;
  color: black;
  font-weight: 500;
}

.n-pricing .pricing-flex-box {
  margin: 30px auto 50px auto;
  display: flex;
  gap: 2px;
  flex-direction: row;
  flex-wrap: wrap;
}

.np-popular-box {
  margin-top: -32px;
}

.np-popular {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  background-color: #0C111D;
  color: white;
  width: 85%;
  font-size: 13px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  top: -33px;
}

.np-popular-box .pricing-box .active {
  background-color: #7B5EA5;
  color: white;
}

.pricing-box {
  width: 217px;
  border: 1px solid rgb(228, 228, 228);
  padding: 15px;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
}

.pricing-box span {
  font-size: 13px;
  color: rgb(49, 49, 49);
  font-weight: 600;
}

.pricing-box .price-tag {
  font-size: 30px;
  color: #0C111D;
  font-weight: 550;
  height: 60px;
}

.pricing-box .price-tag .price_num{
  font-size: 26.8px;
}
.pricing-box .price-tag span {
  font-size: 11.5px;
  color: #344054;
  font-weight: 600;
}

.pricing-box .package-type {
  font-size: 16px;
  color: #344054;
  font-weight: 200px;
}

.price-tag .custom {
  line-height: 1px;
  margin-top: -10px;
  font-size: 13px;
  color: #344054;
  font-weight: 550;
}

.blue-quote {
  line-height: 1px;
  margin-top: -10px;
  font-size: 13px;
  color: #2146DC;
  font-weight: 550;
}

.pricing-box .started-btn {
  font-size: 15px;
  border: 1px solid #7B5EA5;
  background-color: transparent;
  color: #344054;
  width: 170px;
  height: 38px;
  border-radius: 8px;
  margin: 20px auto;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #7B5EA5;
    color: white;
  }
}

.pricing-features {
  border-top: 1px solid rgb(223, 223, 223);
  border-bottom: 1px solid rgb(223, 223, 223);
  height: 400px;
  color: #344054;
}

.see-feature {
  display: flex;
  font-size: 14px;
  justify-content: center;
  color: #344054;
  margin-bottom: -30px;
  margin: 15px 0 0 0;
  margin-bottom: -10px;
  font-weight: 500;
  cursor: pointer;
}

.pricing-features div {
  margin-top: 7px;
}

.pricing-features img {
  margin-right: 8px;
}

.text-overflow {
  line-height: 15px;
  height: 32px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.pricing-box select {
  background: none;
  border: none;
  text-align: center;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 550;
  margin-top: 20px;
  color: #344054;
}

// feature table
.price-feature-table h2 {
  font-size: 32px;
  text-align: center;
  color: #0C111D;
  font-weight: 500;
}

.np-pricing-table {
  width: 100%;
  margin: 20px auto;
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.027);
  // overflow-x: scroll;
}

.np-pricing-table .np-package-title {
  color: #461E81;
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
}

.np-pricing-table .np-package-title-black {
  color: #0C111D;
  font-size: 18px;
  font-weight: 900;
  margin-top: 10px;

}

.np-pricing-table table {
  width: 100%;
  border-spacing: 0;
}

.np-pricing-table thead {
  background-color: #f9f9f9;
}

.np-pricing-table thead th {
  text-align: left;
  padding: 20px 8px;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.np-topic-title {
  margin-top: 15px;
  padding: 10px;
  padding-left: 25px;
}

.np-pricing-table tbody td {
  text-align: left;
  padding: 8px 8px;
  border-bottom: 1px solid #e0e0e0;
  color: #0C111D;
  font-size: 15px;
  font-weight: 600;
}

.np-feature-title {
  text-align: left;
  color: #0C111D;
  font-weight: bold;
  padding-left: 28px !important;
}

td:first-child {
  // background-color: #fff;
  font-weight: bold;
}

.table-pricing-box {
  // width: 217px;
  // padding: 15px;
  border-radius: 10px;
  // margin: 0 auto;
}

.table-pricing-box span {
  font-size: 13px;
  color: rgb(49, 49, 49);
  font-weight: 600;
}

.table-pricing-box .price-tag {
  font-size: 30px;
  color: #0C111D;
  font-weight: 500;
  height: 90px;
}

.table-pricing-box .price-tag span {
  font-size: 13px;
  color: #344054;
  font-weight: 600;
}

.table-pricing-box .package-type {
  font-size: 16px;
  color: #344054;
  font-weight: 200px;
}

.price-tag .custom {
  line-height: 1px;
  margin-top: -10px;
  font-size: 13px;
  color: #344054;
  font-weight: 600;
}

.blue-quote {
  line-height: 1px;
  margin-top: -10px;
  font-size: 13px;
  color: #2146DC;
  font-weight: 600;
}

.table-pricing-box .started-btn {
  font-size: 15px;
  border: 1px solid #7B5EA5;
  background-color: transparent;
  color: #344054;
  width: 140px;
  height: 38px;
  border-radius: 8px;
  margin: 20px auto;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #7B5EA5;
    color: white;
  }
}

.table-pricing-box .active {
  background-color: #7B5EA5;
  color: white;
}

.pricing-currency {
  text-align: end;
  margin-top: 3rem;

  select {
    height: 38px;
    padding: 0.375rem 0.75rem;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #3e515b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d4d7;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

}


// Responsive

@media screen and (max-width: 1200px) {
  .compare-hensive {
    height: 100%;
    padding-top: 25%;
  }

  .pricing_module_box {
    .module_cards {
      .MuiGrid-grid-lg-4 {
        max-width: unset !important;
      }
    }
  }

  .pricing_module_box .module_cards .MuiGrid-container {
    grid-gap: 13px;
    gap: 13px;
    justify-content: center;
  }

  .table-pricing-box .price-tag {
    height: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .price-compare h5 {
    font-size: 34px;
    line-height: 42px;
  }

  .price-compare h2 {
    font-size: 52px;
  }

  .price-compare p {
    font-size: 13px;
    width: 100%;
    line-height: 27px;
    margin: 10px 0 20px;
  }

  .np-pricing-table {
    overflow-x: scroll;
  }

  .n-pricing-heading h1 {
    font-size: 44px;
  }

  .n-pricing-heading p {
    font-size: 25px;
  }

  .table-pricing-box {
    width: 200px;
  }

  .n-pricing .pricing-flex-box {
    gap: 20px;
  }

  .np-popular-box {
    margin-top: 32px;
    margin: 0 auto;
  }
}


@media screen and (max-width: 575px) {
  .price-compare h5 {
    font-size: 21px;
    line-height: 26px;
  }

  .price-compare h2 {
    font-size: 34px;
    line-height: 48px;
  }
}

// ---------------------------------- New Tire style -----------------------------