.login-container {
  width: 100%;
  height: calc(100vh - 83px);
  background: url(../../../images/auths/signin_bg_new.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  .container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    .login-modal {
      width: 45%;
      min-width: 345px;
      background: #fff;
      border-radius: 5px;
      padding: 20px 35px;
      position: relative;
      overflow: hidden;
      h5 {
        color: #4c6280;
        font-weight: 600;
        font-size: 21px;
        margin: 0 0 15px;
      }
      .new-form {
        .form-group {
          position: relative;
          .form-label {
            margin: 0;
            color: #000;
            font-weight: 400;
          }
          .form-control {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #e0e0e0;
            padding: 0;
            font-size: 16px;
            &:-internal-autofill-selected {
              background-color: transparent !important;
            }
          }
          .form-error {
            margin: 0;
            color: #eb0000;
            font-size: 13px;
            line-height: 20px;
            font-weight: 500;
            display: inline-block;
            position: absolute;
            bottom: -20px;
            left: 0;
          }
          &:has(.form-error){
            margin-bottom: 1.5rem;
          }
        }
        .remember-group {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 15px;
          .remember {
            label {
              margin: 0;
              width: auto;
              padding-left: 25px;
            }
          }
          .forget-password {
            border: none;
            background: transparent;
            color: #757575;
            font-size: 14px;
          }
        }
        .invalid-input {
          text-align: center;
          color: #ab0000;
          font-size: 15px;
          font-weight: 500;
          padding: 3px;
          .close {
            position: absolute;
            right: 12px;
            top: 9px;
            opacity: 0.7;
            text-shadow: none;
          }
        }
        .form-submit {
          background-color: #8061b3;
          border-radius: 5px;
          border: 1px solid #8061b3;
          font-size: 16px;
          color: #fff;
          padding: 10px 50px;
          margin: 5px auto 10px;
          display: block;
          &:disabled {
            background-color: #ebebe4;
            border: 1px solid #ebebe4;
          }
        }
        .reg-here {
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 10px;
          font-size: 15px;
          color: #757575;
          margin: 10px 0;
          a {
            color: #2d333e;
            font-weight: 500;
          }
        }
        .login-through {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          margin: 20px 0 10px;
          button {
            border: none;
            background: #edf2f6;
            padding: 10px 35px;
            color: #4d4d4d;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            gap: 10px;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .unVerified-box {
        h6 {
          color: #4c6280;
          font-weight: 600;
          font-size: 21px;
          margin: 10px 0 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          img {
            width: 28px;
            height: 28px;
          }
        }
        p {
          font-size: 16px;
          color: #4c6280;
          text-align: center;
          font-weight: 400;
        }
        .unVerified-btns {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          margin: 0 0 20px;
          .btn {
            border: none;
            padding: 8px 20px;
            color: #fff;
            font-size: 16px;
            border-radius: 5px;
            transition: all 0.2s;
            &.resend-link {
              background: #169b1a;
            }
            &.change-email {
              background: #b4002c;
            }
            &:hover {
              background: #541cb8;
            }
          }
        }
      }
    }
  }
}
.spinner-box {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background: #ffffffa6;
  z-index: 1;
  display: grid;
  place-items: center;
  .spinner {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 3px solid #8061b3;
      animation: prixClipFix 2s linear infinite;
    }
  }
}
@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1024px) {
  .login-container {
    .login-modal {
      width: 60%;
    }
  }
}
@media screen and (max-width: 767px) {
  .login-container {
    .login-modal {
      width: 80%;
    }
  }
}
@media screen and (max-width: 575px) {
  .login-container {
    .login-modal {
      width: 90%;
    }
  }
}
