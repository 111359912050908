.doc-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
}

.doc-logo {
    width: 250px;
    height: 70px;
    overflow: hidden;
}
.doc-logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.doc-headSection {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.doc-title {
    font-size: 30px;
    font-weight: 600;
    color: black;
}

button.doc-download {
    border: none;
    background: #4FA23B;
    color: white;
    border-radius: 7px;
    padding: 10px;
}

.doc-body {
    color: black;
    padding: 40px;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 7rem;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
    position: absolute;
    left: 36%;
    right: 36%;
    top: 36%;
    bottom: 25%;
    margin: auto;
    background: white;
}

.popup.adding_folder .modal-header h4 {
    font-family: 'GraphikRegular';
    font-size: 14px;
    padding: 10px 11px;
    color: rgb(46, 47, 53);
}

.popup.adding_folder .modal-body input {
    font-family: 'GraphikRegular';
    height: 41px;
    box-shadow: none;
}

.popup.adding_folder .modal-footer button {
    font-family: 'GraphikRegular';

}

.popup.adding_folder .modal-header {
    padding: 0;
    background: #fff;
    color: white;
    font-family: 'GraphikRegular';
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid rgb(229, 229, 229);
}

.popup.adding_folder .modal-header button.close {
    color: white;
    opacity: 1;
    padding: 9px;
    color: rgb(118, 118, 118);
    font-size: 34px;
    font-weight: normal;
}

.popup.adding_folder .modal-dialog {
    width: 600px;
    /* top: 15%; */
    box-shadow: rgba(0, 0, 0, 0.22) 0px 15px 20px 0px, rgba(0, 0, 0, 0.3) 0px 20px 60px 0px;
    border-radius: 4px;
}

@media (min-width: 576px) {

    .modal-dialog {
        max-width: 600px;
    }

}

button.btn.btn-danger.btn-ok.add_folder_new {
    background: #5fbd68;
    border: #5fbd68;
    font-family: 'GraphikSemibold';
}

.popup.adding_folder .modal-body p {
    font-family: 'GraphikRegular';
    text-align: center;
}

.sig-container {
    width: 100%;
    height: 50vh;
    top: 10%;
    left: 10%;
}

.sigContainer {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;
}

.sigPad {
    width: 100%;
    height: 100%;
}

.sigButtons {
    width: 100%;
    height: 30px;
}

.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
}

/*------------ Sigature popup ------------*/

.signat button.close {
    margin: 4px 13px 0px 0px !important;
}

.signat .modal-header {
    border-bottom: none !important;
}

.signat .nav-tabs .nav-item a {
    border: none;
    position: relative;
}

.signat a.active.nav-link:before {
    content: '';
    position: absolute;
    width: 53%;
    border-bottom: 2px solid #47b972;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.signat .modal-body {
    padding: 0;
}

.signat ul.nav.nav-tabs {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
}

.signat .tab-pane {
    width: 100%;
    padding: 20px 20px 0px;
    background: transparent;
    position: relative;
}

.modal-dialog.signat {
    max-width: 600px;
}

canvas.sigPad {
    background: rgb(248, 248, 248);
}

.sig-clear {
    position: absolute;
    /* top: 35px; */
    left: 34px;
    color: rgb(141, 141, 141);
    font-size: 14px;
    cursor: pointer;
}

.sig-color {
    position: absolute;
    top: 35px;
    right: 21px;
}

.sig-color button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 8px;
    position: relative;
    border: none;
    background: #000;
    outline: none;
}

.sig-color button img {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
}

.sig-color button img {
    display: none;
}

.sig-color .s_size img {
    width: 13px;
    height: 13px;
    padding: 2px;
    top: 2px;
    left: 3px;
    display: block;
}

.sig-color .s_size {
    width: 18px;
    height: 18px;
    transform: scale(1.4);
}

.sig-color button.s_blue {
    background: rgb(26, 97, 204);
}

.sig-color button.s_red {
    background: rgb(202, 51, 51);
}

.sig-color button.s_green {
    background: rgb(92, 182, 72);
}

.signat .modal-footer {
    border: none;
}

.signat .modal-footer {
    border: none;
}

.signat .modal-footer button.btn.btn-secondary {
    margin-left: 0;
}

.signat .modal-footer button.btn.btn-secondary {
    margin-right: auto;
    color: rgb(118, 118, 118);
    background-color: rgba(118, 118, 118, 0.08);
    border-radius: 3px;
    transition: background-color 0.3s ease 0s, color 0.3s ease 0s, box-shadow 0.3s ease 0s;
    border-color: transparent;
}

.footer_cnt {
    padding: 0px 20px;
    margin-top: 12px;
    font-size: 12px;
}

.footer_cnt p a {
    color: rgb(57, 148, 91);
}

.footer_cnt p {
    font-size: 12px;
    line-height: 1.4;
}

.sig-box {
    width: 100%;
    border: transparent;
    height: 200px;
    background: rgb(248, 248, 248);
    position: relative;
}

.sig-box input[type="text"] {
    border: transparent;
    width: 93%;
    bottom: 26px;
    left: 0;
    position: absolute;
    right: 0;
    margin: 0 auto;
    border-bottom: 1px solid #47b972;
    font-size: 40px;
    background: transparent;
    text-align: center;
    outline: none;
    padding-bottom: 3px;
    height: auto !important;
}

.file_drag {
    width: 100%;
    height: 200px;
    position: relative;
    border: 1px dashed #b5b5b5;
}

.file_drag label {
    display: inline-block;
    background-color: rgb(71, 185, 114);
    color: white;
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
}

.sig-drag_upload {
    position: absolute;
    margin: 0 auto;
    width: 185px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.drop_word h3 {
    font-size: 16px;
    font-weight: 600;
    color: rgb(47, 47, 47);
}

.drop_word p {
    color: rgb(47, 47, 47);
    font-size: 14px;
    margin: 0;
}

.signat .btn-primary {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px 0px;
}

.signat .btn-primary:hover {
    background: #25864a;
}

.choose-font {
    position: absolute;
    top: auto;
    left: 21px;
    z-index: 1;
}

.choose-font select {
    border: none;
    background: transparent;
    padding: 12px;
    outline: none;
    font-size: 16px;
    color: rgb(141, 141, 141);
}

.modal-dialog.signat button.close {
    outline: none !important;
}

.signat .modal-footer button.btn.btn-secondary:hover {
    background-color: rgb(118 118 118 / 18%);
}

.modal-dialog.signat h4#myModalLabel {
    font-size: 24px;
    font-weight: 600;
}

.signat .nav-tabs .nav-item a {
    cursor: pointer;
}

.sig-color button {
    cursor: pointer;
}

.preview-image {
    max-width: 100%;
    max-height: 100%;
}

.modal-dialog.signature-modal {
    max-width: 50rem;
}

.modal-header.doc-modal-header {
    padding: 5px 15px !important;
}

.doc-submit {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    margin-bottom: 20px;
}

.doc-submit button {
    border: none;
    padding: 10px;
    background: #32A800;
    color: white;
}

.doc-body-container {
    background: #eee;
    padding: 40px;
}

.doc-container {
    padding: 15px;
    width: 80%;
    margin: 0 auto;
    background: #fff;
}