.viewdemo h3 {
  text-align: center;
  color: #000;
  font-size: 32px;
  margin: 26px 0;
  line-height: 46px;
}

.video_tittle {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fafcff;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  gap: 15px;
  button {
    border: none;
    text-align: left;
    background: transparent;
    font-size: 14px;
    line-height: 20px;

    .video-thumb {
      width: 100%;
      height: 120px;
      margin: 0px 0 10px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0px 2px 3px #ebebeb;
      overflow: hidden;
      position: relative;
      .video-thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      iframe {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .play-video {
        position: absolute;
        width: 35px;
        height: 35px;
        -webkit-filter: drop-shadow(0px 0px 2px #000) invert(1);
        filter: drop-shadow(0px 0px 2px #000) invert(1);
        border-radius: 50%;
        overflow: hidden;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .pause-video {
        display: none;
      }
      .loader-box {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        display: none;
        background: #fff;
        z-index: 1;
        .loader {
          width: 40px;
          height: 40px;
          display: block;
          border-radius: 50%;
          position: relative;
          animation: rotate 1s linear infinite;
          &::before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            inset: 0px;
            border-radius: 50%;
            border: 3px solid #8061b3;
            animation: prixClipFix 2s linear infinite;
          }
        }
      }
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes prixClipFix {
        0% {
          clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        }
        25% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
        }
        50% {
          clip-path: polygon(
            50% 50%,
            0 0,
            100% 0,
            100% 100%,
            100% 100%,
            100% 100%
          );
        }
        75% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
        }
        100% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        }
      }
    }
    &.activeVideo {
      .video-thumb {
        .pause-video {
          display: block;
          position: absolute;
          width: 35px;
          height: 35px;
          -webkit-filter: drop-shadow(0px 0px 2px #000) invert(1);
          filter: drop-shadow(0px 0px 2px #000) invert(1);
          border-radius: 50%;
          overflow: hidden;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .play-video {
          display: none;
        }
        .loader-box {
          display: grid;
          place-items: center;
        }
      }
    }
  }
}

.video-show {
  width: 100%;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  video {
    width: 100%;
    border-radius: 20px;
    box-shadow: none;
  }
}

.iframe_modal-app-demo {

  .iframe_modal_header {
    justify-content: end;
    border: none !important;
    padding: 5px 15px 5px 15px !important;
    button {
      padding: 0px 5px;
      border: none;
      color: #ff0000;
      font-size: 1.04rem;
      background: none;
    }
  }
  .iframe_modal_body{
    padding-top: 0;
  }
}

// .iframe_modal_btn{
//   padding: 0px;
// }

@media only screen and (max-width: 1023px) {
  .row {
    display: flex !important;
  }
}
@media only screen and (max-width: 991px) {
  .viewdemo h3 {
    font-size: 24px;
    margin: 30px 0 50px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .viewdemo h3 {
    padding: 0 20px;
  }
  .video_tittle {
    flex-direction: row;
    flex-wrap: wrap;
}

.video_tittle button {
    width: 48%;
}
}
@media only screen and (max-width: 575px) {
  .video_tittle button {
    width: 100%;
}
}















// .iframe_modal_header {
//   justify-content: end;
//   border: none !important;
//   padding: 5px 15px 5px 15px !important;
// }





// // button 
// element .style {
//   padding: 0px 5px;
//   border: none;
//   color: #ff0000;
//   font-size: 1.04rem;
// }

// // .modal-body
// element .style {
//   padding-top: 0;
// }




