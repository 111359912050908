// .front-header .header-sections ul.inner-menu li a{
//     background-color: #9575cd;
//     color: #fff;
// }
.front-header .header-sections ul.inner-menu li.men-clicks span {
  background-color: #9575cd;
  color: #fff;
}

section.home-header.front-header {
  position: static;
  background-color: #fff;
}

section.breadcum-headers {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.breadcum-sec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.breadcum-sec ul {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.breadcum-sec ul li {
  padding: 0 16px 0 0;
  position: relative;
}

.breadcum-sec ul li a {
  color: #616161;
  font-size: 12px;
}

.breadcum-sec ul li.active a {
  color: #9575cd;
  font-size: 16px;
}

.breadcum-sec ul li.active:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #9575cd;
  border-radius: 50%;
  left: -10px;
  top: 13px;
}

section.contact-sections {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
}

.right-forms {
  width: 96%;
  padding: 0px 15px;
}

.sts-two {
  width: 50%;
}

.sts-two.as-ls {
  padding-right: 15px;
}

.sts-two.as-rs {
  padding-left: 15px;
}

.cus-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000000;
  line-height: 26px;
}

.cus-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.cus-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: 1px solid #616161;
  border-radius: 2px;
}

.cus-check:hover input ~ .cus-checkmark {
  background-color: #ccc;
}

.cus-check input:checked ~ .cus-checkmark {
  background-color: #ffffff;
  border: 1px solid #9575cd;
  border-radius: 4px;
}

.cus-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cus-check input:checked ~ .cus-checkmark:after {
  display: block;
}

.cus-check .cus-checkmark:after {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid #9575cd;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

section.mapping-contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.map-lists {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.map-infos {
  position: absolute;
  top: 0;
  right: 6%;
  background-color: #ffffffc7;
  height: 100%;
  width: 28%;
  padding: 100px 60px;
}

.map-infos h5 span {
  color: #9575cd;
  font-size: 16px;
  padding: 0px 8px;
}

.mp-center {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.mp-center p {
  font-size: 15px;
  color: #333333;
  line-height: 25px;
  padding: 2px 0 20px 25px;
  width: 100%;
}

.social-pages ul {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.social-pages {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #9575cd;
  padding: 30px 10px;
}

.social-pages ul li {
  padding: 0px 10px;
}

.social-pages ul li a {
  color: #a3a3a3;
  font-size: 21px;
}

.social-pages ul li a:hover {
  color: #9575cd;
}

section.abs-contact {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

section.searching-resources {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
}

.search-places {
  width: 45%;
  margin: 0 auto;
}

.width-ranges {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.width-ranges input.form-control {
  color: #333;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #8061b3;
  background-color: #ffffff;
  font-size: 14px;
  text-indent: 12px;
}

.width-ranges input.form-control::-webkit-input-placeholder {
  color: #d0d0d0 !important;
}

.width-ranges input.form-control:-ms-input-placeholder {
  color: #d0d0d0 !important;
}

.width-ranges input.form-control::placeholder {
  color: #d0d0d0 !important;
}

//   .search-btns button.btn.src-bts {
//     width: 50px;
//     height: 50px;
//     background-color: #9575cd;
//     border-radius: 50%;
//     border: 0;
// }
// .search-btns button.btn.src-bts:hover {
//     background-color: #4b1c9db0;
// }
.search-btns {
  position: absolute;
  right: 4px;
  top: 2px;
}

section.resources-sections {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0;
}

h6.learn-more {
  width: 100%;
  margin-bottom: 10px;
}

h6.learn-more a {
  color: #9575cd;
  font-size: 14px;
}

.res-height {
  height: 220px;
  width: 100%;
  text-align: center;
}

.res-height.fir-ras {
  position: absolute;
  top: -76px;
}

.res-height {
  position: absolute;
  top: -25px;
}

.tooltip-stus {
  position: relative;
  display: inline-block;
}

.tooltip-stus .tooltiptext-stus {
  visibility: hidden;
  width: 265px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0;
  padding: 0 4px;
  position: absolute;
  z-index: 1;
  top: -6px;
  left: 166%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  color: #9e9e9e;
  font-size: 12px;
  line-height: 24px;
}

.tooltip-stus .tooltiptext-stus::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #b1b1b1 transparent transparent;
}

.tooltip-stus:hover .tooltiptext-stus {
  visibility: visible;
}

.common-flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.signup-covers {
  margin-top: 30%;
  width: 100%;
}

.domain-inputs input.form-control {
  width: 60%;
  border-radius: 5px;
  border: solid 1px #9e9e9e;
  background-color: #ffffff;
  height: 33px;
  //text-indent: 14px;
}

.domain-inputs span {
  color: #333333;
  font-size: 14px;
  padding: 6px 10px 0;
}

.domain-inputs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

section.pricing-sections {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}

.price-comparing {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.pricing-heading {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.pricing-heading h4 {
  color: #333333;
  font-size: 24px;
  text-align: center;
  width: 100%;
  position: relative;
  text-transform: CAPITALIZE;
}

.pricing-heading:before {
  content: "";
  position: absolute;
  width: 112px;
  border: 1.5px dashed #8e70c499;
  left: 219px;
  top: 17px;
}

.pricing-heading:after {
  content: "";
  position: absolute;
  width: 112px;
  border: 1.5px dashed #8e70c499;
  right: 219px;
  top: 17px;
}

.pricing-heading h4:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 33px;
  background-color: #8c6ec1;
  border-radius: 16px;
  top: 2px;
  left: 30%;
}

.pricing-heading h4:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 33px;
  background-color: #8c6ec1;
  border-radius: 16px;
  top: 2px;
  right: 30%;
}

.pricing-heading p:before {
  content: "";
  position: absolute;
  width: 32px;
  border: 1.5px dashed #8e70c499;
  left: 215px;
  top: 17px;
  height: 61px;
  border-right: 0;
  border-top: 0;
}

.pricing-heading p:after {
  content: "";
  position: absolute;
  width: 32px;
  border: 1.5px dashed #8e70c499;
  right: 215px;
  top: 17px;
  height: 61px;
  border-left: 0;
  border-top: 0;
}

.pricing-heading h4:hover {
  color: #333333;
}

.pricing-heading p {
  width: 54%;
  margin: auto;
  text-align: center;
  font-size: 16px;
  color: #616161;
  line-height: 26px;
  margin: 18px auto 0;
}

.board-pricing {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f2f1ec;
  margin: 0;
}

.middle-price {
  width: 50%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.middle-price .left-prc h6 {
  background-color: #9575cd;
  display: inline;
  color: #ffffff;
  font-size: 14px;
  padding: 3px 20px;
  border-radius: 19px;
}

.middle-price .left-prc ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.middle-price .left-prc ul li {
  font-size: 15px;
  color: #333333;
  width: 100%;
  padding: 0;
  line-height: 24px;
}

.middle-price .left-prc ul li p {
  color: #616161;
  margin: 0;
}

.right-prc {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}

.right-prc p {
  color: #757575;
  font-size: 16px;
  margin: 0 0 8px;
  width: 100%;
  line-height: 22px;
  text-align: right;
}

.right-prc p span {
  border-bottom: 1px dashed #9e9e9e;
  padding-left: 30px;
}

.right-prc input {
  width: 160px;
  height: 90px;
  border-radius: 5px;
  border: 0;
  background-color: #ffffff;
  font-size: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

section.policy-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.vals-policy {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.vals-policy h4 {
  color: #8061b3;
  font-size: 30px;
  margin: 0 0 34px;
  padding: 0;
  font-family: "Eina03SemiBold";
  position: relative;
}

.vals-policy h6 {
  font-size: 20px;
  color: #000000;
  font-weight: normal;
  font-family: "Eina03SemiBold";
  margin-bottom: 8px;
}

.vals-policy p {
  width: 100%;
  font-size: 16px;
  color: #000000;
  line-height: 28px;
  margin-bottom: 15px;
}

.circle-click {
  width: 16px;
  height: 16px;
  border: solid 1px #d0d0d0;
  border-radius: 50%;
  position: relative;
}

.tabbing-features .tab-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 0;
  padding: 0;
  position: relative;
}

.feat-tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  position: relative;
}

.feat-tabs h5 {
  color: #333333;
  width: 50%;
  font-size: 18px;
  margin: auto;
}

.feat-tabs p {
  margin: 10px auto;
  color: #616161;
  font-size: 15px;
  width: 53%;
  line-height: 26px;
}

.abs-features h3 {
  font-size: 30px;
  color: #bdbdbd;
  text-transform: uppercase;
  letter-spacing: 12px;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.abs-features {
  position: absolute;
  left: -50%;
  top: 38%;
  width: 100%;
}

.feat-tabs img {
  max-width: 90%;
  margin: 0 auto;
}

// .vals-policy img {
//     -webkit-transition: ease 1s all;
//     transition: ease 1s all;
// }
// .vals-policy:hover img{
// -webkit-filter: grayscale(50%);
// filter: grayscale(50%);
// -webkit-transition: ease 1s all;
// transition: ease 1s all;
// }
.vals-policy h4:hover {
  color: #8061b3;
}

.tab-lists-features ul.nav.nav-tabs {
  position: absolute;
  top: 43%;
  right: 0;
  width: auto;
  display: inline-block;
  border: 0;
}

.tab-lists-features ul.nav.nav-tabs li.nav-item {
  width: 100%;
  right: 0;
  padding: 6px 0;
}

.tab-lists-features ul.nav.nav-tabs li.nav-item a.active.nav-link {
  border: 0;
  padding: 0;
  width: auto;
  border: 0;
}

.tab-lists-features ul.nav.nav-tabs li.nav-item a {
  padding: 0;
  margin: 0;
  border: 0;
}

.tab-lists-features ul li a.active .circle-click span {
  width: 8px;
  height: 8px;
  background-color: #9575cd;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
}

.tab-lists-features ul li a.active .circle-click {
  border: solid 1px #9575cd;
}

.pricing-views {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.pricing-views .price-widths {
  width: 90%;
  margin: auto;
}

.shifting-pricing {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: center;
  padding: 50px 0 0;
  display: inline-flex;
}

.choose-prc {
  width: 25%;
  padding: 0px 15px;
}

.frontend-choice {
  border-radius: 5px;
  //box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px 16px;
  min-height: 336px;
  position: relative;
}

.frontend-choice.sing-color {
  background-color: #fff;
  border: 1px solid #eeeeee;
}

label.empl-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 40px;
}

.empl-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.empl-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
}

.sing-color .empl-checkmark {
  background-color: #a8a8a8;
}

.empl-checkmark:after {
  content: "";
  position: absolute;
  display: block;
}

.empl-checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.frontend-choice h5 {
  width: 100%;
  display: block;
  font-size: 16px;
  color: #000000;
  padding: 3px 0 10px;
  text-align: left;
  line-height: 18px;
  line-height: 24px;
  font-family: "Eina03SemiBold";
  min-height: 60px;
}

.frontend-choice.sing-color h5 {
  color: #333333;
}

.frontend-choice a {
  margin-left: auto;
  border-radius: 13px;
  font-size: 12px;
  padding: 0 16px;
  height: 24px;
  line-height: 25px;
  margin-top: 0;
  position: relative;
  top: -6px;
}

.frontend-choice button.btn {
  font-size: 13px;
  padding: 0 14px;
  margin-top: 0;
  position: relative;
  top: 0px;
  width: 100%;
  color: #000000;
}

.frontend-choice.sing-color a {
  color: #9575cd;
}

.frontend-choice.sing-color button.btn {
  color: #000;
}
.frontend-choice.sing-color button.btn:hover {
  color: #9575cd;
}
.frontend-choice.sing-color button.btn:hover:before {
  border-bottom: 1px dashed #8061b3;
}
.frontend-choice.mul-color button.btn:hover {
  color: #000;
}
.frontend-choice.mul-color button.btn:hover:before {
  border-bottom: 1px dashed #000;
}
.frontend-choice ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 8px 0;
  height: 120px;
}

.frontend-choice ul li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 3px 0;
  line-height: 20px;
}

span.left-count {
  width: 13%;
  text-align: left;
  font-size: 14px;
  color: #757575;
}

span.right-count {
  width: 87%;
  text-align: left;
  font-size: 14px;
  color: #757575;
}

.frontend-choice.sing-color span.left-count {
  color: #565656;
}

.frontend-choice.sing-color span.right-count {
  color: #333333;
}

p.tax-lists {
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: #565656;
}

// .price-abs{
//     position: absolute;
//     left: -6px;
//     padding: 7px;
//     width: 104%;
//     height: 80px;
//     box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
//     background-color: #ffffff;
//     bottom: 30px;
// }
// .price-abs:after {
//     position: absolute;
//     top: 0;
//     left: 2px;
//     margin-top: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: transparent transparent transparent #bdbdbd;
//     -webkit-transform: rotate(45deg);
//     transform: rotate(45deg);
//     content: '';
// }

// .price-abs:before {
//     position: absolute;
//     top: -1px;
//     right: 1px;
//     margin-top: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: #7986cb00 transparent #bdbdbd transparent;
//     -webkit-transform: rotate(45deg);
//     transform: rotate(224deg);
//     content: '';
// }
.price-abs p {
  font-size: 14px;
  width: 100%;
  text-align: center;
  margin: 0;
}

.frontend-choice.sing-color .price-abs p {
  color: #333333;
}

.price-abs h2 {
  width: 100%;
  text-align: center;
  margin: 0 0 25px;
  padding: 0;
  font-size: 30px;
  line-height: 20px;
  color: #000000;
  font-family: "Eina03SemiBold";
}

.price-abs h2 span {
  font-size: 18px;
}

.frontend-choice.mul-color {
  // background-image: url('../../../images/home/color-frame.png');
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-color: #faf6ff;
  width: 100%;
  border: 1px dashed #8061b3;
}

.frontend-choice.mul-color h5 {
  color: #8061b3;
}

.frontend-choice.mul-color a {
  background-color: #ffffff;
  color: #9575cd;
}

.frontend-choice.mul-color button.btn {
  color: #9575cd;
}

.frontend-choice.mul-color span.left-count,
.frontend-choice.mul-color span.right-count {
  color: #8061b3;
}

.frontend-choice.mul-color .empl-checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #9575cd;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.frontend-choice.mul-color .price-abs p,
.frontend-choice.mul-color .price-abs h2 {
  color: #8061b3;
}

.frontend-choice.wh-color .empl-checkmark {
  border: solid 1px #9575cd;
}

.frontend-choice.wh-color .empl-checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #9575cd;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.frontend-choice.wh-color h5 a {
  background-color: #b49cdd;
  color: #fff;
}

.frontend-choice.wh-color button.btn {
  background-color: #b49cdd;
  color: #fff;
}

.frontend-choice.wh-color span.left-count {
  color: #333333;
}

.frontend-choice.wh-color span.right-count {
  color: #333333;
}

.frontend-choice.wh-color .price-abs {
  background-color: #b49cdd;
}

.frontend-choice.wh-color .price-abs p,
.frontend-choice.wh-color .price-abs h2 {
  color: #ffffff;
}

.frontend-choice.wh-color .price-abs:before {
  border-color: transparent transparent #6b5296 transparent;
}

.frontend-choice.wh-color .price-abs:after {
  border-color: transparent transparent transparent #6b5296;
}

.frontend-choice.sing-color.sing-chs span.empl-checkmark {
  background-color: #a8a8a700;
  border: 1px solid #a8a8a8;
}

.frontend-choice.sing-color.sing-chs .empl-checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #a8a8a8;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sto-status {
  position: absolute;
  top: 7px;
  right: 10px;
}

.tooltip-stu {
  position: relative;
  display: inline-block;
}

.tooltip-stu i {
  color: #7986cb;
  font-size: 20px;
}

.tooltip-stu .tooltiptext-stu {
  visibility: hidden;
  width: 193px;
  color: #565656;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0;
  position: absolute;
  z-index: 1;
  top: 3px;
  right: 110%;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 10px;
  height: 23px;
  line-height: 15px;
}

.tooltip-stu:hover .tooltiptext-stu {
  visibility: visible;
}

span.list-ques {
  width: 22px;
  height: 22px;
  background-color: #f7d323;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 15px;
  padding: 0;
}

.prices-cont {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: auto;
}

.prices-cont button.btn.btn-const {
  border-radius: 37px;
  background-color: #9575cd;
  color: #ffffff;
  font-size: 16px;
  padding: 6px 36px;
  font-family: "Eina03SemiBold";
  width: 100%;
}

.shifting-pricing.toping-border {
  border-top: 1px solid #ddd;
}

.modal-dialog.price-mains {
  max-width: 986px;
  width: 986px;
  margin: 0 auto;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

.price-mains .modal-title button.btn {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #fff;
  position: absolute;
  right: 12px;
  font-size: 24px;
  top: 3px;
}

.price-mains .modal-header {
  background-color: #ffffff;
  padding: 10px 65px 0 !important;
  margin: 0;
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 0px !important;
}

.price-mains .modal-header h5.modal-title {
  text-align: center;
  margin: auto;
  font-size: 15px;
  color: #000000;
  font-family: "Eina03SemiBold";
  font-weight: normal;
  border-bottom: 1px dashed #000;
  width: 100%;
  padding: 10px 0 18px;
}

.feature-maintains {
  width: 100%;
  padding: 0px 54px;
}

.feature-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding: 0 0 15px;
}

.feature-lists h6 {
  color: #000000;
  font-size: 16px;
  width: 100%;
  margin: 10px 0;
  padding: 0;
  font-weight: normal;
}

.feature-lists ul {
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.feature-lists ul li {
  width: 33.33%;
  padding: 7px 0px;
  line-height: 20px;
  font-size: 14px;
  color: #616161;
}

.feature-lists ul li i {
  color: #f7d323;
  padding-right: 1px;
  margin-right: 6px;
}

.price-mains .modal-body {
  min-height: 400px;
  overflow: auto;
  padding: 0 10px 10px 10px;
}

.price-mains .modal-content {
  border-radius: 12px;
}

.menu-lists ul li.active a img.menu-bef {
  display: none;
}

.menu-lists ul li.active a img.menu-aft {
  display: inline-block;
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.Toastify {
  width: 30%;
  position: fixed;
  right: 0;
  background-color: #fff;
  z-index: 99;
}

.Toastify .Toastify__toast {
  width: 100%;
  padding: 15px;
  font-size: 14px;
  position: relative;
}

.Toastify__toast.Toastify__toast--error {
  background-color: #ff000014;
  color: #544f4f;
  font-size: 14px;
}

.Toastify__toast.Toastify__toast--success {
  background-color: #4caf503b;
  color: #544f4f;
  font-size: 14px;
}

button.Toastify__close-button {
  position: absolute;
  right: 10px;
  top: 18px;
  background-color: transparent;
  border: 0;
  box-shadow: 2px 2px transparent;
}

label.empl-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 23px;
}

label.empl-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.empl-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
}

label.empl-container:hover input ~ .empl-checkmark {
  background-color: #ccc;
}

label.empl-container input:checked ~ .empl-checkmark {
  background-color: #fff;
}

.empl-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

label.empl-container input:checked ~ .empl-checkmark:after {
  display: block;
}

label.empl-container .empl-checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sing-color label.empl-container input:checked ~ .empl-checkmark {
  background-color: #a8a8a8;
}

.sing-color.sing-chs label.empl-container input:checked ~ .empl-checkmark {
  background-color: transparent;
}

.modal-dialog.cart-infoss {
  max-width: 75%;
  margin: 70px auto;
}

.cart-infoss .modal-header {
  background-color: #9575cd;
  padding: 10px !important;
  margin: 0;
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.cart-infoss .modal-header h5.modal-title {
  text-align: center;
  margin: auto;
  font-size: 14px;
  color: #ffffff;
}

.cart-infoss .modal-title button.btn {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #fff;
  position: absolute;
  right: 12px;
  font-size: 24px;
  top: 3px;
  width: auto !important;
}

.cart-infoss .modal-content {
  border-radius: 12px;
  border: 0;
}

.cart-varity {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.left-card-infos {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.left-card-infos ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.left-card-infos ul li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 0;
}

.mdl-const {
  width: 45%;
}

.md2-const {
  width: 17%;
}

.md3-const {
  width: 20%;
}

.left-card-infos ul li p {
  margin: 0;
  font-size: 16px;
  color: #000000;
}

li.cart-headings {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.right-card-infos {
  width: 35%;
  display: flex;
  flex-wrap: wrap;
}

.list-ment {
  width: 90%;
  margin-left: auto;
}

.list-ment ul {
  background-color: #f2f1ec;
  border: 0;
  border-radius: 5px;
  padding: 20px 40px;
  margin: 0;
}

.list-ment ul li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2px 0;
}

.list-ment ul li span {
  color: #757575;
  font-size: 14px;
  width: 50%;
}

.list-ment ul li span.ths-list {
  text-align: left;
  padding: 0px 15px 0 0;
  color: #000000;
  font-family: "Eina03SemiBold";
}

.list-ment ul li h5 {
  text-align: center;
  width: 100%;
  font-size: 30px;
  color: #565656;
  margin: 15px 0 0;
  padding: 15px 0 0;
  border-top: 1px solid #dedede;
}

.list-ment button.btn.pay-nwss {
  text-align: center;
  background-color: #9575cd;
  display: inline;
  color: #ffffff;
  font-size: 14px;
  padding: 6px 31px;
  border-radius: 19px;
  margin: auto;
  display: inherit;
  margin: 17px auto;
  text-transform: uppercase;
}

.list-ment button.btn.pay-nwss:hover {
  background-color: #5612cc;
}

section.switch-tabs.only-for-mbl:before {
  display: none;
}

section.switch-tabs.only-for-mbl .tabs-accrs {
  padding: 0;
}

.frontend-choice button.btn.btn-adds {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #dedede;
  padding: 0;
  color: #929191;
  line-height: 8px;
  font-size: 18px;
}

span.count-btns {
  padding: 0px 7px;
  color: #757575;
}

section.switch-tabs.only-for-mbl {
  display: none;
}

// .frontend-choice.mul-color button.btn.btn-adds {
//     background-color: #ffffff;
// }
.frontend-choice button.btn.btn-adds:before {
  display: none;
}
.frontend-choice h3.cmg-soon {
  align-items: center;
  margin: 120px 0 0;
  font-size: 20px;
  text-align: center;
  color: #333;
}

span.cmg-sns {
  margin-top: 19px;
  display: inline-block;
  color: #000000;
  font-size: 16px !important;
}

ul.list-markers {
  width: 100%;
  margin: 0;
  padding: 0 0 20px;
}

ul.list-markers li {
  width: 100%;
  font-size: 15px;
  color: #616161;
  line-height: 26px;
}

ul.list-markers li i {
  color: #9575cd;
  padding: 0px 15px 0;
}

.vals-policy p a {
  color: #9575cd;
}

.cookies-table-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.cookies-table-section table.table {
  width: 100%;
}

th.rent-periods {
  width: 160px;
}

.cookies-table-section .table thead th {
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #673ab7;
}

.cookies-table-section .table tbody td {
  font-size: 14px;
  color: #616161;
}

.vals-policy p a:hover {
  color: #673ab7;
}

ul.social-pannels {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

ul.social-pannels li {
  padding: 0px 10px;
}

ul.social-pannels li a {
  color: #000;
}

ul.social-pannels li a:hover {
  color: #9575cd;
}

.cookies-table-section .table tbody td p {
  margin: 0;
}

.header-sections a img.ags-logo {
  // max-width: 220px;
  max-width: 180px;
  position: relative;
  left: -5px;
}

.tooltip-stus.spce-tls .tooltiptext-stus {
  top: 3px;
  width: 200px;
  padding: 0 8px;
  text-align: left;
}

.login-steps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.login-steps ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 20px 0 10px;
}

.login-steps ul li {
  width: 33.33%;
}

.login-steps ul li span.login-seccount {
  width: 32px;
  display: block;
  height: 32px;
  background-color: #e5e5e5;
  border-radius: 50%;
  color: #000;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}

.login-steps ul li span.loginsec-parts {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #4d4d4d;
  margin: 10px 0;
  display: block;
  cursor: pointer;
}

.loginprev {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.loginprev button.btn.login-prevs {
  background-color: #ddddddbd;
  font-size: 14px;
  padding: 6px 22px;
  color: #030202;
  border: 0;
  border-radius: 4px;
}

.loginprev button.btn.login-prevs:hover {
  background-color: #8c8c8c;
  color: #fff;
}

.loginprev button.btn.login-nexts {
  background-color: #9575cd;
  font-size: 14px;
  padding: 6px 22px;
  color: #fff;
  border: 0;
  border-radius: 4px;
}

.loginprev button.btn.login-nexts:hover {
  background-color: #7f51d0;
}

.loginprev button.btn {
  margin: 0px 10px;
}

.login-steps ul li.active span.login-seccount {
  background-color: #8061b3;
  color: #fff;
}

.login-steps ul li.active span.loginsec-parts {
  color: #8061b3;
}

.forms-hiding {
  width: 100%;
}

.heading-boxes {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.heading-boxes h4 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  width: 100%;
  margin: 12px 0 20px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.agency-boxes.login-cont .employee-forms.adjus-boxes {
  padding: 10px 10px;
}

.forms-hiding {
  width: 100%;
  padding: 0 45px;
}

.forms-hiding .login-steps ul li {
  width: 100%;
}

.agency-boxes.login-cont .heading-boxes .new-agency-login {
  margin-top: 0;
}

.domain-inputs span.dmn-sign {
  padding: 0;
  position: absolute;
  left: 18px;
  top: 5px;
}

.domain-inputs span.dmn-sign span {
  color: #707070;
  padding: 0;
  font-size: 12px;
}

.domain-inputs span.dmn-sign.abs-moved {
  left: 23%;
  display: inline-block;
}

.domain-uppends {
  width: 100%;
  display: none;
}

.domain-inputs span.dmn-sign.abs-moved-value_added {
  display: none;
}

.domain-uppends.valueadded {
  display: block;
}

.domain-inputs input.form-control.value_added {
  font-size: 0;
}

span.left-spacs {
  width: 55%;
}

span.right-spacs {
  width: 45%;
}

.sto-status .tooltip-stu {
  line-height: 25px;
}

.frontend-choice .common-spacs {
  // justify-content: flex-end;
  // align-items: flex-end;
  // margin-left: 75px !important;
  text-align: center;
  margin: 10px auto 0;
}

span.domain-names {
  margin-left: -3px;
}

.header-sections ul li.active a {
  color: #8061b3;
  // border-right: 3px solid #f7d323;
  // border-left: 3px solid #f7d323;
  font-family: "Eina03SemiBold";
}

// .header-sections ul li.ags-signin {
//     margin-left: 120px;
// }

.infoclr-contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.conts-parts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.modal-dialog.agency-singups {
  max-width: 846px;
  width: 846px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
}

.modal-dialog.agency-singups {
  border-radius: 10px;
}

.modelsign-stru {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.modelsign-stru .modal-content {
  border: 0;
}

.left-sign {
  width: 45%;
}

.right-sign {
  width: 55%;
}

.left-sign img.sign-logo {
  width: 184px;
  object-fit: contain;
}

.left-sign img {
  width: 90%;
}

.right-sign ul.nav.nav-tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 0;
}

.right-sign ul.nav.nav-tabs li a {
  border: 0;
  color: #9e9e9e;
  font-size: 16px;
  border-left: 1px solid #ececec;
  padding: 0px 20px;
  border-radius: 0;
  display: inline;
}

.right-sign ul.nav.nav-tabs li a.active {
  font-family: "Eina03SemiBold";
  color: #8061b3;
  border-left: 3px solid #f7d323;
}

.right-sign .tab-content {
  margin-top: 25px;
  border: 0;
}

.right-sign .tab-content .tab-pane {
  padding: 0;
}

.right-sign .signup-groups {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.signup-groups .group-control {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.signup-groups .group-control input.form-control {
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: 2px 2px transparent;
  height: 40px;
  font-size: 14px;
  padding: 0 42px 0 16px;
}

.signup-groups .group-control ::-webkit-input-placeholder {
  color: #757575 !important;
}

.signup-groups .group-control :-ms-input-placeholder {
  color: #757575 !important;
}

.signup-groups .group-control ::placeholder {
  color: #757575 !important;
}

span.pro-updates {
  position: absolute;
  top: 2px;
  right: 18px;
}

.signup-groups .group-control label {
  margin: 0;
  color: #757575;
  font-size: 10px;
  background-color: #fff;
  position: absolute;
  display: inline;
  top: -9px;
  left: 15px;
  line-height: 20px;
  padding: 0 3px;
  z-index: 9;
}

.sign-process {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0 10px;
}

.sign-process span {
  color: #757575;
  font-size: 14px;
}

.sign-process span a {
  color: #000000;
  font-family: "Eina03SemiBold";
  margin-left: 10px;
}

.sign-process span a:hover {
  color: #8061b3;
}

button.progress-next {
  background-color: #8061b3;
  border: 1px solid #8061b3;
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  padding: 10px 48px;
  margin-left: auto;
}

button.progress-next:hover {
  background-color: #53269e;
  border: 1px solid #53269e;
}

.modal-dialog.agency-singups .modal-body {
  padding: 30px 45px;
}

.agency-singups .cus-checkmark {
  position: absolute;
  top: 2px !important;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
}

.agency-singups .cus-check input:checked ~ .cus-checkmark {
  border-radius: 3px;
}

.agency-singups .cus-check .cus-checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 11px;
}

button.for-password {
  border: 0;
  background-color: transparent;
  color: #757575;
  margin-left: auto;
  color: #757575;
  font-size: 12px;
  padding: 0;
}

.agency-singups label.cus-check {
  width: auto;
  color: #757575;
  font-size: 12px;
  line-height: 18px;
  padding-left: 30px;
}

button.for-password:hover {
  color: #f56c6c;
}

.login-progress {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 20px 0;
  justify-content: center;
}

.login-progress p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #757575;
  margin: 35px 0 0;
}

.login-progress p a {
  color: #000000;
  font-family: "Eina03SemiBold";
  margin-left: 10px;
}

.login-progress p a:hover {
  color: #8061b3;
}

button.pres-login {
  background-color: #8061b3;
  border-radius: 2px;
  border: 1px solid #8061b3;
  font-size: 16px;
  color: #fff;
  padding: 10px 36px;
}

button.pres-login:hover {
  background-color: #53269e;
  border: 1px solid #53269e;
}

.cnt-infos {
  width: 100%;
  background-color: #8061b3;
  border-radius: 5px;
  padding: 25px;
  min-height: 668px;
  position: relative;
}

.cnt-infos h4 {
  width: 100%;
  color: #ffffff;
  margin: 0 0 30px;
  font-size: 24px;
}

.cnt-infos h4:hover {
  color: #ffffff;
}

.cnt-infos ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.cnt-infos ul li {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 15px;
}

.cnt-infos ul li span {
  width: 30px;
  display: inline-block;
}

.cont-abs {
  position: absolute;
  bottom: 0;
  right: 0;
}

.abs-social {
  position: absolute;
  bottom: 40px;
  left: 60px;
}

.abs-social ul li {
  display: inline-block;
  width: auto;
  margin: 0;
}

.abs-social ul li a {
  padding: 0 13px;
  font-size: 20px;
}

.abs-social ul li a:hover {
  color: #f7d323;
}

.cus-check a {
  text-decoration: underline;
}

.cus-check a:hover {
  color: #8061b3;
}

.forgotval-stru {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.forgotval-stru img.sign-logo {
  width: 184px;
  object-fit: contain;
}

.parll-left {
  width: 45%;
}

.parll-right {
  width: 55%;
}

.modal-dialog.agency-forget .modal-body {
  padding: 30px 45px 0 45px;
}

.forget-step1 {
  width: 100%;
}

.forget-step3 .login-progress {
  margin: 70px 0 20px;
}

.forget-step3 {
  /* display: none; */
}

.parll-right p {
  color: #000000;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
}

.forget-step1 .login-progress {
  margin: 60px 0 0;
}

.parll-right h4:hover {
  color: #000000;
}

// .forget-step1{
//     display: none;
// }
p.resend-code {
  width: 100%;
  text-align: right;
  margin: 0;
  padding-top: 5px;
}

p.resend-code a {
  color: #8061b3;
  font-family: "Eina03SemiBold";
}

p.resend-code a:hover {
  color: #612cb9;
}

.forget-step2 .login-progress {
  margin: 40px 0 0;
}

.forget-step2 {
  /* display:none; */
}

.btn:focus,
.btn.focus {
  box-shadow: 0 0 0 0.2rem #20a8d800 !important;
}

h6.learn-more a i {
  margin-left: 10px;
}

.feature-lists:last-child {
  border-bottom: 0;
}

li.me-cls {
  display: none;
}

.tab-lists-features h4:hover {
  color: #333;
}

.hensive-billing {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.hensive-billing p {
  width: 100%;
  color: #000000;
  font-size: 16px;
  line-height: 26px;
  margin: 30px 0;
}

.hensive-billing img {
  max-width: 95%;
}

.right-prc h6 {
  width: 100%;
  color: #000000;
  font-family: "Eina03SemiBold";
  font-size: 24px;
  font-weight: normal;
  text-align: right;
  margin: 10px 0;
}

.empl-left {
  width: 60%;
  padding-right: 30px;
}

.empl-right {
  width: 40%;
  margin-top: 5px;
  position: relative;
}

.price-widths {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

section.price-banking {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #8061b3;
  margin-bottom: 0;
  padding: 25px 20px;
  border-bottom: 1px solid #ddd;
}

.breakup-price {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.left-brns {
  width: 50%;
}

.right-brns {
  width: 50%;
  padding: 5px 50px 0;
}

.left-brns h6 {
  width: 100%;
  text-align: right;
  color: #fff;
  font-weight: normal;
}

.left-brns h6 span.dtep-totl {
  color: #ffffff;
  font-size: 16px;
  padding: 0px 6px;
  display: inline-block;
}

.left-brns span.dtep-tot2 {
  font-size: 30px;
  font-family: "Eina03SemiBold";
  padding: 0px 6px;
}

.left-brns span.dtep-tot2 span.dtep-price {
  font-size: 16px;
}

.left-brns span.dtep-tot3 {
  border-bottom: 1px dashed #9e9e9e;
  font-size: 16px;
  padding: 0px 6px;
}

.right-brns button.buybtns-ops {
  background-color: #f7d323;
  border: 0;
  color: #000000;
  font-size: 16px;
  padding: 8px 50px;
  border-radius: 20px;
  font-family: "Eina03SemiBold";
}

.right-brns button.buybtns-ops:hover {
  background-color: #d4b51f;
}

section.subsecription-fees {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1.5px dashed #000;
  padding: 40px 0;
}

.vals-policy h4:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 0;
  top: 42px;
}

.list-ment ul li.tls-prc {
  border-top: 1px solid #ddd;
  padding: 10px 0 0;
  margin-top: 10px;
}

.list-ment ul li.tls-prc span {
  font-family: "Eina03SemiBold";
  color: #000000;
  font-size: 20px;
}

.list-ment ul li h4 {
  width: 100%;
  font-size: 14px;
  color: #000000;
  font-family: "Eina03SemiBold";
  border-bottom: 1px solid #ddd;
  padding: 0 0 8px;
  margin-bottom: 10px;
}

.prices-cont button.btn.btn-const:hover {
  background-color: #835bca;
}

section.application-features {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}

.features-heading {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.features-heading h3 {
  text-align: center;
  width: 100%;
  color: #8061b3;
  font-family: "Eina03SemiBold";
  position: relative;
  margin-bottom: 30px;
}

.features-heading h3:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 48%;
  top: 56px;
}

.features-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 0;
}

.feats-cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.feats-cont h5 {
  width: 100%;
  font-size: 20px;
  color: #8061b3;
  font-family: "Eina03SemiBold";
  position: relative;
  margin: 10px 0 20px;
}

.feats-cont p {
  width: 100%;
  color: #000000;
  font-size: 16px;
  line-height: 28px;
}

.feats-cont h6 {
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-weight: normal;
  font-family: "Eina03SemiBold";
  margin: 20px 0 8px;
}

.feats-cont ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.feats-cont ul li {
  padding: 5px 30px 0 0;
}

.feats-gals {
  width: 100%;
}

.feats-cont h5:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 0;
  top: 36px;
}

section.others-features {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
}

.cont-feats {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.cont-feats h3 {
  width: 100%;
  color: #8061b3;
  font-size: 30px;
  font-family: "Eina03SemiBold";
  position: relative;
  margin: 0 0 40px;
  padding: 0 8px;
}

.cont-feats h3:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 10px;
  top: 52px;
}

.othfea-lists {
  width: 25%;
  padding: 0 8px;
  margin-bottom: 30px;
}

.counter-names {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  min-height: 180px;
}

.counter-names h4 {
  width: 100%;
  color: #8061b3;
  font-size: 16px;
  font-family: "Eina03SemiBold";
}

.counter-names p {
  width: 100%;
  margin: 0;
  color: #000000;
  font-size: 14px;
  line-height: 26px;
}

.counter-names h4:hover {
  color: #8061b3;
}

.counter-names:hover {
  box-shadow: 3px 3px 10px #0000001a;
}

section.bells-notification {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #8061b3;
}

.info-vlas {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.shift-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
}

.main-steps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.main-steps h4 {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  font-family: "Eina03SemiBold";
  font-weight: normal;
  position: relative;
  margin: 0px 0 15px;
}

.main-steps p {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}

.customs-mbs {
  width: 100%;
}

.customs-mbs img {
  width: 100%;
}

.main-steps h4:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/white-shape.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 0;
  top: 35px;
}

.main-steps h4:hover {
  color: #f7d323;
}

.main-steps h4:hover:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 0;
  top: 35px;
}

.shift-cases {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.shift-cases ul.nav.nav-tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 0;
}

.shift-cases ul.nav.nav-tabs li {
  width: 20%;
}

.shift-cases ul.nav.nav-tabs li a {
  width: 100%;
  border: 0;
  text-align: left;
  color: #9e9e9e;
  font-size: 16px;
  position: relative;
  padding: 0 15px;
}

.shift-cases .tab-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 0;
}

.shift-cases .tab-content .tab-pane {
  width: 100%;
}

.opns-ranges {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0 0;
}

.shift-cases ul.nav.nav-tabs li a.active {
  color: #8061b3;
}

section.shiftstabing {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 70px 0;
}

.shift-cases ul.nav.nav-tabs li a.active:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 17px;
  top: 35px;
}

.right-moved {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 15px 0;
}

.right-moved h5 {
  width: 100%;
  color: #000000;
  font-size: 20px;
  font-family: "Eina03SemiBold";
}

.right-moved p {
  width: 100%;
  font-size: 16px;
  color: #000000;
}

.opns-ranges .slick-dots li button {
  height: 16px;
  width: 16px;
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 50%;
  z-index: 9;
}

.opns-ranges .slick-dots {
  left: 68%;
  top: 48%;
  z-index: 9;
}
.shift-cases .left-moved ul.slick-dots {
  left: 74%;
}
.opns-ranges .slick-dots li.slick-active button {
  background-color: #8061b3;
}

.slick-dots li button:before {
  color: transparent !important;
}

.opns-ranges .slick-dots li.slick-active button:after {
  content: "";
  position: absolute;
  background-image: linear-gradient(#f7d323, white);
  width: 26px;
  height: 26px;
  display: inline-block;
  border-radius: 50%;
  left: -5px;
  top: -4px;
  z-index: -1;
}

.tooltip-spaces {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip-spaces .tooltiptext-spaces {
  visibility: hidden;
  width: 550px;
  height: 162px;
  border: 2px solid #f7d323 !important;
  box-shadow: 0px 0px 10px #00000029;
  background-color: #fff;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 30px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -250px;
  color: #000000 !important;
  text-align: left;
  font-size: 16px;
  line-height: 28px;
}

.tooltip-spaces .tooltiptext-spaces::after {
  content: "";
  position: absolute;
  top: 101%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f7d323 transparent transparent transparent;
}

.tooltip-spaces:hover .tooltiptext-spaces {
  visibility: visible;
}

.right-prc p span:hover {
  color: #8061b3;
  border-bottom: 1px dashed #8061b3;
  cursor: pointer;
}

button.btn.tool-close {
  position: absolute;
  top: 3px;
  right: 2px;
  color: #f7d323;
}

button.btn.tool-close:hover {
  color: #3a3936;
}

.frontend-choice img.sticker {
  position: absolute;
  top: -13px;
  left: 43%;
  filter: grayscale(100%);
}

.frontend-choice.mul-color img.sticker {
  filter: grayscale(0);
}

.frontend-choice button.btn:before {
  content: "";
  position: absolute;
  left: 38%;
  border-bottom: 1px dashed #000000;
  width: 43px;
  top: 22px;
}

.frontend-choice.mul-color button.btn:before {
  border-bottom: 1px dashed #8061b3;
}

span.left-count img {
  filter: grayscale(100%);
}

.frontend-choice.mul-color span.left-count img {
  filter: grayscale(0);
}

.count-valus button.btn.count-inc {
  position: absolute;
  top: 3px;
  left: 118px;
  cursor: pointer;
}

.count-valus button.btn.count-dec {
  position: absolute;
  top: 43px;
  left: 118px;
  cursor: pointer;
}

section.home-header.fixed {
  position: fixed;
  background-color: #fff;
  box-shadow: 2px 2px 3px #ddd;
  top: 0;
  left: 0;
}
.agency-singups .modal-content {
  border-radius: 10px;
}
.signup-groups .group-control .form-group {
  margin: 0;
}
.signup-groups .group-control .invalid-feedback {
  font-weight: normal !important;
  line-height: 20px;
}
span.dmn-sign.abs-moved {
  position: absolute;
  top: 2px;
  left: 111px;
  color: #000000;
  font-size: 14px;
}
span.dmn-sign {
  position: absolute;
  top: 2px;
  left: 17px;
  color: #000000;
  font-size: 14px;
}
span.dmn-sign.abs-moved-value_added {
  display: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.Toastify {
  z-index: 9999;
}
.signup-groups .group-control.domain-empty input.form-control {
  color: transparent !important;
}
.sign-process button.signpro-prev {
  border: 0;
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  padding: 0 22px;
}
.sign-process button.signpro-prev:hover {
  color: #8061b3;
}
.sign-process.prec-process {
  margin-top: 100px;
  justify-content: flex-end;
}
.sign-process.prec-process button.progress-next {
  margin-left: unset;
}
.frontend-choice.sing-color {
  background-color: #f5f5f5;
  border: 1px solid #9e9e9e;
}
.frontend-choice.no-color {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
}
.toptoast {
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
}
.toptoast .Toastify {
  top: 0;
  right: 0;
}
.count-valus button.btn.count-dec img {
  filter: grayscale(100%);
  opacity: 0.3;
}
.count-valus button.btn.count-dec:hover img {
  filter: grayscale(0);
  opacity: 1;
}
.count-valus button.btn.count-inc img {
  filter: grayscale(100%);
  opacity: 0.3;
}
.count-valus button.btn.count-inc:hover img {
  filter: grayscale(0);
  opacity: 1;
}
.frontend-choice img.plan-icon {
  max-width: 20px;
  filter: grayscale(100%);
  position: relative;
  top: -6px;
}
.frontend-choice.mul-color img.plan-icon {
  filter: grayscale(0);
}
.slick-slide,
.slick-slide img {
  outline: none !important;
}
.mul-color {
  cursor: pointer;
}
.no-color {
  cursor: pointer;
}
.empl-left h5 {
  width: 100%;
  text-align: right;
  font-size: 13px;
  margin: 0;
  font-style: italic;
  color: #000000;
  line-height: 24px;
}

.register-success-page p {
  text-align: center;
  color: #000;
}

.register-success-page {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.register-success-page button {
  background-color: #8061b3;
  border-radius: 2px;
  border: 1px solid #8061b3;
  font-size: 16px;
  color: #fff;
  padding: 10px 36px;
}

.register-success-page button:hover {
  background-color: #53269e;
  border: 1px solid #53269e;
}

/*------------ New change -------*/
.text-lab {
  position: relative;
  border: 1px solid #e0e0e0;
}
.text-lab span {
  position: absolute;
  right: 40px;
  top: 3px;
  font-size: 14px;
  color: #8b8b8b;
}
.signup-groups .group-control.domain-empty .text-lab input {
  color: #000 !important;
}
.text-lab input#subdomain {
  width: 283px;
  border: none;
}
.text-lab .invalid-feedback {
  position: absolute;
}
.text-lab span.informs-globe {
  right: 4px;
  top: -7px;
  display: inline-block;
  cursor: pointer;
}
.text-lab span.informs-globe i {
  color: #d7a30b;
  font-size: 16px;
  cursor: pointer;
}
span.pro-updates img {
  cursor: pointer;
}
.no-data {
  height: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.no-data h3 {
  width: 100%;
}
.email-ver-button,
.email-ver-new {
  margin: 0 9px;
  border: none;
  background: #169b1a;
  padding: 6px 23px 8px 23px;
  color: #fff;
  line-height: normal;
  border-radius: 6px;
  font-size: 18px;
}
.email-ver-new {
  background: #b4002c;
}
.no-data input:focus {
  border: "2px solid grey";
}

.login-but {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login-but button {
  margin: 14px 6px;
  border: none;
  background: #eeeeee;
  padding: 8px 24px;
  color: #4d4d4d;
}
.login-but button i {
  font-size: 17px;
  margin-right: 4px;
}
.login-but button img {
  max-width: 19px;
  margin-right: 8px;
}
p.acc-quc {
  position: relative;
  background: #fff;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0;
}
p.acc-quc:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #dfdfdf;
  left: 0;
  bottom: 14px;
}
p.acc-quc b {
  background: white;
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0 10px;
  font-size: 14px;
  color: #757575;
}
