.features_page {
   text-align: center;

   h4 {
      color: #0c111d;
      font-size: 64px;
      font-weight: 500;
      line-height: 78px;

      span {
         color: #7b5ea5;
      }
   }

   h5 {
      color: #667085;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1px;
   }

   h6 {
      color: #0c111d;
      font-size: 28px;
      font-weight: 500;
   }

   a {
      background-color: transparent;
      color: #7b5ea5;

      svg {
         margin-left: 10px;
      }
   }

   p {
      color: #475467;
      font-size: 14px;
      font-weight: 500;
      width: 80%;
      margin: auto;
      margin-top: 20px;
   }

   img {
      margin-top: 28px;
   }

   :where(.shift_management, .employee, .timesheet, .invoicing, .compliance, .recruitment) {
      .child {
         padding: 10px;
         border-radius: 6px;
         -webkit-border-radius: 6px;
         -moz-border-radius: 6px;
         -ms-border-radius: 6px;
         -o-border-radius: 6px;
      }
   }

   .shift_management {
      .child {
         background-color: #f6f9ff;
      }
   }

   .employee {
      margin-top: 80px;

      .child {
         background-color: #fff6fc;
      }
   }

   .timesheet {
      .child {
         background-color: #f6fffe;
      }
   }

   .invoicing {
      margin-top: 80px;

      .child {
         background-color: #fefff6;
      }
   }

   .compliance {
      .child {
         background-color: #fffaf6;
      }
   }

   .recruitment {
      .child {
         background-color: #f8fff6;
      }
   }
}

.timesheet_invoice {
   margin-top: 150px;
}

.experience_benifit {
   background-color: #4a3a2f;
   position: relative;
   height: 368px;
   width: 100%;
   margin-top: 120px;
   margin-left: 0;

   .left_side {
      position: relative;

      img {
         position: absolute;
         left: 40px;
         bottom: 0;
      }
   }

   .right_side {
      display: flex;
      align-items: center;

      p {
         text-align: left;
         color: #ffffff;
         font-size: 24px;
         margin: unset;
         margin-bottom: 30px;
         font-weight: 400;
      }

      .purple_bg {
         background-color: #491495;
         border-radius: 8px;
         color: #fff !important;
         height: 40px;
         width: 180px;
         display: inline-flex;
         align-items: center;
         justify-content: center;
         border: 1px solid #fff;
         font-size: 16px;
         transition: all 0.3s ease-in-out;
         -webkit-transition: all 0.3s ease-in-out;
         -moz-transition: all 0.3s ease-in-out;
         -ms-transition: all 0.3s ease-in-out;
         -o-transition: all 0.3s ease-in-out;

         span {
            font-size: 12px;
         }

         &:hover {
            font-weight: 500;
            color: #491495 !important;
            border: 1px solid #491495;
            background-color: #fff;

            img {
               filter: brightness(0) saturate(100%) invert(8%) sepia(63%) saturate(6620%) hue-rotate(266deg) brightness(109%) contrast(99%);
            }
         }

         img {
            margin-left: 20px;
         }
      }

      .orange_outline {
         border: 1px solid #ffffff;
         border-radius: 8px;
         color: #ffffff;
         background: transparent;
         font-size: 16px;
         height: 40px;
         width: 180px;

         &:hover {
            background: #fe5a4f;
            color: #fff;
         }

         a {
            color: #fff;
         }

         a:hover {
            color: #fff;
         }
      }

      .get_start_book_demo {
         display: flex;
         justify-content: start;
      }
   }
}

@media screen and (max-width: 992px) {
   .features_page h4 {
      font-size: 44px;
      line-height: 58px;
   }

   .features_page .employee,
   .timesheet_invoice,
   .recruitment {
      margin-top: 20px;
   }
}

@media screen and (max-width: 768px) {
   .experience_benifit {
      .left_side {
         position: absolute;
         bottom: 0;
         left: 0;

         img {
            left: 10px;
            width: 70%;
         }
      }

      .right_side {
         p {
            font-size: 14px;
            width: unset;
         }
      }
   }
}

@media screen and (max-width: 678px) {
   .features_page h4 {
      font-size: 34px;
      line-height: 48px;
   }
}

@media screen and (max-width: 480px) {
   .features_page h4 {
      font-size: 24px;
      line-height: 38px;
   }
}

@media screen and (max-width: 375px) {
   .features_page h4 {
      font-size: 20px;
   }
}

.sigfr-width {
   width: 460px;
   height: 210px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #fff3da;
   border-radius: 120px;
   padding: 15px;
   gap: 15px;
   margin: auto;

   .right-subs {
      width: 53%;
      flex: 1 1;

      p {
         width: 100%;
         color: #000000;
         text-align: left;
         font-size: 14px;
         line-height: 25px;
         margin-bottom: 10px;
         min-height: 80px;
         font-weight: 300;


      }
   }

   p {
      width: 60%;
      text-align: center;
      color: #ffffff;
      margin: 0;

      span {
         width: 100%;
         display: block;
         line-height: 28px;
         text-transform: capitalize;
         color: #fff;
         font-size: 16px;
      }
   }

   .left-subs {
      width: 175px;
      height: 175px;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

}

.sigfr-width.sigfr-width2 .right-subs p,
.sigfr-width.sigfr-width2 .right-subs .trails-sec {
   text-align: right;
}

.get_started-call-to-action .sigfr-width .right-subs p {
   line-height: 20px;
}

.book_demo_cta {
   background-color: #ffa41d !important;
   font-weight: 600 !important;
   color: #fff !important;
   padding: 10px 22px;
   border-radius: 2px;
   font-size: 14px;
}

.trails-sec a {
   padding: 10px 22px;
   background-color: #8061b3;
   border-radius: 2px;
   color: #fff;
   font-size: 14px;
}


@media only screen and (max-width: 480px) {
   #timesheets .cont-featurs h6 br {
      display: block;
   }

   .sigfr-width .left-subs {
      display: none;
   }

   .free-offers .sigfr-width p {
      text-align: center !important;
   }

   .sigfr-width2 .right-subs {
      position: unset;
   }

   .sigfr-width .trails-sec {
      text-align: center !important;
   }
}