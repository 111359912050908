.feature_parts {
   // height: 87vh;
   align-items: center;
   .left-banner {
      width: 48%;
      .new_sht-list1 {
         background-image: linear-gradient(45deg, #5f4b95, #5f4b95);
         font-size: 24px;
         line-height: 42px;
      }
      h6 {
         font-size: 25px;
         font-weight: 500;
         color: #1a202c;
         margin: 5px 0 10px;
      }
   }
   .right_banners {
      width: 50%;
      position: relative;
      img {
         box-shadow: 16px 16px 40px #00000015;
         border-radius: 10px;
      }
   }
}
.feature_learnmore {
   background: #8061b3;
   color: #fff;
   font-size: 14px;
   font-weight: 500;
   &:hover {
      color: #fff;
      background: #541cb8 !important;
   }
}
.feature_content {
   width: 100%;
   padding: 50px 0 0;
   h5 {
      font-size: 32px;
      text-align: center;
      color: #404040;
      margin: 0 0 40px;
      font-weight: 600;
      span {
         color: #8061b3;
      }
   }
   p {
      font-size: 16px;
      color: #4c6280;
   }
   .feature_content_info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      margin: 20px 0;
      .feature_content_box {
         width: 48%;
         h6 {
            font-size: 16px;
         }
         p {
            font-size: 15px;
            margin: 0;
         }
      }

      .feature_content_img {
         width: 520px;
         img {
            width: 100%;
         }
      }
   }

   .card__feature {
      border: none;
      height: auto;
      min-height: 325px;
      box-shadow: none;
      .feature__img {
         width: 40px;
         height: 40px;
         img {
            width: 100%;
            height: 100%;
         }
      }
      .feature__img--img {
         height: 215px;
         img {
            height: 100%;
         }
      }
      h6 {
         color: #5f4b95;
         font-size: 16px;
         font-weight: 600;
         margin: 25px 0 10px;
         line-height: 27px;
      }
      p {
         width: 95%;
         font-size: 14px;
         line-height: 27px;
      }
      &.card__feature--new {
         align-items: center;
         text-align: center;
      }
      &.new__card {
         text-align: center;
         align-items: center;
         .feature__img {
            width: 275px;
            height: 275px;
         }
      }
   }
   .three__feature__img {
      display: flex;
      justify-content: space-evenly;
      position: relative;
      top: 60px;
   }
   .three__feature {
      min-height: auto;
      h6 {
         line-height: 27px;
         margin: 0 0 15px;
      }
   }
}

.employee_accordion {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 20px;
   background: #f7fafc;
   .card {
      height: auto;
      box-shadow: none;
      margin: 0;
      border: none;
      background: #fff;

      .card-header {
         border: none;
         box-shadow: none;
         padding: 0;
         background-color: transparent;
         .btn-link {
            width: 100%;
            padding: 10px 15px;
            color: #1a202c;
            font-weight: 500;
            text-shadow: 0 0 black;
            margin: 0;
            text-align: left;
            text-decoration: none;
            &.active {
               color: #5f4b95;
            }
         }
      }
      .card-body {
         font-size: 14px;
         line-height: 27px;
         color: #4c6280;
         font-weight: 400;
         padding-top: 0 !important;
      }
      &:has(.card-header:not .btn-link.active) {
         background: #fff;
      }
   }
}
.employee_accordion_img {
   width: 500px;
   margin: 0 auto;
   img {
      width: 100%;
      height: 100%;
      object-fit: contain;
   }
}
.features_cta {
   width: 100%;
   min-height: 200px;
   padding: 40px;
   border-radius: 10px;
   background: #fff3da;
   display: flex;
   align-items: center;
   gap: 40px;
   margin: 60px 0 0;
   // .left-features_cta {
   //    width: 200px;
   //    img {
   //       width: 100%;
   //    }
   // }
   .right-features_cta {
      width: 100%;
      text-align: center;
      h5 {
         font-size: 25px;
         color: #323232;
         font-weight: 600;
      }
      p {
         width: 100%;
         color: #000000;
         font-size: 14px;
         line-height: 25px;
         margin: 15px 0 30px;
      }
      .features_cta_btn {
         a {
            padding: 10px 22px;
            background-color: #8061b3;
            border-radius: 2px;
            color: #ffffff;
            font-size: 14px;
            &:hover {
               background-color: #541cb8;
            }
         }
      }
   }
}
.new_cta_section {
   text-align: center;
   margin: 20px 0;
   h6 {
      color: #5f4b95;
      font-size: 20px;
      margin: 0 0 15px;
   }
   p {
      width: 65%;
      margin: 0 auto;
      line-height: 27px;
      color: #1a202c;
      font-size: 15px;
   }
}

.recurit_cards {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: repeat(2, 1fr);
   grid-gap: 10px;
}

.recurit_card1 {
   grid-area: 1 / 1 / 2 / 2;
}
.recurit_card2 {
   grid-area: 1 / 2 / 3 / 3;
   align-self: center;
}
.recurit_card3 {
   grid-area: 2 / 1 / 3 / 2;
}
.recurit_card {
   width: 100%;
   padding: 15px;
   background: #fff;
   box-shadow: 0px 0px 24px #edf2f7;
   border-radius: 15px;
   h6 {
      color: #5f4b95;
      font-size: 16px;
      line-height: 27px;
      margin: 0 0 10px;
   }
   p {
      font-size: 14px;
      color: #1a202c;
      line-height: 26px;
   }
}
.recuritTab_section {
   width: 100%;
   margin: 85px 0;
   .nav {
      background: #f7fafc;
      border-radius: 15px;
      margin: 0 0 20px;
      .nav-link {
         font-size: 15px;
         font-weight: 500;
         cursor: pointer;
         &.active {
            background: #5f4b95;
            border-radius: 15px;
            font-weight: 600;
         }
      }
   }
   .tab-content {
      border: none;
      p {
         width: 80%;
         color: #1a202c;
         font-size: 15px;
         text-align: center;
         margin: 0 auto 30px;
      }
      .tab-content-img {
         width: 400px;
         margin: 0 auto;
         img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }
   }
}
.recuritCar_section {
   width: 100%;
   margin: 20px 0;
   padding: 20px 0;
   background: #f7fafc;
   .slick-slide {
      margin: 0 5px;
   }
   .slick-track {
      display: flex;
      padding: 15px 0;
   }
   .recuritCar_slide {
      width: 100%;
      background: #fff;
      border-radius: 15px;
      padding: 20px;
      h6 {
         color: #5f4b95;
         font-size: 15px;
      }

      p {
         font-size: 14px;
         color: #1a202c;
         line-height: 27px;
      }
   }
   .slick-dots {
      position: relative;
      bottom: 0;
      width: 80px;
      display: flex !important;
      justify-content: space-between;
      margin: 20px auto 0;
      background: #cbd5e0;
      border-radius: 25px;
      overflow: hidden;
      li {
         width: auto;
         height: auto;
         margin: 0;
         overflow: hidden;
         button {
            height: 5px;
            padding: 0;
            &::before {
               content: none;
            }
         }
         &.slick-active {
            button {
               width: 30px;
               background: #5f4b95;
            }
         }
      }
   }
}

/*********/
.invoice__carousel {
   .invoice__carousel_btns {
      width: 100%;
      position: relative;
      height: 290px;
      &::before {
         content: '';
         position: absolute;
         background: url('../../../images/feature-new/invoice/ellipse.svg') no-repeat;
         background-size: contain;
         background-position: center;
         transform: rotate(275deg);
         width: 100%;
         height: 100%;
         inset: 0;
         z-index: -1;
      }
      .carousel_btn {
         width: 48px;
         height: 48px;
         border: none;
         border-radius: 50%;
         display: grid;
         place-items: center;
         background: #e2e8f0;
         position: absolute;
         &.carousel_btn_1 {
            left: 25%;
            top: 30px;
         }
         &.carousel_btn_2 {
            right: 40%;
            top: -20px;
         }
         &.carousel_btn_3 {
            right: 18%;
            top: 39%;
         }
         &.carousel_btn_4 {
            right: 38%;
            bottom: -10px;
         }
         &.active {
            width: 64px;
            height: 64px;
            background: #5f4b95;
            img {
               filter: invert(1) brightness(50);
            }
            &.carousel_btn_1 {
               top: 20px;
            }
            &.carousel_btn_2 {
               top: -20px;
            }
            &.carousel_btn_3 {
               top: 38%;
            }
            &.carousel_btn_4 {
               bottom: -20px;
            }
         }
      }
   }
   .invoice__carousel_slides {
      .invoice__card {
         height: auto;
         min-height: 160px;
         padding: 20px;
         h6 {
            color: #5f4b95;
            font-size: 16px;
            font-weight: 600;
            margin: 25px 0 10px;
            line-height: 27px;
         }
         p {
            width: 95%;
            font-size: 14px;
            line-height: 27px;
            color: #4c6280;
         }
      }
      .slick-dots {
         position: relative;
         bottom: 0;
         width: 80px;
         display: flex !important;
         justify-content: space-between;
         margin: 0;
         border-radius: 25px;
         overflow: hidden;
         li {
            width: auto;
            height: auto;
            margin: 0;
            overflow: hidden;
            button {
               width: 8px;
               height: 5px;
               padding: 0;
               border-radius: 25px;
               background: #cbd5e0;
               &::before {
                  content: none;
               }
            }
            &.slick-active {
               button {
                  width: 30px;
                  background: #5f4b95;
               }
            }
         }
      }
   }
}
/*********/
.communication_section {
   text-align: center;
   .container {
      > h6 {
         color: #5f4b95;
         font-size: 20px;
         margin: 0 0 15px;
      }
      > p {
         width: 65%;
         margin: 0 auto;
         line-height: 27px;
         color: #1a202c;
         font-size: 15px;
      }
      .com__card {
         text-align: center;
         box-shadow: none;
         border: none;
         h6 {
            color: #1a202c;
            font-size: 16px;
            font-weight: 600;
            margin: 25px 0 10px;
            line-height: 27px;
         }
         p {
            font-size: 14px;
            line-height: 27px;
            color: #1a202c;
         }
      }
   }
}
.group_section {
   text-align: center;
   .card {
      width: 65%;
      min-width: 700px;
      padding: 20px !important;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin: 0 auto;
      text-align: left;
      box-shadow: none;
      border: none;
      background: #f7fafc;

      .group_img {
         width: 220px;
         height: 200px;
         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
      .group_info {
         width: 70%;
         h6 {
            color: #1a202c;
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 15px;
            line-height: 21px;
         }
         p {
            font-size: 14px;
            line-height: 27px;
            color: #1a202c;
            margin: 0;
         }
      }
   }
}

.stake_section {
   width: 100%;
   margin: 40px 0;
   padding: 40px 0;
   .stake_head {
      h6 {
         color: #5f4b95;
         font-size: 18px;
         margin: 0 0 10px;
      }
      p {
         line-height: 27px;
         color: #1a202c;
         font-size: 14px;
      }
   }
   .stake_list {
      width: 100%;
      padding: 0 0 0 40px;
      li {
         position: relative;
         margin: 0 0 20px;
         span {
            width: 32px;
            height: 32px;
            overflow: hidden;
            position: absolute;
            left: -45px;
            top: 30px;
            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
         h6 {
            color: #1a202c;
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 5px;
            line-height: 27px;
         }
         p {
            font-size: 14px;
            line-height: 27px;
            color: #1a202c;
         }
      }
   }
}

.category_section {
   background: #f7fafc;
   padding: 20px 0;
   .cat_head {
      text-align: center;
      h6 {
         color: #5f4b95;
         font-size: 20px;
         margin: 0 0 15px;
      }
      p {
         width: 65%;
         margin: 0 auto;
         line-height: 27px;
         color: #1a202c;
         font-size: 15px;
      }
   }
   .row {
      justify-content: center;
      .card__cat {
         border: none;
         box-shadow: none;
         margin: 0;
         padding: 20px !important;
         h6 {
            color: #1a202c;
            font-size: 16px;
            font-weight: 600;
            margin: 15px 0 10px;
            line-height: 27px;
         }
         p {
            font-size: 14px;
            line-height: 27px;
            color: #1a202c;
         }
      }
   }
}
.stream_section {
   width: 100%;
   margin: 40px 0;
   padding: 40px 0;
   .streamlines_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      gap: 15px;
      li {
         width: 470px;
         background: #f7fafc;
         padding: 10px 20px;
         border-radius: 10px;
         text-align: left;
         h6 {
            color: #1a202c;
            font-size: 14px;
            font-weight: 600;
            margin: 5px 0 10px;
            line-height: 21px;
         }

         p {
            color: #1a202c;
            font-size: 13px;
            font-weight: 500;
            margin: 0 0 10px;
            line-height: 27px;
         }
         &:nth-child(even) {
            margin-left: auto;
            background: #edf2f7;
            text-align: right;
         }
      }
   }
}

.streamlines_flows {
   width: 100%;
   text-align: right;

   .streamlines_flows_img {
      width: 340px;
      height: 290px;
      overflow: hidden;
      margin: 0 auto;
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   h6 {
      color: #5f4b95;
      font-size: 18px;
      font-weight: 600;
      margin: 20px 0 10px;
      line-height: 21px;
   }
   p {
      color: #1a202c;
      font-size: 13px;
      font-weight: 500;
      margin: 0 0 10px;
      line-height: 27px;
   }
}
.shift_cover_banners {
   .home-banner {
      padding: 0;
   }
   .feature_parts{
      // height:50vh;
   }
}

.shift_feature_content {
   padding: 0;

   .shift_accessibility {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 94%;
      margin: auto;
   }

   .feature_image {
      padding: 20px;
      margin: 12px;
      text-align: left;
      width: 300px;
      height: 360px;
      position: relative;
      top: 30px;
   }

   .shift_accessibility_card {
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      text-align: center;
      max-width: 300px;
      margin: 12px;
      text-align: left;
      width: 300px;
      height: 360px;
   }

   .shift_accessibility_card-icon {
      font-size: 30px;
      margin-bottom: 10px;
   }

   .shift_accessibility_card-title {
      font-size: 16px;
      font-weight: bold;
      // margin-bottom: 10px;
      line-height: 40px;

   }

   .shift_accessibility_card-description {
      font-size: 14px;
      color: #1a202c;
      line-height: 25px;

   }



   /* WhyChoose.css */
   .why-choose-section {
      background-color: #6a41b4;
      /* Purple background */
      padding: 30px;
      border-radius: 15px;
      margin: auto;
      margin-top: 46px;
      width: 94%;
      // height: 357px;
      .tryforfree{
         color: #6a41b4;
         border: 1px solid;
         background: #ffff;
         font-size: 14px;
         width: 28%;
         a{
            color: #6a41b4;
         }
      }
   }

   .why-choose-heading {
      font-weight: bold;
      margin-bottom: 15px;
      color: #ffffff;
      font-size: 32px;
      line-height: 40px;

   }

   .why-choose-description {
      font-size: 16px;
      line-height: 1.5;
      color: #ffffff;
      width: 530px;
      margin-bottom: 0.5rem;
   }

   .why-choose-image {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      top: 30px;
      position: relative;
   }


}
/*********/
.comp_subanners_section {
   width: 100%;
   margin: 60px 0;
}
.comp_subanners {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   // grid-template-rows: repeat(2, 1fr);
   grid-column-gap: 0px;
   grid-row-gap: 0px;
   img{
      width: 100%;
   }
}

.comp_subanners1 {
   grid-area: 1 / 1 / 2 / 2;
}
.comp_subanners2 {
   grid-area: 1 / 2 / 2 / 3;
}
.comp_subanners3 {
   grid-area: 2 / 1 / 3 / 2;
}
.comp_subanners4 {
   grid-area: 2 / 2 / 3 / 3;
}

.get_started_free_btn{
   color:#ffff ;
   border: 1px solid;
   background:#8061b3;
   padding: 0;
   &:hover{
      color: #fff;
      background-color: #541cb8;
      transition: all .3s;
   }
   a{
      color: #ffff;
      width: 100%;
      display: inline-block;
      // padding: 0.375rem 0.75rem;
      padding: 0.6rem 0.75rem;
      
   }
}

.get_started-call-to-action{
   margin: 20px auto !important;
   .sigfr-width .right-subs p{
      line-height: 20px;
   }
}

.book_a_demo_view{
   gap: 25px;
}
.book_a_demo_btn{
   width: 125px !important;
   // background-color: #8061b3;
   color: white;
   border: none !important;
   padding: 0 !important;
   &:hover{
      color: #fff;
      // background-color: #541cb8;
      transition: all .3s;
   }
   a{
      color: #ffff;
      width: 100%;
      padding: 0.375rem 0.75rem;
      display: inline-block;
   }
}


.view_features_row{
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   gap: 1rem;
   grid-gap: 1rem;

   .book_a_demo_btn{
      height: 38px;
   }
}




@media screen and (max-width: 1200px) {
   .feature_content {
      h5 {
         font-size: 24px;
      }
      .feature_content_info {
         justify-content: center;
         p {
            width: 100%;
         }
         .feature_content_box {
            width: 100%;
         }
      }
   }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
   .recurit_card1,
   .recurit_card2,
   .recurit_card3 {
      grid-area: auto / 1 / auto / -1;
   }
}
@media screen and (max-width: 990px) {
   .feature_parts {
      height: auto;
      .left-banner {
         width: 100%;
      }
      .right_banners {
         width: 90%;
         margin: 0 auto;
      }
   }
   .recuritTab_section {
      .nav {
         white-space: nowrap;
         flex-wrap: nowrap;
         overflow: auto;
         padding: 0 0 10px;
         &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: #f5f5f5;
         }

         &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.8, #898989), color-stop(0.6, #949494), color-stop(0.4, #b1b1b1));
         }

         &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
            border-radius: 10px;
         }
      }
   }
}
@media screen and (max-width: 575px) {
   .recuritTab_section .tab-content p {
      width: 100%;
   }
   .recurit_card1,
   .recurit_card2,
   .recurit_card3 {
      grid-area: auto / 1 / auto / -1;
   }
}
