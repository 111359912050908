/* remove images */
.about-app__description-title {
  margin: 35px 0;
}
.about-app__description-title-img img {
  float: left;
  width: 32px;
  height: auto;
  margin: 4px 0 0 9px;
}
#pricing {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.btn-nav__mobile {
  margin-left: 20%;
  margin-right: 20%;
}
.about-app__description-title-img img {
  max-width: 32px;
}
img.slide-img-mobile {
  max-width: 500px;
  text-align: center;
  margin: auto;
}
.header-home__btn {
  margin: 8px 0 8px 0;
}
.btn-head__2 {
  margin-left: 0;
  margin-right: 0;
}
.btn-head__3 {
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: left;
  text-decoration: none;
  transition: all 0.3s;
  height: auto;
  cursor: pointer;
}
.btn-head__3:active {
  outline: none;
}
.flex-container_2--centered {
  text-align: center;
}
.flex-container_2-title {
  text-align: left !important;
}
img.flex-container_2-image {
  max-width: 800px;
  width: 100%;
  margin: auto;
}
.flex-container {
  text-align: left;
}
img.flex-icon {
  width: 88px;
}
.flex-description {
  font-size: 16px;
  text-align: justify;
}
.flex-description--centered {
  text-align: justify;
}
/* Flex Container 1 , 2 , 3, 4 */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 50px 10px 0;
}
.flex-container > div {
  width: 500px;
  margin: 50px auto;
  text-align: center;
  font-size: 20px;
}
.flex-container_2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.flex-container_2 > div {
  width: 100%;
  margin: 10px auto;
  text-align: center;
  font-size: 20px;
}
.flex-container_3 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 10px 0;
}
.flex-container_3 > div {
  width: 500px;
  margin: 10px auto;
  text-align: center;
  font-size: 20px;
}
a.flex-container_3 {
  color: inherit;
}
.flex-container_4 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

/* footer flex */
.flex-container-footer__logo {
  width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  transition: all 0.5s ease;
  text-align: center;
}
.flex-container-footer-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.flex-container-footer {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}
.flex-container-footer > div {
  width: 200px;
  margin: 0px auto;
  text-align: center;
  font-size: 20px;
}
.flex-container__row {
  padding-top: 200px;
}
.flex-hr {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: none;
}

img.slide-img-mobile,
img.gif-hover,
video,
img.flex-container__img,
.btn-head {
  box-shadow: 0 8px 15px rgba(125, 147, 178, 0.25);
}

/* Media Queries */

/* MEDIA */ /* MEDIA */ /* MEDIA */
/* MEDIA */ /* MEDIA */ /* MEDIA */
/* MEDIA */ /* MEDIA */ /* MEDIA */
/* MEDIA */
/* MEDIA */
/* MEDIA */
/* MEDIA */
/* MEDIA */
/* MEDIA */
/* MEDIA */
/* MEDIA */
/* MEDIA */ /* MEDIA */ /* MEDIA */
/* MEDIA */ /* MEDIA */ /* MEDIA */
/* MEDIA */ /* MEDIA */ /* MEDIA */

// Devices   :
//   iPhone  :
// iPhone        : 320 x 480
// iPhone 4      : 640 x 960   : DPR : 2 = 320 x 480
// iPhone 5      : 640 x 1136  : DPR : 2 = 320 x 568
// iPhone 6      : 750 x 1334  : DPR : 2 = 375 x 667
// iPhone 6P     : 1242 x 2208 : DPR : 3 = 414 x 736
//   Samsung :
// Samsung SI    : 480 x 800   : DPR : 1.5
// Samsung SIII  : 720 x 1280
//   Tablets  :
// iPad 1        : 768 x 1024
// iPad 3        : 1536 x 2048 : DPR : 2 = 768 x 1024

// /* MEDIA *//* MEDIA *//* MEDIA */

// /* Mobile Phones Portrait */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .d-t-none {
    display: none;
  }
  .menu .d-l-none {
    display: none;
  }
}
/* Mobile Phones Landscape */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  .d-t-none {
    display: none;
  }
  .d-l-none {
    display: none;
  }
}
/* Mobile Phones Portrait or Landscape */
@media screen and (max-device-width: 640px) {
  .d-t-none {
    display: none;
  }
  .d-l-none {
    display: none;
  }
}
/* iPhone 4 Portrait or Landscape */
@media screen and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .d-t-none {
    display: none;
  }
  .d-l-none {
    display: none;
  }
}
/* iPhone 5 Portrait or Landscape */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .d-t-none {
    display: none;
  }
  .d-l-none {
    display: none;
  }
}
/* iPhone 6 and 6 Plus Portrait or Landscape */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .d-t-none {
    display: none;
  }
  .d-l-none {
    display: none;
  }
}
/* TABLETS TABLETS TABLETS */
/* Tablets Portrait or Landscape */
@media screen and (min-device-width: 768px) and (min-device-height: 1024px) {
}

/* DESKTOP DESKTOP DESKTOP */
/* DESKTOP DESKTOP DESKTOP */
/* DESKTOP DESKTOP DESKTOP */



@media screen and (max-width: 1400px) {
  .header-home .header-home__webapp-img {
    width: calc(100% - 370px);
    height: auto;
  }
}
@media screen and (max-width: 1200px) {
  .header-home .header-home__description--webapp {
    width: 420px;
  }
  .header-home .header-home__webapp-img {
    right: -60px;
  }
  .header-home .header-home__title {
    margin-top: 0;
  }
  .container {
    padding: 0 32px;
  }
}

@media screen and (max-width: 990px) {
  .carousel .slider {
    width: 100%;
  }
  .slick-pev,
  .slick-next {
    display: none !important;
  }
  .d-t-none {
    display: none;
  }
  .d-none {
    display: inline;
  }
  .img-vid {
    max-width: 100%;
  }
}
/* from 799px and greater */
/* centralised */
@media screen and (min-width: 799px) {
  .btn-head__2 {
    margin-left: 30%;
    margin-right: 30%;
  }
}

/* from 0 to 799px */
@media screen and (max-width: 799px) {
  .header-home__title--big {
    margin-top: 0;
  }
  .section.section--light {
    padding-top: 120px;
  }
  .menu.menu--active {
    padding-top: 10px;
    height: 70px;
  }
  .menu__right-nav {
    padding-top: 10px;
  }
  button.menu__mobile-button {
    margin-top: 10px;
  }
  .col-1,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-m-2,
  .col-m-3,
  .col-m-4,
  .col-m-5,
  .col-m-6,
  .col-m-7,
  .col-m-8,
  .col-m-9,
  .col-m-10,
  .col-m-11,
  .col-m-12,
  .col-t-1,
  .col-t-2,
  .col-t-3,
  .col-t-4,
  .col-t-5,
  .col-t-6,
  .col-t-7,
  .col-t-8,
  .col-t-9,
  .col-t-10,
  .col-t-11,
  .col-t-12 {
    width: 100%;
  }
  img.flex-icon {
    width: 70px;
  }
  .footer-header,
  .footerNavRow li {
    line-height: 26px;
  }
  .footerNavRow ul {
    margin: 8px 0;
  }
  .footer-wrapper {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  .flex-container-footer > div {
    width: 300px;
    margin: 0px auto;
    text-align: center;
    font-size: 20px;
  }
  .header-home .header-home__description--webapp {
    width: 100%;
    margin-bottom: 40px;
  }
  .section.section--first {
    margin-top: 70px;
  }
  .section {
    padding-bottom: 40px;
  }
  .header-home__webapp-img {
    display: none;
  }
  .section__description {
    text-align: center;
  }
  .d-l-none {
    margin-right: 40px;
  }
  .d-t-none {
    display: none;
  }
  .d-none {
    display: inline;
  }
  .flex-container_2-title {
    text-align: center;
  }
  /* Features Carousel */
  .carousel .carousel__slide {
    display: block;
  }
  .carousel .carousel__slide-content {
    max-width: 100%;
    margin-bottom: 25px;
  }
  .carousel__slide-img {
    max-width: 100%;
  }
  .carousel__header {
    padding-top: 24px;
  }
  .carousel__navigation-header {
  }
  .carousel__navigation-cards {
  }
  .carousel .slider {
    width: calc(100% - 20px);
  }
  .carousel .carousel__slide-img {
    max-width: 100%;
  }
  .carousel__navigation-item img {
    height: 70px !important;
    transition: all 0.8s;
  }
  .site-btn--center .site-btn {
    margin: 0 10%;
  }
  .site-btn--center.sitebtn {
    position: relative;
    bottom: 0;
  }
  .col-10 {
    width: 100%;
  }
  .about-app {
    text-align: center;
    margin-top: 60px;
  }
  /* Pricing Card */
  .pricing__card {
    margin: 0;
  }
  .pricing__card-price p {
    margin-bottom: 2px;
  }
  .pricing__card-price {
    margin: 20px 0;
  }
  .pricing__card-price #month,
  #year {
    font-size: 20px;
    font-weight: bold;
    margin: 0 10px;
  }
  .pricing .pricing__plan {
    width: 100%;
    position: absolute;
    visibility: hidden;
  }
  .pricing .pricing__plan.pricing__plan--hidden {
    display: block;
  }
  .pricing .pricing__plan.pricing__plan--hidden .pricing__card-title {
    color: #4c6280;
    margin-top: 50px;
  }
  .pricing .pricing__plan--last {
    position: static;
  }
  .pricing__card-title {
    font-size: 20px;
  }
  .pricing__opportunities {
    font-size: 20px;
  }
  .pricing--select-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .pricing--select-description {
    font-size: 20px;
    text-align: center;
  }
  .download__title {
    font-size: 20px;
  }
  .download__description {
    font-size: 20px;
  }
  .section .section__title {
    margin-bottom: 40px;
  }
  .shift-scheduling {
  }
  .about-app__description-title-img img {
    float: left;
    width: 32px;
    height: auto;
    margin: 4px 0 0 9px;
  }
  .header-home__title {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .header-home__50 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .flex-description {
  }
}

@media screen and (max-width: 650px) {
  .d-l-none {
    display: none;
  }
  .mobile-menu {
    bottom: 0;
  }
  .mobile-menu__wrapper {
    margin-top: auto;
    margin-bottom: auto;
  }
}
/* from 500px + */
@media screen and (min-width: 500px) {
  .mobile-menu__logo {
    top: 60px !important;
    left: 32px !important;
  }
  .mobile-menu__close {
    top: 68px !important;
    right: 48px !important;
  }
  .menu {
    padding-top: 10px;
  }
}
/* from 0 to 500px */
@media screen and (max-width: 500px) {
  /* Menu */
  .menu__right-nav {
    padding-top: 0px;
  }
  button.menu__mobile-button {
    margin-top: 0px;
    right: 30px;
    top: 18px;
  }
  .menu__logo-img {
    width: 32px;
  }
  .menu.menu--active .menu__mobile-button {
    top: 6px;
  }
  .menu.menu--active .menu__logo-img {
    width: 32px;
  }
  .menu.menu--active .menu__logo-title {
    margin-top: 0px;
  }
  .menu .menu__logo {
    padding-left: 15px;
  }
  .menu.menu--active {
    height: 64px;
    padding-top: 16px;
  }
  .menu__item .d-t-none {
    display: none;
  }
  .d-none {
    display: inline;
  }

  .container {
    padding: 0;
  }
  /* Features */
  .footer-header {
    font-size: 14px;
    transition: all 0.5s;
  }
  .flex-container-footer__logo {
    width: 100%;
    text-align: center;
  }
  .flex-container-footer {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  h3,
  h4 {
    font-size: 24px;
    line-height: 36px;
  }
}

.features-flex-container__img {
  float: right;
}
