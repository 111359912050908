@media only screen and (max-width: 1200px) {
	#root .site-btn {
		font-size: 15px;
		padding: 10px 25px;
		margin: 0 auto;
	}

	.container {
		padding: 0 32px !important;
	}
}
@media only screen and (min-width: 990px) and (max-width: 1200px){
	.banner-parts .try-free button.btn {
		margin-bottom: 15px;
		margin-right: 10px;
		margin-left: 0px;
	}
	.header-sections ul.inner-menu.menu-gains.shower-menus {
		right: 0;
	}
}
@media only screen and (max-width: 990px) {
	.banner-parts .try-free button.btn {
		margin-bottom: 15px;
		margin-right: 10px;
		margin-left: 0px;
	}
	.header-sections ul.inner-menu.menu-gains.shower-menus {
		right: 0;
	}

	.d-none {
		display: inline !important;
	}

	img.contact-banner {
		max-height: 400px;
		width: 100%;
		object-fit: cover;
	}
}

@media only screen and (max-width: 767px) {
	img.contact-banner {
		max-height: 250px !important;
		width: 100%;
		object-fit: cover;
	}

	body {
		overflow-x: hidden;
	}

	.row {
		margin-left: -15px;
		margin-right: -15px;
		display: block !important;
		flex: none;
	}

	.left-edit-side {
		width: 100% !important;
	}

	.right-edit-side {
		width: 100% !important;
	}

	.form-group.left {
		float: none !important;
	}

	.new-BtnNew {
		position: fixed !important;
		bottom: 0px;
		background: #000 !important;
		opacity: 1;
		z-index: 1;
		left: 0px;
	}

	.btn-secondary {
		width: 100% !important;
	}

	.form__card.card.form__card--background.float.new-forms-profile {
		padding: 15px !important;
	}

	.col-2 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-4 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-6 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-8 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-10 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-12 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.features svg {
		margin-bottom: 35px;
		margin-top: 0px;
		margin-left: auto;
		margin-right: auto;
	}

	.header-home .header-home__title {
		padding: 10px;
	}

	.header-home .header-home__description {
		padding: 10px;
	}

	.header-home__btn {
		width: 100%;
	}

	.container {
		max-width: 90% !important;
	}

	.about-app .about-app__img img {
		max-width: 100% !important;
	}

	.about-app {
		text-align: center;
	}

	.logo .col-2.logo__img-wrap {
		width: 100% !important;
	}

	.features-cards-details .col-2.col-m-4.col-l-6 {
		width: 100% !important;
	}

	.partners-logos .col-2.logo__img-wrap {
		width: 100% !important;
	}

	.leadership .col-3 {
		max-width: 100% !important;
	}

	.logo .col-2 {
		width: 100% !important;
	}

	.radio-btn {
		padding-right: 32px;
		display: inline-block;
		width: 50%;
	}

	.form .form__wrap {
		max-width: 100% !important;
	}

	button.site-btn.site-btn--accent.form__submit.disable {
		width: 100%;
	}

	.leadership .col-3.col-t-6 {
		max-width: 100%;
		float: left;
	}

	.opportunities .col-4.col-l-6 {
		width: 100% !important;
		float: left;
	}

	.width_less .col-md-6 {
		width: 100% !important;
		float: left;
	}

	.checkbox-btn {
		display: block !important;
		float: left;
		width: 100%;
	}

	.footer-menu .footer-menu__nav li {
		display: inline-block;
		float: left;
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}

	.form__card.card.form__card--background.float.shift-lidt.mobile-shift {
		padding: 15px !important;
	}

	.form__card.card.form__card--background.float.mobile-earnings {
		padding: 15px !important;
	}

	.left-search {
		width: 100% !important;
	}

	.left-export {
		width: 100% !important;
		display: inline-block;
		float: left;
	}

	.left-export button {
		width: 100% !important;
	}

	.left-dachs button {
		width: 100%;
	}

	.DateRangePickerInput__showClearDates {
		padding: 0px !important;
	}

	.right-search {
		width: 100% !important;
	}

	.right-select {
		width: 100% !important;
	}

	.right-vara {
		width: 100% !important;
	}

	.right-select select.btn.btn-primary.rounded-0 {
		margin-top: 10px !important;
	}

	.new-cards-profiles.card-body {
		padding: 0px;
	}

	.profiles-details-cards .card .p-3.card-body {
		padding: 15px !important;
	}

	.profiles-details-cards .card {
		padding: 10px !important;
	}

	.ipad-devices.col-12.col-sm-6.col-lg-6 {
		float: left;
		width: 100% !important;
	}

	.adj-search {
		padding: 18px;
	}

	.form__card.card.form__card--background.float.shift-views-larges {
		padding: 15px !important;
	}

	.wholetask {
		border: 1px solid #ddd;
		float: left;
		width: 100%;
	}

	.pay-comp.card-body {
		padding: 5px;
	}

	.mobile-earnings .card {
		padding: 0px;
	}

	.mobile-earnings .clearfix.p-3.card-body {
		padding: 15px !important;
	}
}

@media only screen and (max-width: 1023px) {
	body {
		overflow-x: hidden;
	}

	.profiles-details-cards .card .p-3.card-body {
		padding: 15px !important;
	}

	.profiles-details-cards .card {
		padding: 10px !important;
	}

	.ipad-devices.col-12.col-sm-6.col-lg-6 {
		float: left;
		width: 50%;
	}

	.right-search {
		width: 100%;
		float: right;
	}

	.right-select {
		width: 15%;
		float: left;
	}

	.right-vara {
		width: 80%;
		float: left;
	}

	.left-search {
		width: 100% !important;
		float: left;
		margin-bottom: 20px;
	}

	.header-home .header-home__title {
		width: 100% !important;
	}

	.header-home .header-home__description {
		width: 100% !important;
	}

	.row {
		margin-left: -15px;
		margin-right: -15px;
		display: block !important;
		flex: none;
	}

	.col-2 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-4 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-6 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-8 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-10 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.col-12 {
		flex: 100% !important;
		max-width: 100% !important;
	}

	.features svg {
		margin-bottom: 35px;
		margin-top: 0px;
		margin-left: auto;
		margin-right: auto;
	}

	.header-home .header-home__title {
		padding: 10px;
	}

	.header-home .header-home__description {
		padding: 10px;
	}

	.container {
		max-width: 90% !important;
	}

	.about-app .about-app__img img {
		max-width: 100% !important;
	}

	.about-app {
		text-align: center;
	}

	.col-2.logo__img-wrap {
		width: 100% !important;
	}

	.features-cards-details .col-2.col-m-4.col-l-6 {
		width: 50%;
		float: left;
	}

	.features-cards-details .col-2.col-m-4.col-l-6:nth-child(5) {
		width: 100% !important;
	}

	.partners-logos .col-2.logo__img-wrap {
		width: 20%;
		float: left;
	}

	.about-app .col-6.about-app__description {
		float: left;
		width: 100%;
	}

	.about-app .col-6.about-app__img {
		width: 100%;
	}

	.col-4.col-m-6.feedback__item {
		width: 100%;
	}

	section.section.section--dark {
		float: left;
		width: 100%;
	}

	.tab-service-links .col-2.topbar__item {
		width: 16.6667%;
		float: left;
	}

	.header-home .header-home__description--about {
		max-width: 100% !important;
		margin-bottom: 0px !important;
	}

	.opportunities .col-4.col-l-6 {
		width: 50%;
		float: left;
	}

	.gallery .col-6.gallery__wrap {
		width: 100%;
	}

	.leadership .col-3.col-t-6 {
		max-width: 50%;
		float: left;
	}

	.col-2.logo__img-wrap {
		width: 19.6667%;
		float: left;
	}

	.new-downlods .col-6 {
		width: 50%;
		float: left;
	}

	.register-froms .col-8.col-offset-2 {
		width: 100%;
	}

	.width_less .col-md-6 {
		width: 50%;
		float: left;
	}

	.checkbox-btn {
		display: block !important;
		float: left;
		width: 100%;
	}

	p.forget_password a {
		float: none !important;
	}

	.left-menus ul li {
		padding: 8px 5px !important;
	}

	.right-search {
		width: 98% !important;
		float: right;
	}

	.adj-search {
		padding: 8px !important;
	}
}

@media only screen and (max-width: 1024px) {
	.feat-tabs img {
		max-height: 300px !important;
	}

	.features-cards-details .col-2 {
		max-width: 50%;
		float: left;
		flex: 0 0 19.66667%;
	}

	.features svg {
		margin-left: auto;
		margin-right: auto;
	}

	p.forget_password a {
		float: none !important;
	}

	.left-search {
		width: 100% !important;
		float: left;
		margin-bottom: 20px;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
	.right-search {
		width: 75% !important;
		float: right;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
	.mobile-shift .card {
		width: 50%;
		float: left;
	}

	.wholetask {
		float: left;
		width: 100%;
	}
}

@media only screen and (min-width: 799px) and (max-width: 1024px) {
	.flex-container-footer>div {
		width: 25% !important;
	}
}

@media only screen and (max-width: 767px) {
	#footer .flex-container-footer__logo {
		width: 100%;
	}
}

@media screen and (max-width: 799px) {
	.flex-container-footer>div {
		width: 25% !important;
	}
}

@media only screen and (min-width: 767px) {
	.section.section--top-space {
		min-height: calc(100vh - 461px) !important;
	}

	footer {
		height: 461px;
	}
}

@media only screen and (max-width: 767px) {
	#footer .flex-container-footer>div {
		width: 100% !important;
	}
}

@media only screen and (max-width: 567px) {
	.try-free button.btn {
		margin-bottom: 15px;
		margin-right: 10px;
	}
	.form .cont-sections .form__form-group .lab-left {
		width: 100% !important;
	}

	.form .cont-sections .form__form-group .right-width {
		width: 100% !important;
		padding: 0 !important;
	}

	.form .cont-sections .form__form-group .right-wid {
		width: 50% !important;
		padding: 0 10px 0 0 !important;
	}
}

@media only screen and (max-width: 414px) {
	.banner-parts .left-banner h4 {
		font-size: 22px;
	}

	.banner-parts .left-banner h6 {
		font-size: 18px;
	}

	.try-free button.btn.tre-subs {
		font-size: 13px;
		padding: 0 10px;

	}

	.try-free input.form-control {
		font-size: 13px;
		text-indent: 8px;

	}
}

/*** profile ***/


.allow-dropdown input {
	height: 35px !important;
	border-radius: 0px;
}

input.form-control.serach-place {
	height: 35px !important;
	border-radius: 0px;
}

.TabpaneList li.nav-item {
	padding: 0px !important;
}

.SelectDate select {
	height: 35px !important;
	border-radius: 0px;
}

.availableDate {
	float: left;
	width: 100%;
	text-align: left;
	font-size: 15px;
	color: #544f4f;
	padding: 0 0 10px;
	margin: 0;
}

button.mt-5.additionalAdd.btn {
	padding: 7px !important;
	border-radius: 0px;
	margin-top: 41px !important;
}

.left-menus ul li:hover {
	background: #00adf0;
	color: #fff;
	cursor: pointer;
}

.changeShift {
	float: left;
	width: 100%;
	margin: 0px auto;
	text-align: center;
}

.new-BtnNew {
	padding: 15px !important;
	float: right;
	position: absolute;
	right: 10px;
}

.new-overall {
	position: relative;
	height: 60px;
}

span.shiftComp.badge.badge-danger {
	padding: 15px;
	border-radius: 3px;
}

.timesheet-Send {
	padding: 15px !important;
	border-radius: 3px;
	color: #fff !important;
	margin-top: 15px;
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.processCompleted {
	padding: 15px !important;
	border-radius: 3px;
}

.form__card.card.form__card--background.float.shift-lidt.mobile-shift {
	padding: 20px !important;
}

.new-list-parts {
	top: 20px !important;
	right: 20px !important;
}