.document__SignBox {
    background: #eee;

    .document__head {
        width: 100%;
        height: 60px;
        background: #fff;
        display: flex;
        justify-content: center;
        margin: 0 0 15px;
        position: sticky;
        top: 0;

        .document__logo {
            width: 100px;
            display: inline-block;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .close-doc {
            position: absolute;
            right: 20px;
            top: 15px;
            border: none;
            background: transparent;
            padding: 0;
        }

        .close-doc-start {
            position: absolute;
            right: 20px;
            top: 15px;
            padding: 0;
            border-radius: 5px;
            color: rgb(0, 0, 0);
            background: rgb(252, 217, 65);
            width: 80px;
            height: 36px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            grid-gap: 5px;
            gap: 5px;
            margin: 0 auto;
            font-weight: 600;
        }
    }

    .document__content {
        width: 55%;
        min-height: calc(100vh - 90px);
        margin: 0 auto;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        color: #424242;
    }

    .document__signature {
        position: relative;

        .document__addSignature {
            width: 200px;
            height: 90px;
            border: 0.5px solid #5cb648;
            padding: 10px;
            border-radius: 5px;
            color: #5cb648;
            background: #e5ffdf;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            margin: 0 0 15px;
        }

        .document__fullName {
            width: 200px;
            height: 35px;
            border: 0.5px solid #5cb648;
            padding: 10px;
            border-radius: 5px;
            background: #e5ffdf;
            font-size: 13px;
            line-height: 24px;
            color: #424242;
            box-shadow: none;
        }

        .document__submit {
            width: 110px;
            height: 40px;
            border-radius: 25px;
            color: #fff;
            background: #5cb648;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            margin: 0 auto;
            transition: .2s ease-in;

            &:hover {
                background: #781C68;
                transition: .2s ease-in;
            }
        }

        .docs-signedtext {
            text-align: center;
            color: #459134;
        }

        .c-group.view-logo {
            position: absolute;
            top: 0;
            left: 215px;
            width: 200px;
            height: 90px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.modal-dialog.signat {
    .modal-header {
        .modal-title {
            font-family: "AvertaRegular";
        }
    }

    .modal-body {
        .nav.nav-tabs {
            box-shadow: none;

            border-bottom: 0;

            .nav-item {
                .nav-link {
                    border: 1px solid transparent;

                    &.active {
                        border-bottom: 0;
                        border-radius: 0;

                        &::before {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .choose-font {
            background: #fff;
            top: 30px;
            left: 30px;
            border-radius: 25px;

            select {
                padding: 5px 10px;
            }
        }
    }

    .modal-footer {
        .sig-clear.new_clear {
            position: unset;
            margin-right: auto;
            display: inline-flex;
            gap: 5px;
            font-size: 16px;
            color: #e14d2a;
            padding: 5px;
        }

        button.btn {
            min-width: 110px;
            height: 40px;
            padding: 5px 25px;
            border: none;
            border-radius: 25px !important;
            font-family: "AvertaRegular";
            font-size: 14px;
            color: #fff !important;
            box-shadow: none !important;

            &.btn-primary {
                background: #5cb648;
            }

            &.btn-secondary {
                margin-right: 5px;
                background: #ff664d;
            }
        }
    }

    .footer_cnt p {
        font-style: italic;
        font-size: 13px;
        line-height: 21px;
    }
}

canvas.sigPad,
.sig-box,
.file_drag {
    width: 100%;
    height: 100%;
    border: 2px dashed #dbdbdb;
    background: #fff;
    border-radius: 8px;
}

.sig-box,
.file_drag {
    height: 200px;
}

.sig-drag_upload {
    width: 300px;

    .drop_word {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        h3 {
            margin: 10px 0 0;
        }
    }
}

.sig-upload label {
    background: transparent;
    color: #488bff;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;
    font-family: "AvertaRegular";
    padding: 0;
    margin: 15px 0 0;
}

.success-bg {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E4FFE5;
    ;


    .success_modal {
        min-width: 410px;
        background: #fff;
        padding: 30px;
        margin: 30px 0;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            width: 120px;
            height: 120px;

            img {
                width: 100%;
            }

        }

        p {
            font-size: 15px;
            margin: 5px 0;
            color: #5e5c5c;
            font-weight: 500;
        }

        h4 {
            font-size: 24px;
            margin: 10px 0 5px;
        }

        p:has(button) {
            color: #898989;
            font-size: 12px;

            button {
                border: none;
                font-size: 14px;
                border-radius: 5px;
                padding: 5px 10px;
                background: #5fbd68;
                /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                color: #fff;
            }
        }
    }

}

@media screen and (max-width:575px) {
    .modal-dialog.signat {
        max-width: 96%;
        width: 100%;
    }

    .modal-content {
        width: 92%;
    }

    .document__SignBox .document__content {
        width: 85%;
    }

    .document__SignBox .document__signature .document__submit {
        width: 77px;
        height: 24px;
        font-size: 12px;
    }

    .document__SignBox .document__head .close-doc-start {
        width: 45px;
        height: 21px;
        font-size: 12px;
    }

    .modal-dialog.signat .modal-footer button.btn {
        min-width: 93px;
        height: 30px;
        padding: 2px 13px;
    }

}