.forgot-container {
  width: 100%;
  height: calc(100vh - 83px);
  background: url(../../../images/auths/forgot_bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  .container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    .forgot-modal {
      width: 45%;
      min-width: 345px;
      background: #fff;
      border-radius: 15px;
      padding: 20px 35px;
      overflow: hidden;
      position: relative;
      h5 {
        color: #4c6280;
        font-weight: 600;
        font-size: 21px;
        margin: 0 0 15px;
      }
      .new-form {
        .form-group {
          position: relative;
          .form-label {
            margin: 0;
            color: #000;
            font-weight: 400;
          }
          .form-control {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #e0e0e0;
            padding: 0;
            font-size: 16px;
          }
          .form-error {
            margin: 0;
            color: #eb0000;
            font-size: 13px;
            line-height: 20px;
            font-weight: 500;
            display: inline-block;
            position: absolute;
            bottom: -20px;
            left: 0;
          }
          &:has(.form-error) {
            margin-bottom: 1.5rem;
          }
          .resend,
          .toggle-pass {
            position: absolute;
            right: 0;
            top: 45px;
            border: none;
            background: transparent;
            color: #7f61b2;
            font-size: 15px;
          }
        }
        .form-info {
          color: #4c6280;
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
          margin: 10px 0 30px;
        }
        .form-submit {
          background-color: #8061b3;
          border-radius: 5px;
          border: 1px solid #8061b3;
          font-size: 16px;
          color: #fff;
          padding: 10px 50px;
          margin: 5px auto 10px;
          display: block;
          transition: all 0.2s;
          &:hover {
            background-color: #541cb8;
            border: 1px solid #541cb8;
          }
        }
        .invalid-code {
          text-align: center;
          color: #ab0000;
          font-size: 15px;
          font-weight: 500;
          padding: 3px;
          &.alert-success {
            color: #1b853f;
          }
          .close {
            position: absolute;
            right: 12px;
            top: 9px;
            opacity: 0.7;
            text-shadow: none;
          }
        }
      }
      .just-here {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
        font-size: 15px;
        color: #757575;
        margin: 10px 0;
        a {
          color: #2d333e;
          font-weight: 500;
        }
      }
    }
  }
}
.spinner-box {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background: #ffffffa6;
  z-index: 1;
  display: grid;
  place-items: center;
  .spinner {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 3px solid #8061b3;
      animation: prixClipFix 2s linear infinite;
    }
  }
}
@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.Toastify {
  width: 25%;
  top: 15%;
}
.Toastify__toast.Toastify__toast--success {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #dbffdb;
  .Toastify__close-button {
    position: relative;
    inset: 0;
  }
  .Toastify__toast-body {
    font-size: 16px;
    color: #003100;
  }
  .Toastify__progress-bar {
   display: none;
  }
}
