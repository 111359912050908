@media screen and (max-width: 1200px) {
  // Banner Section Start
  //Common Start
  .tab-lists-scroll {
    flex-wrap: wrap;
  }
  .head_content,
  .head_content img,
  .tab-lists-scroll ul.slide-tabbing.nav.nav-tabs {
    display: none;
  }
  .banner-parts,
  .right_banners {
    position: unset;
  }
  .tabbing-scroll .tab-lists-scroll li a.nav-link .imag_tittle img,
  .slide_head_content {
    display: block;
  }
  //Common End
  .nav-log-start {
    display: none !important;
  }

  .left-banner h4 {
    line-height: 46px;
    font-size: 40px;
  }

  .left-banner h6 {
    font-size: 16px;
  }
  .left-banner p {
    font-size: 13px;
  }
  .try-free {
    flex-wrap: unset;
    justify-content: space-between;
  }

  //powerful_manage_business
  .pmb .rowed {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
  .tabbing-scroll .tab-lists-scroll li.nav-item {
    border: none;
    margin: 0 8px;
  }
  .tab-lists-scroll ul.nav.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    background: #fff;
    box-shadow: 0px 0px 5px 1px #ccc;
  }
  .tab-lists-scroll .tab-content .tab-pane {
    text-align: center;
  }

  .tabbing-scroll .tab-lists-scroll li a.nav-link {
    opacity: 0.5;
    padding: 0.5rem 8px;
    height: unset !important;
  }

  .tabbing-scroll
    .tab-lists-scroll
    :where(li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6))
    a.active.nav-link {
    height: unset !important;
  }

  .imag_tittle {
    gap: 10px;
  }

  //
  section.supercharges-mains {
    padding: 25px 0 80px;
  }
  .right-mannel h4 {
    width: 100%;
  }
  .clients-cates h3 {
    font-size: 28px;
  }
  .adopting_agency_portal .rowed .aap_user {
    padding: 21px;
    border-radius: 150px 0 0 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 15px;
    position: unset;
  }
  .footer-widths h5 {
    font-weight: 600;
  }
}

@media screen and (max-width: 1024px) {
  .left-banner h4 {
    font-size: 36px;
  }
  .adopting_agency_portal .rowed {
    display: block;
  }
  .adopting_agency_portal .rowed .aap_user {
    border-radius: unset;
  }
  .adopting_agency_portal .rowed .content {
    text-align: center;
    margin-bottom: 28px;
  }
  .adopting_agency_portal h5 {
    width: unset;
  }
}
@media screen and (max-width: 990px) {
  .banner-parts .left-banner h4 br {
    display: none;
  }
  .banner-parts .left-banner {
    text-align: center;
  }
  .tabbing-scroll .tab-lists-scroll li a.active.nav-link {
    height: auto !important;
  }
  .nav-log {
    display: block;
  }
  .banner-parts {
    display: block;
  }
  .try-free {
    justify-content: space-evenly;
  }

  .pmb .rowed .powersec {
    width: 300px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }
  .five_card .card {
    width: 48%;
  }
  .five_card .card p {
    font-size: 13px;
    line-height: 27px;
}
  //  Features page Start
  .recruitment-free,
  .manage-invoice,
  .automated {
    flex-direction: column-reverse;
  }
  .feature_head {
    justify-content: unset;
  }

  //  Features page End
}
@media screen and (max-width: 840px) {
  .banner-parts {
    padding: 0;
  }

  // Feature Start
  .effortless .cont-featurs h6 br,
  .worry-free .cont-featurs h6 br,
  .manage-employee .cont-featurs h6 br {
    display: none;
  }
  // Feature End
}
@media screen and (max-width: 768px) {
  .five_card .card {
    width: 75%;
  }
  .left-subs img {
    width: 410px;
  }
  .right-subs {
    width: auto;
  }
  .free-offers .sigfr-width p {
    text-align: left;
  }

  // Feature Start
  .tittle_head .feature_head a {
    padding: 0;
  }
  .sigfr-width {
    border-radius: 5px;
    height: auto;
    flex-wrap: wrap;
  }
  // Feature End
}
@media screen and (max-width: 678px) {
  .app-comms .slick-slider {
    width: 100%;
    height: auto !important;
  }

  .container {
    max-width: 100% !important;
  }
  .tabbing-scroll .shift-arrag img {
    height: auto !important;
  }
  .right-mannel p {
    width: 100%;
  }
  .exclusive-apps .app-details h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 540px) {
  body .container {
    padding: 0 20px !important;
  }
  section.client-lists {
    padding: 0 0;
    margin: 0 0;
  }
  .client-lists {
    padding: 0 !important;
  }
  .adopting_agency_portal .get_chat button {
    width: auto;
  }
  //  Feature Start
  .feature_head_tittle h4 {
    font-size: 20px;
    line-height: 30px;
  }
  //  Feature End
}
@media screen and (max-width: 575px) {
  .five_card .card {
    width: 100%;
  }
}
// *************************************************************************************************************************************************************************
@media only screen and (max-width: 2000px) and (min-width: 1600px) {
  // section.home-banner {
  //   padding-bottom: 200px;
  // }
  .banner-parts .left-banner {
    width: 100%;
  }
  .banner-parts .right-banner {
    margin-top: 70px;
  }

  .supercharge-growth .charges-heading {
    margin: 230px 0 0;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1400px) {
  .header-parts:before,
  .manage-steps .cl-width.rags .mang-cate:after,
  .header-parts:after,
  .manage-steps .cl-width:last-child .mang-cate:after,
  .charges-heading:before,
  .charges-heading:after {
    border: 1px dashed #8e70c499;
  }

  // section.home-banner {
  //   padding-bottom: 150px;
  // }
}
@media only screen and (max-width: 1200px) and (min-width: 1600px) {
  .tabbing-scroll .container {
    margin-left: auto;
    margin-right: auto;
    transition: padding 0.3s;
    width: 98%;
    padding: 0 0 0 100px;
  }
}
@media only screen and (max-width: 1200px) {
  .left-card-infos ul li p {
    font-size: 15px;
  }
  .resources-sections .res-ranges {
    width: 100%;
  }
  .mp-center p {
    margin: 0;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1024px) {
  .map-lists .map-infos {
    width: 40%;
  }
  .modal-content .list-ment {
    width: 70%;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .shift-cases ul.nav.nav-tabs li {
    width: auto;
  }
  .opns-ranges .right-moved {
    padding: 50px 15px 0;
  }
  .opns-ranges .slick-dots {
    left: 73%;
    top: 52%;
  }
  .cont-feats .othfea-lists {
    width: 33.33%;
  }
  section.tabbing-scroll {
    background-position: 53%;
  }

  .features-strus .col-md-4 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .features-strus .col-md-8 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    width: 100%;
  }
  .exclusive-apps .slick-slide img {
    width: 180px;
    height: 388px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    border-radius: 25px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center:before {
    width: 250px;
    height: 383px !important;
    top: 7px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    transform: scale(0.97);
  }

  .exclusive-apps .slick-next {
    top: 106%;
    left: 62%;
  }
  .exclusive-apps .slick-prev {
    top: 106%;
  }
  .exclusive-apps .slick-slide img {
    width: 180px;
    height: 388px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    border-radius: 25px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    transform: scale(0.97);
  }
  .exclusive-apps .slick-slide.slick-active.slick-center:before {
    width: 250px;
    height: 383px;
    top: 7px;
  }
  .exclusive-apps .slick-next {
    top: 106%;
    left: 62%;
  }
  .exclusive-apps .slick-prev {
    top: 106%;
  }
  .sigfr-width .right-subs {
    padding: 50px 20px;
  }

  .abs-manmel .right-mannel p {
    font-size: 15px;
    line-height: 24px;
  }
  .header-sections ul li.ags-signin {
    margin-left: 0;
  }
  .header-sections ul li a {
    padding: 0px 10px;
    font-size: 14px;
  }
  .header-sections ul li.ags-signin a {
    padding: 6px 10px;
  }

  .tab-lists-scroll .tab-content {
    width: 100%;
  }

  .modal-dialog.cart-infoss {
    max-width: 90%;
  }
  .modal .modal-dialog.price-mains {
    max-width: 70%;
    margin: 20px auto;
  }
  .board-pricing .middle-price {
    width: 70%;
  }
  .pricing-sections .shifting-pricing .choose-prc {
    padding: 0px 10px;
    margin-bottom: 20px;
    width: 25%;
  }
  section.abs-contact {
    max-width: 50%;
  }
  .manage-agency .manage-steps h4:before {
    left: 18%;
  }
  .manage-agency .header-parts:before {
    width: 44px;
    left: 104px;
  }
  .manage-agency .header-parts:after {
    width: 44px;
    right: 104px;
  }
  .manage-agency .manage-steps h4:after {
    right: 18%;
  }
  .clients-join span.right-joins {
    font-size: 24px;
  }
  .clients-join span.left-joins {
    font-size: 60px;
  }
  .supercharge-growth .charges-heading h4:before {
    left: 28%;
  }
  .supercharge-growth .charges-heading p {
    width: 60%;
  }
  .supercharge-growth .charges-heading h4:after {
    right: 28%;
  }
  .supercharge-growth .charges-heading:after {
    right: 63px;
  }
  .supercharge-growth .charges-heading:before {
    left: 63px;
  }
  .supercharge-growth .charges-heading p:after {
    right: 62px;
  }
  .supercharge-growth .charges-heading p:before {
    left: 62px;
  }
  .switch-tabs .tabbings-lists p {
    width: 80%;
  }
  .switch-tabs .tabs-accrs ul.nav.nav-tabs li.nav-item {
    padding: 0 7px;
    margin-bottom: 14px;
    width: 20%;
  }
  .tab-content .tabbings-lists {
    padding: 0;
  }
  .sigfr-width .right-subs {
    width: 67%;
  }
  .sub-free .free-offers {
    width: 70%;
  }

  .pricing-sections .pricing-heading h4:after {
    right: 24%;
  }
  .pricing-sections .pricing-heading h4:before {
    left: 24%;
  }
  .pricing-sections .pricing-heading:after {
    right: 102px;
  }
  .pricing-sections .pricing-heading:before {
    left: 102px;
  }
  .pricing-sections .pricing-heading p:before {
    left: 100px;
  }
  .pricing-sections .pricing-heading p:after {
    right: 100px;
  }
}
@media only screen and (max-width: 1024px) {
  .feat-tabs img {
    max-height: 60%;
  }
  .footer-copyright p {
    font-size: 15px;
  }

  .sub-free .free-offers {
    width: 60%;
  }
}

@media only screen and (max-width: 990px) {
  li.me-cls {
    padding: 15px 20px;
    display: inline-block !important;
  }
  li.me-cls img#closeviews {
    width: 15px;
    cursor: pointer;
  }
  .app-informations .slick-pev,
  .app-informations .slick-next {
    display: block !important;
  }
  .header-sections ul.inner-menu.menu-gains {
    position: fixed;
    top: 0;
    width: 320px;
    background-color: #fff;
    height: 100%;
    overflow: auto;
    padding: 0;
    right: -100%;
    transition: all 0.5s ease;
    z-index: 99;
    display: inline-block;
  }
  .header-sections ul.mobile-logins {
    display: inline-block;
  }
  .header-sections ul li.men-clicks span {
    width: 30px;
    height: 30px;
    padding: 0;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    background-color: #9575cd;
    color: #ffff;
    cursor: pointer;
  }
  .header-sections ul li.men-clicks span img {
    position: relative;
    top: -2px;
  }
  ul.inner-menu.menu-gains.showing-menus {
    margin: 0;
  }
  .footer-section .footer-copyright p {
    padding: 18px 15px;
  }
  .home-header .header-sections ul li {
    width: 100%;
  }
  .home-header .header-sections ul li a {
    width: 100%;
    display: inline-block;
    padding: 22px 10px;
    text-align: center;
    border-bottom: 1px solid #ededed !important;
    border: 0;
  }
  .header-sections ul li.ags-signin {
    margin-left: 0;
  }
  .header-sections ul li.active a {
    background-color: #fff;
    color: #8061b3;
  }
  .header-sections ul li a:hover {
    background-color: #fff;
    color: #8061b3;
  }
  .header-sections ul li.ags-signin a {
    border: 0.5px solid #8061b3 !important;
    border-radius: 2px;
    color: #8061b3;
    padding: 6px 9px;
    width: 50%;
  }
  .header-sections ul li.ags-signin {
    text-align: center;
    margin-top: 30px;
  }
  .header-parts:before,
  .header-parts:after,
  .manage-steps h4:before,
  .manage-steps .cl-width.rags .mang-cate:after,
  .manage-steps h4:after,
  .mang-cate:before,
  .manage-steps .cl-width:last-child .mang-cate:after,
  .charges-heading:before,
  .charges-heading:after,
  .charges-heading p:before,
  .charges-heading p:after,
  .charges-heading h4:after,
  .charges-heading h4:before,
  .pricing-heading h4:after,
  .pricing-heading:before,
  .pricing-heading p:before,
  .pricing-heading p:after,
  .pricing-heading h4:before,
  .pricing-heading:after {
    display: none;
  }
  .pricing-sections .pricing-heading p {
    width: 80%;
  }
  .sub-free .free-offers {
    width: 100%;
  }
  .searching-resources .search-places {
    width: 80%;
  }

  .signup-covers {
    display: none;
  }
  .common-flex .col-md-6 {
    -webkit-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
  section.abs-contact {
    display: none;
  }
  /**Feature dropdown**/
    .home-header .header-sections ul li .dropdown {
      width: 100%;
      display: flex;
      justify-content: center;
  }

  .header-sections ul li .dropdown .dropdown-toggle a {
      height: auto;
      justify-content: center;
  }

  .header-sections ul li .dropdown .dropdown-toggle {
      width: 100%;
  }

  .header-sections ul li .dropdown .dropdown-toggle::after {
      position: absolute;
      right: 100px;
      top: 25px;
  }
  .header-sections ul li .dropdown.show .dropdown-menu {
    padding: 10px 0px !important;
    left: 50% !important;
    top: 55px !important;
    transform: translateX(-50%) !important;
}

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .random-subs .setup-lits .spacealign:nth-child(even) {
    padding-left: 15px;
  }
  .app-informations .col-md-4 {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .tabs-accrs ul.nav.nav-tabs {
    padding: 10px 0 10px;
  }
  .app-informations .col-md-8 {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .contact-sections .col-md-7 {
    flex: 0 0 80%;
    max-width: 80%;
  }

  .mapping-contact .map-infos {
    width: 50%;
    padding: 10px 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .modal-dialog.agency-singups {
    max-width: 90%;
    width: 90%;
  }
  .right-sign ul.nav.nav-tabs li a {
    padding: 0px 20px !important;
  }
  .breakup-price .left-brns {
    width: 60%;
  }
  .breakup-price .right-brns {
    width: 40%;
    padding: 5px 15px 0;
  }
  .right-card-infos .list-ment ul {
    padding: 20px 15px;
  }
  .right-card-infos .prices-cont {
    width: 100%;
  }
  .shift-cases ul.nav.nav-tabs li {
    width: 33.33%;
    margin-bottom: 20px;
  }
  .cont-feats .othfea-lists {
    width: 50%;
  }
  .opns-ranges .right-moved {
    padding: 50px 15px 0;
  }
  .shift-cases .opns-ranges .slick-dots {
    left: 0;
    top: unset;
  }
  section.tabbing-scroll {
    background-position: 50%;
  }

  .cnt-infos .abs-social {
    left: 20px;
  }
  .infoclr-contact .cnt-infos {
    padding: 25px 12px;
  }
  .infoclr-contact .cnt-infos ul li {
    font-size: 15px;
  }
  .contact-sections .cnt-infos ul li span {
    width: 20px;
  }
  .img-features img {
    margin: 0px auto;
  }
  .features-strus .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .features-strus .col-md-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    width: 100%;
  }
  .exclusive-apps .slick-slide img {
    width: 180px;
    height: 388px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    border-radius: 25px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center:before {
    width: 250px;
    height: 383px !important;
    top: 7px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    transform: scale(0.97);
  }

  .exclusive-apps .slick-next {
    top: 106%;
    left: 62%;
  }
  .exclusive-apps .slick-prev {
    top: 106%;
  }
  .exclusive-apps .slick-slide img {
    width: 180px;
    height: 388px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    border-radius: 25px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    transform: scale(0.97);
  }
  .exclusive-apps .slick-slide.slick-active.slick-center:before {
    width: 250px;
    height: 383px;
    top: 7px;
  }
  .exclusive-apps .slick-next {
    top: 106%;
    left: 62%;
  }
  .exclusive-apps .slick-prev {
    top: 106%;
  }
  .topper-ranges .left-sches h5 {
    font-size: 18px;
  }
  .banner-parts .left-banner img {
    max-width: 80%;
  }
  section.home-banner {
    padding-bottom: 50px;
  }
  .random-subs .setup-lits {
    padding: 30px 20px 30px 20px;
  }
  .random-subs .setup-lits h4 {
    font-size: 20px;
    margin-bottom: 48px;
  }
  .random-subs .setup-lits h4:before {
    top: 78px;
  }
  .random-subs .spacealign {
    margin: 0 0 35px;
  }
  .random-subs .spacealign h5 {
    font-size: 15px;
  }
  .tab-lists-scroll ul.nav.nav-tabs {
    width: 100%;
  }
  .tab-lists-scroll ul.slide-tabbing.nav.nav-tabs {
    position: static;
    width: 50%;
    margin: 20px auto;
  }
  .tab-lists-scroll ul.slide-tabbing li.nav-item a.active .crls-sec span {
    top: -21px;
  }
  .tab-lists-scroll .tab-content {
    width: 100%;
  }
  .tab-lists-scroll .absslic-docu {
    padding: 20px 60px 0;
  }
  .container {
    width: 96%;
  }

  .growing-charges .col-md-6 {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .growth-channel {
    justify-content: center;
  }

  .modal .modal-dialog.cart-infoss {
    max-width: 95%;
  }
  .right-card-infos .list-ment {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .modal .modal-dialog.price-mains {
    max-width: 70%;
    margin: 20px auto;
  }
  .feature-lists ul li {
    width: 50%;
  }
  .board-pricing .middle-price {
    width: 90%;
  }
  .pricing-sections .shifting-pricing .choose-prc {
    width: 33.33%;
    padding: 0px 15px;
    margin-bottom: 20px;
  }
  .app-informations .app-details h3 {
    font-size: 34px;
  }
  .app-informations .app-details h5 {
    font-size: 25px;
  }
  .clients-mains .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .clients-mains .col-md-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .clients-mains span.left-joins {
    font-size: 60px;
  }
  .supercharge-growth .charges-heading p {
    width: 90%;
  }
  .clients-join span.right-joins {
    font-size: 24px;
  }
  .banner-parts .right-banner {
    margin-left: auto;
    margin-top: 50px;
    width: 98%;
  }
  .banner-parts .left-banner {
    margin: 50px 0;
  }
  .banner-parts .left-banner h6 {
    font-size: 18px;
  }

  .banner-parts .col-md-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .banner-parts .col-md-7 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .switch-tabs .tabbings-lists p {
    width: 90%;
  }
  .manage-steps .cl-width {
    width: 50%;
    margin-bottom: 30px;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .right-mannel h4:before {
    top: 55px;
  }
  .app-informations .col-md-8 {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .switch-tabs .tabs-accrs ul.nav.nav-tabs li.nav-item {
    width: 33.33%;
    padding: 0 7px;
    margin-bottom: 14px;
  }
  .switch-tabs .tabs-accrs ul.nav.nav-tabs li.nav-item a {
    padding: 15px 10px !important;
  }
  .tab-pane .tabbings-lists {
    padding: 0;
  }
  .tabs-accrs ul.nav.nav-tabs {
    padding: 10px 0 10px;
  }

  section.sub-free .free-offers {
    width: 80%;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center:before {
    height: 293px;
  }
}
@media only screen and (max-width: 767px) {
  .header-sections a img.ags-logo {
    max-width: 150px !important;
}
  .home-header .header-sections ul li a {
    padding: 14px 10px;
  }
  .sign-process.prec-process {
    margin-top: 50px;
  }
  button.progress-next {
    padding: 10px 30px;
  }
  .right-sign ul.nav.nav-tabs li a {
    font-size: 15px;
  }
  .forget-step1 .login-progress {
    margin: 40px 0 0;
  }
  .modelsign-stru .left-sign img {
    width: 90%;
  }
  .signup-groups .group-control input.form-control {
    font-size: 13px;
  }
  span.dmn-sign.abs-moved {
    font-size: 13px;
  }
  span.dmn-sign.abs-moved {
    left: 103px;
  }
  .modelsign-stru .left-sign img.sign-logo {
    width: 184px !important;
  }
  .modal-dialog.agency-singups {
    max-width: 95%;
    width: 95%;
  }
  .modelsign-stru .left-sign a {
    width: 100%;
    display: block;
  }

  .modal-dialog.agency-singups .right-sign {
    width: 100%;
  }
  .agency-singups .modal-content {
    border-radius: 10px;
    overflow: auto;
  }
  .modal-dialog.agency-singups .modal-body {
    padding: 20px;
  }
  .modelsign-stru .left-sign {
    width: 100%;
    text-align: center;
  }
  .count-valus button.btn.count-inc {
    right: 35%;
    left: unset;
  }
  .count-valus button.btn.count-dec {
    right: 35%;
    left: unset;
  }
  .right-prc .empl-left {
    width: 100%;
    padding-right: 0;
  }
  .right-prc h6 {
    text-align: center;
    font-size: 20px;
  }
  .breakup-price .left-brns {
    width: 100%;
  }
  .breakup-price .left-brns h6 {
    text-align: center;
  }
  .cart-varity .right-card-infos {
    margin-top: 30px;
  }
  .right-card-infos .list-ment {
    width: 100%;
    margin-left: unset;
    padding: 0 50px;
  }
  .right-card-infos .prices-cont {
    width: 100%;
    margin-left: unset;
    padding: 0 50px;
  }
  .breakup-price .right-brns {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    padding: 5px 0;
  }
  .right-prc p {
    text-align: center;
  }
  .middle-price .empl-right {
    width: 100%;
    margin-top: 5px;
    margin: 15px auto 0;
    text-align: center;
  }
  .opns-ranges .right-moved {
    padding: 100px 15px 0;
  }
  .shift-cases ul.nav.nav-tabs li {
    width: 50%;
    margin-bottom: 20px;
  }
  .shift-cases .opns-ranges .slick-dots {
    left: 0;
    top: unset;
  }
  .shift-cases {
    padding: 30px 0 0;
  }
  .feats-gals {
    margin-top: 20px;
  }
  .features-heading h3 {
    margin-bottom: 24px;
  }
  .cont-feats .othfea-lists {
    width: 50%;
  }
  .cont-feats h3 {
    font-size: 24px;
  }
  .counter-names {
    min-height: 190px;
  }
  .vals-policy h4 {
    font-size: 24px;
  }
  .vals-policy h6 {
    font-size: 18px;
  }

  .cnt-infos h4 {
    font-size: 20px;
  }
  .cnt-infos .abs-social {
    left: 20px;
  }
  .infoclr-contact .cnt-infos ul li {
    font-size: 15px;
  }

  .tab-lists-features h5 {
    font-size: 18px;
  }
  .features-strus .cont-featurs {
    padding-left: 20px;
  }
  .cont-featurs h6 {
    font-size: 18px;
  }
  .features-strus.manage-invoice .const-height img {
    max-width: 60%;
  }
  .const-height img {
    max-width: 70%;
    position: static;
    left: 0;
  }
  .const-height {
    height: auto;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .tab-lists-features h5 {
    font-size: 16px;
  }
  .features-strus.manage-employee .const-height img {
    max-width: 40%;
  }
  .features-strus.worry-free .const-height img {
    max-width: 60%;
  }
  .features-strus.recruitment-free .const-height img {
    max-width: 65%;
  }
  .supercharge-growth .topper-ranges .left-sches p {
    margin-bottom: 20px;
  }
  .switch-tabs .tabbings-lists p {
    margin-bottom: 20px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    width: 100%;
  }
  .exclusive-apps .slick-slide img {
    width: 180px;
    height: 388px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    border-radius: 25px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    transform: scale(0.97);
  }
  .exclusive-apps .slick-slide.slick-active.slick-center:before {
    width: 250px;
    height: 383px;
    top: 7px;
  }
  .exclusive-apps .slick-next {
    top: 106%;
    left: 62%;
  }
  .exclusive-apps .slick-prev {
    top: 106%;
  }
  .tabs-accrs .tabbings-lists {
    padding: 0;
  }
  .tabs-accrs .tab-content {
    padding: 20px 0 0;
  }
  section.tabbing-scroll {
    padding: 30px 0;
  }
  .tab-lists-scroll ul.slide-tabbing.nav.nav-tabs {
    position: static;
    width: 60%;
    margin: 20px auto;
  }
  .footer-lists .footer-logwid ul li {
    width: 100%;
    text-align: center;
  }
  .footer-section .footer-widths h5 {
    font-size: 15px;
    text-align: center;
    width: 100%;
  }
  .footer-lists .footer-widths.midls-footers {
    width: 100%;
  }
  .footer-section .footer-copyright p {
    padding: 18px 5px;
  }
  .footer-lists .footer-logwid ul li span {
    width: auto;
  }

  .footer-lists .footer-logwid ul li span.fts-left {
    width: auto;
    margin-right: 10px;
  }
  .banner-parts .left-banner img {
    max-width: 80%;
  }
  .banner-parts .right-banner {
    width: 100%;
  }
  section.home-banner {
    padding-bottom: 40px;
  }
  .random-subs .powerleft {
    width: 100%;
    margin: 0 auto 40px;
    text-align: center;
    justify-content: center;
  }
  .random-subs .powerleft img {
    max-width: 80%;
  }
  .random-subs .powerright h3 {
    font-size: 24px;
  }
  .powerright .setup-lits h4 {
    width: 100%;
    margin-bottom: 50px;
    font-size: 20px;
  }
  .growing-charges .growth-channel {
    justify-content: center;
  }
  .growing-charges .lists-clients {
    padding: 40px 0 0;
  }

  .supercharge-growth .topper-ranges .left-sches h5 {
    font-size: 18px;
  }
  .supercharge-growth .topper-ranges .left-sches p {
    font-size: 15px;
  }
  .topper-ranges .started-lists p span {
    width: auto;
  }

  .powerright .setup-lits {
    padding: 30px 30px 30px 30px;
  }
  .powerright .setup-lits .spacealign:nth-child(even) {
    padding-left: 60px;
  }
  .powerright .spacealign h5 {
    font-size: 15px;
  }
  .cookies-table-section table.table {
    width: 800px;
  }
  .scrl-funs {
    overflow: auto;
  }
  .footer-lists .footer-widths.last-footers {
    width: 100%;
  }
  .list-ment button.btn.pay-nwss {
    font-size: 13px;
  }
  .cart-varity .left-card-infos {
    width: 100%;
  }
  .cart-infoss .modal-body {
    max-height: 500px;
    overflow: auto;
  }
  .modal-body .list-ment ul li h5 {
    font-size: 22px;
  }
  .cart-varity .right-card-infos {
    width: 100%;
  }
  .modal-dialog.cart-infoss {
    max-width: 96%;
  }
  .tab-lists-scroll ul.nav.nav-tabs {
    width: 100%;
  }
  .multi-tabs .right-dets h4 {
    margin: 0;
    font-size: 15px;
  }
  .tabbing-scroll .tab-lists-scroll .tab-content {
    width: 100%;
  }
  .tabbings-lists {
    padding: 0;
  }
  section.tabbing-scroll {
    background-position: 50%;
  }
  .tab-lists-scroll .absslic-docu {
    padding: 20px 60px 0;
  }
  section.switch-tabs.only-for-mbl {
    display: block;
  }
  .only-for-mbl .tabbings-lists.tab-margin img {
    margin-top: 20px;
  }
  .only-for-mbl .tabs-shss p {
    line-height: 22px;
    font-size: 13px;
  }
  .modal .modal-dialog.price-mains {
    max-width: 98%;
    margin: 20px auto;
  }
  .feature-maintains .feature-lists ul li {
    width: 50%;
  }

  .mapping-contact .map-infos {
    height: auto;
    width: 100%;
    padding: 30px 28px;
    position: relative;
    left: 0;
  }
  .mapping-contact .mp-center p {
    margin: 0;
  }

  .home-banner .explore-arrow {
    position: static;
    bottom: 0;
    left: 0;
    text-align: center;
    margin-bottom: 50px;
  }
  .home-banner .explore-arrow h6 {
    border: 1px solid #ffffff;
  }
  .home-banner .explore-arrow h6 span:nth-child(2) {
    background-color: #d5d5d59e;
  }
  .home-banner .explore-arrow h6 span:nth-child(3) {
    background-color: #ababac57;
  }
  .home-banner .explore-arrow h6:hover {
    background-color: #755ca0;
  }
  .home-banner .explore-arrow h6 span {
    background-color: #ffffff;
  }
  .header-sections .menu-lists ul li.sign-desk {
    display: block;
  }
  .home-banner .explore-arrow p {
    margin: 20px 0 0;
    color: #ffffff;
    font-size: 14px;
  }
  .supercharge-growth .topper-ranges {
    margin: 40px 0 40px;
  }
  .header-sections .menu-lists ul li {
    padding: 15px 115px 15px 0;
  }

  .header-sections ul.menu-gains li.men-clicks {
    display: inline-block;
  }
  .choose-prc .price-abs h2 {
    font-size: 22px;
  }
  .pricing-sections .shifting-pricing .choose-prc {
    width: 50%;
    padding: 0px 8px;
    margin-bottom: 20px;
  }
  .pricing-sections .frontend-choice h5 {
    font-size: 13px;
  }
  .frontend-choice button.btn {
    padding: 0 10px;
  }
  .pricing-views .shifting-pricing {
    padding: 15px 0;
  }
  .supercharge-growth .charges-heading p {
    width: 100%;
  }
  .middle-price .right-prc p {
    width: 100%;
  }

  .middle-price .right-prc {
    padding: 0px 15px;
  }
  .supercharge-growth .charges-heading h4 {
    font-size: 22px;
  }

  .switch-tabs .tabbings-lists p {
    width: 100%;
  }
  .board-pricing .middle-price {
    width: 90%;
  }
  .middle-price .right-prc p {
    margin-bottom: 10px;
  }
  .pricing-sections .pricing-heading h4 {
    font-size: 22px;
  }
  .pricing-sections .pricing-heading p {
    font-size: 15px;
    width: 100%;
  }
  section.tabbing-features {
    padding: 30px 0;
  }
  .tab-content .feat-tabs p {
    width: 80%;
  }
  .tabbing-features .tab-lists-features h4 {
    font-size: 20px;
    width: 100%;
  }
  .tab-content .abs-features {
    top: 48%;
  }
  .feat-tabs .abs-features h3 {
    font-size: 18px;
    letter-spacing: 7px;
  }
  .tab-content .feat-tabs h5 {
    width: 100%;
    font-size: 15px;
  }
  .mang-cate .bottom-cate p {
    line-height: 22px;
    margin: 0;
  }
  .banner-parts .left-banner h6 {
    font-size: 20px;
  }
  .banner-parts .left-banner h4 {
    font-size: 30px;
  }
  .banner-parts .left-banner {
    margin: 15px 0;
  }
  .manage-steps h4 {
    margin-top: 30px;
    font-size: 20px;
  }
  .manage-steps .cl-width {
    width: 50%;
    margin-bottom: 30px;
  }
  .app-informations .app-details h3 {
    font-size: 28px;
  }
  .app-informations .app-details h5 {
    font-size: 20px;
  }
  .footer-lists .footer-logwid a img {
    max-width: 220px;
  }
  .vals-policy img {
    margin-bottom: 25px;
  }
  .searching-resources .search-places {
    width: 100%;
  }
  .contact-sections .common-flex .col-md-6 {
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer-lists .footer-logwid {
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }
  .footer-lists .footer-widths {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
  /**Feature dropdown**/
  .header-sections ul li .dropdown.show .dropdown-menu .dropdown-item a {
    padding: 10px;
}

  .home-header .header-sections ul li .dropdown {
    width: 100%;
    display: flex;
    justify-content: center;
}

.header-sections ul li .dropdown .dropdown-toggle a {
    height: auto;
    justify-content: center;
}

.header-sections ul li .dropdown .dropdown-toggle {
    width: 100%;
}

.header-sections ul li .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 100px;
    top: 25px;
}
.header-sections ul li .dropdown.show .dropdown-menu {
  padding: 10px 0px !important;
  left: 50% !important;
  top: 40px !important;
  transform: translateX(-50%) !important;
}
}

@media only screen and (max-width: 767px) and (min-width: 568px) {
  .agency-singups .modal-content {
    max-height: 300px;
  }
  .modelsign-stru .left-sign {
    width: 40%;
    text-align: left;
  }
  .modal-dialog.agency-singups .right-sign {
    width: 60%;
  }
  .tooltip-spaces .tooltiptext-spaces {
    width: 500px;
    height: auto;
  }
  .clients-mains .col-md-8 {
    width: 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .clients-mains span.left-joins {
    font-size: 45px;
  }
  .clients-mains span.right-joins {
    font-size: 18px;
    line-height: 31px;
  }
  .clients-mains .col-md-4 {
    width: 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .sigfr-width .trails-sec a {
    padding: 10px 32px;
  }
  .switch-tabs .tabs-accrs ul.nav.nav-tabs li.nav-item {
    width: 33.33%;
    padding: 0 7px;
    margin-bottom: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    transform: scale(0.97);
  }
}
@media only screen and (max-width: 767px) and (min-width: 600px) {
  .pricing-sections .shifting-pricing .choose-prc {
    width: 50%;
    padding: 0px 8px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 850px) {
  .exclusive-apps .slick-slide.slick-active.slick-center:before {
    height: 320px !important;
  }
  .exclusive-apps .slick-slide img {
    height: 322px;
    width: 160px;
  }
  .powerright h3 {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 600px) {
  .pricing-sections .shifting-pricing .choose-prc {
    width: 50%;
    padding: 0px 8px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 567px) {
  .sign-process.already-process {
    text-align: center;
    justify-content: center;
  }
  .sign-process.already-process span {
    text-align: center;
    width: 100%;
  }
  .sign-process.already-process button.progress-next {
    margin-left: unset;
  }
  .modelsign-stru .left-sign img {
    width: 60%;
  }
  .agency-singups .modal-content {
    max-height: 500px;
  }
  .forgotval-stru .parll-left {
    width: 100%;
    text-align: center;
  }
  .forgotval-stru .parll-left a {
    width: 100%;
    display: block;
  }
  .forgotval-stru .parll-right {
    width: 100%;
  }
  .forgotval-stru .parll-left img {
    max-width: 60%;
    margin-bottom: 10px;
  }
  .login-progress span {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .login-progress button.progress-next {
    margin-left: unset;
  }
  .login-progress {
    justify-content: center;
  }
  .count-valus button.btn.count-inc {
    right: 32%;
  }
  .count-valus button.btn.count-dec {
    right: 32%;
  }
  .tooltip-spaces .tooltiptext-spaces {
    width: 320px;
    height: auto;
    margin-left: -156px;
    padding: 20px;
  }
  .right-brns button.buybtns-ops {
    font-size: 14px;
  }
  .prices-cont button.btn.btn-const {
    font-size: 14px;
  }
  .left-brns span.dtep-tot2 {
    font-size: 22px;
  }
  .left-brns span.dtep-tot3 {
    font-size: 14px;
  }
  .left-brns h6 span.dtep-totl {
    font-size: 14px;
  }
  .left-card-infos ul li p {
    font-size: 15px;
  }
  .right-card-infos .list-ment ul {
    padding: 20px 15px;
  }
  .right-card-infos .list-ment {
    padding: 0;
  }
  .right-card-infos .prices-cont {
    padding: 0;
  }
  .shift-cases ul.nav.nav-tabs li a {
    font-size: 15px;
  }
  .cont-feats .othfea-lists {
    width: 100%;
  }
  .powerright .setup-lits {
    margin: 90px 0 0;
  }
  .cnt-infos ul li span {
    width: 20px;
  }
  .infoclr-contact .cnt-infos {
    padding: 25px 15px;
  }

  section.tabbing-scroll {
    background-position: 50%;
  }
  .multi-tabs .right-dets {
    width: 100%;
  }
  .tab-lists-scroll ul.slide-tabbing.nav.nav-tabs {
    width: 80% !important;
  }
  .tab-lists-scroll .absslic-docu {
    padding: 20px 10px 0;
  }

  .growth-channel .right-mannel h4 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .growth-channel .right-mannel p {
    font-size: 14px;
    line-height: 24px;
  }
  .growth-channel .right-mannel h4:before {
    top: 48px;
  }
  .random-subs .powerleft img {
    max-width: 100%;
  }
  .powerright .setup-lits {
    padding: 30px 10px 30px 10px !important;
  }
  .setup-lits .spacealign {
    margin: 0 0 20px;
    border-right: 0;
    padding: 0px 15px;
    width: 100%;
  }
  .setup-lits .spacealign h5 span {
    width: auto;
    display: inline-block;
  }
  .powerright .setup-lits .spacealign:nth-child(even) {
    padding-left: 15px;
    padding: 0px 15px;
  }
  .feature-maintains .feature-lists ul li {
    width: 100% !important;
  }
  .pricing-sections .shifting-pricing .choose-prc {
    width: 50%;
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .mapping-contact .map-infos {
    height: auto;
    width: 100%;
    padding: 30px 28px;
    position: static;
  }
  .mapping-contact .mp-center p {
    margin: 0;
  }
  .pricing-sections .middle-price .left-prc {
    width: 100%;
  }
  .pricing-sections .middle-price .right-prc {
    width: 100%;
    margin: 0 0 40px;
    padding: 0;
  }

  .client-lists span.left-joins {
    font-size: 60px;
  }
  .client-lists span.right-joins {
    font-size: 20px;
  }
  .switch-tabs .tabs-accrs ul.nav.nav-tabs li.nav-item {
    width: 50%;
    padding: 0 7px;
    margin-bottom: 14px;
  }

  .cl-width .mang-cate {
    padding: 10px;
  }
  .manage-steps .cl-width {
    padding: 0px 3px;
    margin-bottom: 6px;
  }
  .free-offers .sigfr-width p {
    width: 100%;
  }
  .sigfr-width .trails-sec {
    margin: 13px auto 0;
    text-align: left;
    width: 100%;
  }

  .contact-sections .domain-inputs input.form-control {
    width: 80%;
  }
}
@media only screen and (max-width: 500px) {
  .shift-cases ul.nav.nav-tabs li {
    width: 100%;
    margin-bottom: 15px;
  }
  .count-valus button.btn.count-inc {
    right: 27%;
  }
  .count-valus button.btn.count-dec {
    right: 27%;
  }
}
@media only screen and (max-width: 444px) {
  .banner-parts .left-banner h4 {
    font-size: 24px;
  }
  .banner-parts .left-banner h6 {
    font-size: 13px;
  }
  .pmb h6 {
    font-size: 15px;
    font-weight: 600;
    line-height: 28px;
  }
  .slide_head_content h5 {
    font-weight: 600;
  }
  section.tabbing-scroll {
    background-position: 35%;
  }

  .switch-tabs .tabs-accrs ul.nav.nav-tabs li.nav-item {
    width: 100%;
  }
  .sigfr-width .trails-sec {
    text-align: center;
  }
  .height-mids {
    min-height: auto;
  }
  .sigfr-width .right-subs {
    width: 100%;
  }
  .right-card-infos .list-ment {
    width: 100%;
  }
  .left-card-infos ul li p {
    font-size: 13px;
  }
  .pricing-sections .shifting-pricing .choose-prc {
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .tab-lists-scroll li.nav-item {
    width: 100% !important;
  }
}
@media only screen and (max-width: 414px) {
  .modal-dialog.agency-singups .modal-body {
    padding: 20px 10px;
  }

  .count-valus button.btn.count-inc {
    right: 25%;
  }
  .count-valus button.btn.count-dec {
    right: 25%;
  }
  .abs-manmel {
    left: -27px;
  }
  .cms-contact .sts-two {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .cms-contact .sts-two.as-rs {
    margin-top: 10px;
  }

  .growing-charges .clients-cates h6 {
    font-size: 12px;
  }
}
@media only screen and (max-width: 390px) {
  .tab-lists-features h4:before {
    left: 39%;
    top: 150px;
  }
  .exclusive-apps .slick-slide.slick-active.slick-center div {
    width: 119%;
    left: -8px;
  }
}
@media only screen and (max-width: 375px) {

  section.supercharges-mains {
    padding: 20px 0 0;
  }
  .tab-pane {
    padding: 0;
  }
  .adopting_agency_portal h5 {
    font-size: 20px;
    line-height: 36px;
  }
  .adopting_agency_portal .rowed .aap_user {
    display: none;
  }

  // Feature Start
  .feature_head_tittle h4 {
    font-size: 15px;
  }
  .cont-featurs h6 span {
    font-size: 14px;
  }
  .cont-featurs h6 {
    line-height: 28px;
  }
  // Feature End
}

@media only screen and (max-width: 370px) {
  section.tabbing-scroll {
    background-position: 40%;
  }
  .mang-cate .bottom-cate p {
    font-size: 12px;
  }
  .switch-tabs .tabs-shss p {
    font-size: 13px;
  }
  .tabs-accrs ul.nav.nav-tabs li.nav-item a {
    padding: 15px 7px;
  }
  .tooltip-spaces .tooltiptext-spaces {
    width: 300px;
    height: auto;
    margin-left: -149px;
  }
}
