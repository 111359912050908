// @font-face {
//     font-family: 'NunitoSans-Bold';
//     src: url('../../../fonts/NunitoSans-Bold.eot');
//     src: url('../../../fonts/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
//          url('../../../fonts/NunitoSans-Bold.svg#NunitoSans-Bold') format('svg'),
//          url('../../../fonts/NunitoSans-Bold.ttf') format('truetype'),
//          url('../../../fonts/NunitoSans-Bold.woff') format('woff'),
//          url('../../../fonts/NunitoSans-Bold.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
//   }//   @font-face {
//     font-family: 'NunitoSans-Regular';
//     src: url('../../../fonts/NunitoSans-Regular.eot');
//     src: url('../../../fonts/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
//          url('../../../fonts/NunitoSans-Regular.svg#NunitoSans-Regular') format('svg'),
//          url('../../../fonts/NunitoSans-Regular.ttf') format('truetype'),
//          url('../../../fonts/NunitoSans-Regular.woff') format('woff'),
//          url('../../../fonts/NunitoSans-Regular.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
//   }//   @font-face {
//     font-family: 'NunitoSans-SemiBold';
//     src: url('../../../fonts/NunitoSans-SemiBold.eot');
//     src: url('../../../fonts/NunitoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
//          url('../../../fonts/NunitoSans-SemiBold.svg#NunitoSans-SemiBold') format('svg'),
//          url('../../../fonts/NunitoSans-SemiBold.ttf') format('truetype'),
//          url('../../../fonts/NunitoSans-SemiBold.woff') format('woff'),
//          url('../../../fonts/NunitoSans-SemiBold.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
//   }
@font-face {
  font-family: "Eina03Regular";
  src: url("../../../fonts/Eina03Regular.eot");
  src: url("../../../fonts/Eina03Regular.eot") format("embedded-opentype"),
    url("../../../fonts/Eina03Regular.woff2") format("woff2"),
    url("../../../fonts/Eina03Regular.woff") format("woff"),
    url("../../../fonts/Eina03Regular.ttf") format("truetype"),
    url("../../../fonts/Eina03Regular.svg#Eina03Regular") format("svg");
}
@font-face {
  font-family: "Eina03Bold";
  src: url("../../../fonts/Eina03Bold.eot");
  src: url("../../../fonts/Eina03Bold.eot") format("embedded-opentype"),
    url("../../../fonts/Eina03Bold.woff2") format("woff2"),
    url("../../../fonts/Eina03Bold.woff") format("woff"),
    url("../../../fonts/Eina03Bold.ttf") format("truetype"),
    url("../../../fonts/Eina03Bold.svg#Eina03Bold") format("svg");
}
@font-face {
  font-family: "Eina03SemiBold";
  src: url("../../../fonts/Eina03SemiBold.eot");
  src: url("../../../fonts/Eina03SemiBold.eot") format("embedded-opentype"),
    url("../../../fonts/Eina03SemiBold.woff2") format("woff2"),
    url("../../../fonts/Eina03SemiBold.woff") format("woff"),
    url("../../../fonts/Eina03SemiBold.ttf") format("truetype"),
    url("../../../fonts/Eina03SemiBold.svg#Eina03SemiBold") format("svg");
}
body {
  font-family: "Eina03Regular" !important;
}
// section.home-header {
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   position: absolute;
//   z-index: 9;
// }
// .header-sections {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 15px 0;
// }
// .header-sections ul {
//   margin: 0;
//   display: inline-flex;
//   align-items: center;
//   padding: 0;
// }
// .nav-log {
//   display: none;
// }

// .nav-log-start {
//   display: block;
//   &.with_view_demo{
//     gap: 0.5rem;
//     .book_a_demo_view_btn {
//       padding: 8px 8px !important;
//       border-radius: 5px;
//       border: 0.5px solid #ffa41d;
      
//     }
//   }
// }

// .header-sections ul li a {
//   color: #000000;
//   font-size: 16px !important;
//   padding: 0px 8px;
//   cursor: pointer;
//   // border-left: 1px solid #ececec;
// }
// .header-sections ul li.men-clicks span {
//   width: 30px;
//   height: 30px;
//   padding: 0;
//   display: inline-block;
//   border-radius: 50%;
//   text-align: center;
//   background-color: #ffffff;
//   color: #9575cd;
//   cursor: pointer;
// }
// .header-sections ul li.men-clicks {
//   padding: 0px 0 0 4px;
// }
// section.home-header.front-header {
//   position: relative;
// }
.banner-parts {
  display: flex;
  // padding: 40px 0 40px;
  padding: 85px 0px 65px;
  
  justify-content: space-between;
}
.right_banners {
  // position: absolute;
  right: 0;
}
.left-banner {
  width: 98%;
  // margin: 55px 0;
  position: relative;
}

.left-banner {
  width: 100%;
}
.left-banner h6 {
  color: #000000;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.left-banner h4 {
  line-height: 68px;
  font-size: 50px;
  color: #000000;
  font-family: "Eina03Bold";
}
.left-banner p {
  color: #000000;
  font-size: 14px;
  line-height: 26px;
  padding: 10px 0 26px;
  margin: 0;
}
.try-free {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 50px;
}
.try-free .try-free-view {
  color: #000;
  border-bottom: dotted black;
}
.try-free .try-free-view:hover {
  color: #8061b3;
  border-bottom: dotted #8061b3;
}
.try-free input.form-control {
  height: 39px;
  width: 100%;
  background-color: transparent;
  border: solid 1px #8061b3;
  border-radius: 2px;
  font-size: 15px;
  text-indent: 14px;
  box-shadow: 10px 10px 20px #8061b31a;
}
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0) !important;
}
.try-free ::-webkit-input-placeholder {
  color: #9e9e9e !important;
  font-size: 14px;
}
.try-free :-ms-input-placeholder {
  color: #9e9e9e !important;
  font-size: 14px;
}
.try-free ::placeholder {
  color: #9e9e9e !important;
  font-size: 14px;
}
button.btn.tre-subs {
  position: absolute;
  right: 0;
  height: 39px;
  border-radius: 2px 2px 0 0;
  background-color: #8061b3;
  padding: 0 28px;
  color: #ffffff;
  font-size: 14px;
}

.footer-widths ul {
  padding: 0;
  margin: 0;
  width: 100%;
}
.left-banner p,
.sigfr-width p {
  font-family: "Eina03Regular";
}
.bottom-cate p,
.topper-ranges .left-sches h5,
.bottom-ranges .right-sches h5 {
  font-family: "Eina03Bold";
}

.footer-widths ul li {
  width: 100%;
}

.footer-logwid {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  .foot-logo {
    width: 200px;
    position: relative;
    left: -5px;
  }
}
.footer-widths ul li a:hover {
  color: #8a6cbd;
}
.footer-copyright {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.footer-copyright {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 0;
}
.right-banner {
  width: 100%;
}
.explore-arrow {
  position: absolute;
  bottom: 84px;
  left: 20%;
}
.explore-arrow p {
  margin: 0;
  color: #333333;
  font-size: 16px;
}
section.manage-agency {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.manage-steps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.manage-steps h4 {
  width: 100%;
  text-align: center;
  color: #333333;
  font-size: 24px;
  margin-bottom: 24px;
  position: relative;
}
.mang-cate {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: 0 0 99px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  min-height: 140px;
  padding: 15px;
}
.manage-steps h4:hover {
  color: #333333;
}
.mang-cate .top-cate {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  margin: 2px 0;
}
.mang-cate .top-cate img {
  margin-left: auto;
}
.bottom-cate {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.bottom-cate p {
  color: #333333;
  font-size: 15px;
  width: 100%;
  line-height: 0;
  margin: 0;
}
.mang-cate:before {
  content: "";
  position: absolute;
  width: 46px;
  border-top: 1.5px dashed #8e70c499;
  top: 50%;
  right: -27px;
}
.manage-steps .cl-width {
  padding: 0px 20px;
}
.manage-steps .cl-width:last-child .mang-cate:before {
  display: none;
}
.header-parts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.manage-steps h4:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 33px;
  background-color: #8c6ec1;
  border-radius: 16px;
  top: 2px;
  left: 27%;
}
.manage-steps h4:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 33px;
  background-color: #8c6ec1;
  border-radius: 16px;
  top: 2px;
  right: 27%;
}
.header-parts:after {
  content: "";
  position: absolute;
  width: 166px;
  border-top: 1.5px dashed #8e70c499;
  right: 137px;
  top: 17px;
}
.header-parts:before {
  content: "";
  position: absolute;
  width: 160px;
  border-top: 1.5px dashed #8e70c499;
  left: 137px;
  top: 17px;
}
.manage-steps .cl-width:last-child .mang-cate:after {
  content: "";
  position: absolute;
  border-left: 1.5px dashed #8e70c499;
  height: 36px;
  top: -39px;
  left: 50%;
}
.manage-steps .cl-width.rags .mang-cate:after {
  content: "";
  position: absolute;
  border-left: 1.5px dashed #8e70c499;
  height: 36px;
  top: -39px;
  left: 50%;
}
.explore-arrow h6 {
  border: 1px solid #8d70c2;
  width: 25px;
  height: 42px;
  border-radius: 55px;
  cursor: pointer;
  -webkit-animation: fearrow 5s infinite;
  animation: fearrow 5s infinite;
  position: relative;
  margin: auto;
}
@-webkit-keyframes fearrow {
  from {
    top: 0px;
  }
  to {
    top: 30px;
  }
}
@keyframes fearrow {
  from {
    top: 0px;
  }
  to {
    top: 30px;
  }
}
.explore-arrow h6 span {
  width: 7px;
  height: 7px;
  display: block;
  background-color: #8b6dc0;
  border-radius: 50%;
  text-align: right;
  margin: 4px 7px;
}
.explore-arrow h6 span:nth-child(2) {
  background-color: #8b6dc09e;
}
.explore-arrow h6 span:nth-child(3) {
  background-color: #8b6dc057;
}
.explore-arrow h6:hover {
  background-color: #e9e9e9;
}
section.supercharge-growth {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 20px 0 70px;
}
.charges-heading h4 {
  width: 100%;
  text-align: center;
  color: #333333;
  font-size: 24px;
  margin: 0px 0 18px;
}
section.switch-tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #fef9ff;
}
.tabs-accrs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
  padding: 50px 0 50px;
}
.tabs-accrs ul.nav.nav-tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  border: 0;
  padding: 10px 0 30px;
  justify-content: center;
}
.tabs-accrs ul.nav.nav-tabs li.nav-item {
  width: 20%;
  padding: 0;
}
.tabs-accrs ul.nav.nav-tabs li.nav-item a {
  width: 100%;
  border: 0;
  padding: 0.5rem 0;
}
.tabs-shss {
  width: 100%;
}
.tabs-accrs ul.nav.nav-tabs li.nav-item a img.aft-tab {
  display: none;
}
.tabs-accrs ul.nav.nav-tabs li.nav-item a.active img.bef-tab {
  display: none;
}
.tabs-accrs ul.nav.nav-tabs li.nav-item a.active img.aft-tab {
  display: inline-block;
}
.tabs-shss p {
  color: #757575;
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 22px;
  padding: 20px 15px;
}
.tabs-accrs ul.nav.nav-tabs li.nav-item a.active .tabs-shss p {
  color: #8061b3;
}
.tabs-accrs .tab-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 0;
  padding: 20px 20px 0;
  background-color: #fef9ff;
}
.tabbings-lists p {
  color: #616161;
  text-align: center;
  width: 57%;
  margin: auto;
  font-size: 15px;
  line-height: 26px;
}
.charges-heading p {
  width: 49%;
  margin: auto;
  text-align: center;
  font-size: 16px;
  color: #616161;
  line-height: 26px;
}
.charges-heading h4:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 33px;
  background-color: #8c6ec1;
  border-radius: 16px;
  top: 2px;
  left: 34%;
}
.charges-heading h4:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 33px;
  background-color: #8c6ec1;
  border-radius: 16px;
  top: 2px;
  right: 34%;
}
.charges-heading h4:hover {
  color: #333333;
}
.charges-heading:before {
  content: "";
  position: absolute;
  width: 160px;
  border-top: 1.5px dashed #8e70c499;
  left: 215px;
  top: 17px;
}
.charges-heading:after {
  content: "";
  position: absolute;
  width: 166px;
  border-top: 1.5px dashed #8e70c499;
  right: 215px;
  top: 17px;
}
.charges-heading p:before {
  content: "";
  position: absolute;
  width: 63px;
  border: 1.5px dashed #8e70c499;
  left: 215px;
  top: 17px;
  height: 61px;
  border-right: 0;
  border-top: 0;
}
.charges-heading p:after {
  content: "";
  position: absolute;
  width: 63px;
  border: 1.5px dashed #8e70c499;
  right: 215px;
  top: 17px;
  height: 61px;
  border-left: 0;
  border-top: 0;
}
.topper-ranges {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0 90px;
}
.topper-ranges .left-sches {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.topper-ranges .left-sches img {
  display: block;
  margin: 10px 0;
}
.topper-ranges .left-sches h5 {
  width: 100%;
  font-size: 20px;
  color: #333333;
  margin: 6px 0 10px;
}
.topper-ranges .left-sches p {
  font-size: 16px;
  color: #000000;
  width: 100%;
  line-height: 28px;
  margin: 0;
  padding: 0;
}
.topper-ranges .right-sches {
  width: 100%;
  margin-left: auto;
  text-align: right;
  -webkit-transition: ease 1s all;
  transition: ease 1s all;
}
.topper-ranges .right-sches:hover img {
  -webkit-transition: ease 1s all;
  transition: ease 1s all;
  /* opacity: 0; */
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}
.bottom-ranges .left-sches {
  -webkit-transition: ease 1s all;
  transition: ease 1s all;
}
.bottom-ranges .left-sches:hover img {
  -webkit-transition: ease 1s all;
  transition: ease 1s all;
  /* opacity: 0; */
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}
.topper-ranges .right-sches img {
  width: 100%;
}
.bottom-ranges {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}
.bottom-ranges .right-sches {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.bottom-ranges .right-sches img {
  display: block;
  margin: 10px 0;
}
.bottom-ranges .right-sches h5 {
  width: 100%;
  font-size: 18px;
  color: #333333;
  margin: 6px 0 10px;
  text-align: right;
}
.bottom-ranges .right-sches p {
  font-size: 15px;
  color: #616161;
  width: 100%;
  line-height: 28px;
  margin: 0;
  padding: 0;
  text-align: right;
}
.bottom-ranges .left-sches {
  width: 100%;
  text-align: left;
}
.bottom-ranges .left-sches img {
  width: 100%;
}
section.exclusive-slider {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // background-color: #f5f5f5;
  padding: 60px 0 15px;
}
.exclusive-slider + .sub-free {
  padding-top: 58px;
}

.app-comms {
  width: 100%;
}
.app-comms img {
  width: 100%;
}
.dn-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 55px 0;
}
.dn-section h6 {
  width: 100%;
  color: #000000;
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  font-weight: normal;
}
.dn-section ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.dn-section ul li {
  padding: 5px 30px 0 0;
}
section.sub-free {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 60px;
  background-color: #f5f5f5;
}

.tabbings-lists.tab-margin img {
  margin-top: 80px;
}
section.tabbing-scroll {
  overflow: hidden;
}
// section.tabbing-scroll {
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   padding: 70px 0;
//   position: relative;
//   background-image: url("../../../images/home/shape-code.png");
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: 73%;
// }
.tabbing-scroll .tab-content .tab-pane {
  padding: 0 1rem 1rem;
}
.tab-lists-scroll .tab-content {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  border: 0;
  padding: 0;
}
.tab-lists-scroll a.active.nav-link {
  border: 0;
}

a.active.nav-link .right-dets h4 {
  color: #8061b3;
  font-size: 22px;
}
.head_content {
  display: block;
}

.slide_head_content {
  display: none;
}
.right-dets p {
  color: #616161;
  font-size: 15px;
  width: 100%;
  line-height: 28px;
  margin: 0;
  display: none;
}
a.active.nav-link .right-dets p {
  display: block;
}
.tab-lists-scroll li a.nav-link {
  border: 0 !important;
}
.right-dets h4:hover {
  color: #333333;
}
.left-dets {
  width: 10%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.left-dets span {
  width: 16px;
  height: 16px;
  background-color: #e3e3e3;
  border-radius: 50%;
  margin: 10px 0 0;
  position: relative;
}
a.active.nav-link .left-dets span {
  background-color: #9575cd;
}
a.active.nav-link .left-dets span:after {
  width: 34px;
  height: 34px;
  content: "";
  position: absolute;
  display: inline-block;
  background-color: rgba(149, 117, 205, 0.2);
  border-radius: 50%;
  left: -9px;
  top: -9px;
}
li.nav-item:last-child a.active.nav-link .left-dets:after {
  display: none;
}
.menu-lists ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.menu-lists ul li {
  width: 100%;
  text-align: right;
  border-bottom: 2px solid #ededed;
  padding: 24px 115px 24px 0;
}
.menu-lists ul li a img.menu-aft {
  display: none;
}
.menu-lists ul li a img {
  margin: 0px 5px 0 14px;
  max-width: 20px;
}
.menu-lists ul li a {
  color: #616161;
  font-size: 16px;
}
.menu-lists ul li a:hover {
  color: #9575cd;
}
.menu-lists ul li a:hover .menu-aft {
  display: inline-block;
}
.menu-lists ul li a:hover .menu-bef {
  display: none;
}
.menu-lists ul li.active a {
  color: #9575cd;
}
.close-menu {
  padding: 24px 115px 24px 0;
  width: 100%;
  text-align: right;
}
.close-menu img {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}
.menu-lists ul li a {
  padding: 0;
}
.fixed-body {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0000007a;
  left: 0;
  top: 0;
  display: none;
  z-index: 9;
}
.fixed-body.act-bdy {
  display: none;
}

.showing-menus.shower-menus {
  margin: 0;
}
.fixed-body.act-bdy.shower-body {
  display: block;
}
section.client-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  margin: 40px 0;
}
.clients-join {
  width: 100%;
}
.clients-join h5 {
  color: #707070;
  font-size: 20px;
  line-height: 10px;
  margin-bottom: 15px;
}
.clients-join h2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
span.left-joins {
  width: 50%;
  font-size: 90px;
  display: flex;
  color: #333333;
  font-family: "Eina03Bold";
}
span.right-joins {
  width: 50%;
}
span.right-joins {
  width: 50%;
  font-size: 30px;
  color: #707070;
  line-height: 35px;
}
.our-clients img {
  max-width: 80px;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  height: 30px;
  object-fit: contain;
}
.show-gals img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: inherit;
}
.tab-lists-scroll .tab-content .tab-pane .show-gals {
  width: 100%;
}
.tab-lists-scroll .tab-content .tab-pane {
  width: 100%;
}
li.sign-desk {
  display: none;
}

.top-clicks {
  display: none;
  width: 50px;
  height: 50px;
  background-color: #9575cd;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 49px;
  cursor: pointer;
  position: fixed;
  right: 28px;
  z-index: 9999;
  bottom: 100px;
}
body {
  // background-color: #fffef9 !important;
  background-color: #fff !important;
}
i.fa.fa-eye.view-password {
  cursor: pointer;
  // margin-left: 239px;
  // margin-top: -29px;
  // z-index: 100;
  display: none;
}
i.fa.fa-eye-slash.view-password {
  // cursor: pointer;
  // margin-left: 239px;
  // margin-top: -29px;
  // z-index: 100;
  display: none;
}
.header-sections ul li a:hover {
  color: #8061b3;
}
.header-sections a img {
  max-width: 220px;
}

h4.anim-text {
  width: 101%;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.5s ease all;
  -webkit-animation: typing 4s steps(21, end) infinite,
    blink-caret 0.5s step-end both;
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  }
  @-webkit-keyframes blink-caret {
    50% {
      border-color: transparent;
    }
  }
}
.footer-logwid ul {
  padding: 0;
  margin: 0;
  width: 100%;
}

.header-sections ul li.ags-signin a {
  border: 0.5px solid #8061b3;
  border-radius: 5px;
  color: #fff;
  padding: 8px 9px;
  background-color: #8061b3;
}
.header-sections ul li.ags-signin a:hover {
  background-color: #541cb8 !important;
  color: #fff;
  border: 0.5px solid #541cb8;
}
section.home-features {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
  background-image: url("../../../images/home/back2-scec.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.random-subs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.powerright {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.setup-lits {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  box-shadow: 0px 0px 99px #0000001a;
  border-radius: 10px;
  padding: 30px 30px 30px 60px;
  position: relative;
}
.setup-lits h4 {
  width: 100%;
  color: #000000;
  font-size: 24px;
  text-align: center;
  font-family: "Eina03SemiBold";
  margin-bottom: 70px;
  position: relative;
}
.setup-lits h4 span {
  width: 100%;
  display: block;
}
.spacealign {
  width: 50%;
  float: left;
  border-right: 1px solid #0000001a;
  margin: 0 0 55px;
}
.spacealign h5 {
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-family: "Eina03SemiBold";
  line-height: 26px;
}
.spacealign h5 span {
  width: 100%;
  display: block;
}
.spacealign img {
  margin: 0 0 20px;
}
.setup-lits .spacealign:nth-child(even) {
  border-right: 0;
  padding-left: 90px;
}
::-moz-selection {
  // background: #ffe2cc;
  background: #8061b3;
  color: #fff;
}
::selection {
  // background: #ffe2cc;
  background: #8061b3;
  color: #fff;
}
.powerright h3 {
  width: 100%;
  text-align: center;
  color: #8061b3;
  font-size: 30px;
  font-family: "Eina03SemiBold";
  margin-bottom: 46px;
  position: relative;
}
section.supercharges-mains {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.growing-charges {
  background-color: #fef9ff;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
}
.lists-clients {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  margin-top: 20px;
  padding: 0px 30px 0;
  justify-content: space-between;
}
.our-clients {
  width: 100%;
  padding: 10px 10px 0;
  // background-color: #fff;
  // box-shadow: 0px 0px 20px #0000000d;
  // border-radius: 5px;
}
.heading-styles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.heading-styles h3 {
  width: 100%;
  text-align: center;
  color: #8061b3;
  font-size: 30px;
  font-family: "Eina03SemiBold";
  padding: 0;
  position: relative;
}
.started-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.started-lists p {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #000000;
  margin: 0;
}
.footer-lists .footer-widths.last-footers li {
  display: inline-block;
  margin-right: 10px;
  width: auto;
}
span.fts-left {
  width: 10%;
}
.footer-lists .footer-widths.midls-footers {
  width: 18%;
}

.dn-section p {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.dn-section p a {
  background-color: #8061b3;
  color: #ffffff;
  font-size: 14px;
  border-radius: 2px;
  padding: 6px 22px;
}
.dn-section p a:hover {
  background-color: #541cb8;
}
.dn-section p a i {
  padding-left: 15px;
  position: relative;
  top: 1px;
}

.exclusive-slider .slick-slide.slick-active.slick-center:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/empty-mobile.png");
  transform: translateX(-50%);
  left: 50%;
  object-fit: contain;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 214px;
  height: 352px;
}
.exclusive-slider .slick-slide img {
  box-shadow: 0px 6px 16px #0000001a;
}

.height-mids {
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  padding: 0px 15px;
  position: relative;
}
.tabbings-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 80px;
}
.content-fades {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
.content-fades h4 {
  width: 90%;
  text-align: left;
  color: #8061b3;
  font-size: 20px;
  font-family: "Eina03Bold";
  margin: 20px 0;
  position: relative;
}
.tabbings-lists .content-fades p {
  width: 90%;
  text-align: left;
  color: #000000;
  font-size: 16px;
  margin: 0;
}
.img-fades {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.img-fades img {
  max-width: 100%;
}
.tabs-accrs h3 {
  width: 100%;
  text-align: center;
  position: relative;
  color: #8061b3;
  font-size: 30px;
  font-family: "Eina03SemiBold";
  margin: 15px 0 25px;
}
.started-lists a {
  background-color: #8061b3;
  color: #ffffff;
  font-size: 14px;
  border-radius: 2px;
  padding: 4px 22px;
  margin-top: 30px;
  display: inline-block;
}
.started-lists a:hover {
  background-color: #541cb8;
}
.started-lists p span {
  width: 100%;
  display: inline-block;
}
.left-banner h4.sht-list1 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #000000, #000000);
}
.left-banner h4.sht-list3 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  background: -webkit-linear-gradient(
    to bottom right,
    #25cc0f 0%,
    #9e3212 100%
  );
  background: -moz-linear-gradient(to bottom right, #25cc0f 0%, #9e3212 100%);
  background: linear-gradient(to bottom right, #25cc0f 0%, #9e3212 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
button.btn.tre-subs span {
  font-size: 12px;
  color: #c5c5c5;
}
.powerleft {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 56px;
}
.height-mids:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
  left: -136px;
}
.tabs-accrs ul.nav.nav-tabs li.nav-item a.active .height-mids:before {
  border-bottom: 1px solid #8061b3;
}

.abs-flower {
  position: absolute;
  top: -119px;
  right: 24px;
  z-index: 1;
}
.powerright h3:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 45%;
  top: 53px;
}
.setup-lits h4:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 45%;
  top: 90px;
}
.setup-lits h4:hover {
  color: #000000;
}
.heading-styles h3:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 47%;
  top: 58px;
}
.tabs-accrs h3:before {
  content: "";
  position: absolute;
  background-image: url("../../../images/home/shapes.svg");
  width: 70px;
  height: 14px;
  background-repeat: no-repeat;
  left: 47%;
  top: 58px;
}
section.supercharge-growth {
  background-image: url("../../../images/home/back3-sec.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: right 22%;
}
.absslic-docu {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  flex-wrap: wrap;
  text-align: center;
  padding: 20px 80px 0;
}
.absslic-docu p {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  color: #000000;
  font-size: 16px;
  line-height: 26px;
}
.absslic-docu a {
  background-color: #8061b3;
  color: #ffffff;
  font-size: 14px;
  border-radius: 2px;
  padding: 4px 22px;
  margin-top: 10px;
  display: inline-block;
}
.started-lists a:hover {
  background-color: #541cb8;
}
.tabbing-scroll .slick-current img {
  transform: scale(1.1);
}
.tabbing-scroll .slick-list {
  padding: 45px 60px !important;
  margin-left: 30px !important;
}
.container-cus {
  margin-left: auto;
  margin-right: auto;
  transition: padding 0.3s;
  width: 98%;
  padding: 0 0 0 100px;
}
.left-banner img {
  margin-bottom: 25px;
}
.tabbing-scroll .tab-lists-scroll .tab-content {
  background-color: transparent;
}
.tab-lists-scroll ul.slide-tabbing.nav.nav-tabs {
  position: absolute;
  border: none !important;
}
.tab-lists-scroll ul.slide-tabbing li.nav-item {
  width: 16.66%;
}
.tab-lists-scroll ul.slide-tabbing li.nav-item a {
  padding: 0;
}
.tabbing-scroll .tab-lists-scroll ul.slide-tabbing li a.active.nav-link {
  padding: 0;
}
.content-fades h4:hover {
  color: #8061b3;
}
.tabs-shss p:hover {
  color: #8061b3;
}

.started-lists a span {
  font-size: 12px;
  color: #c5c5c5;
}
.absslic-docu a span {
  font-size: 12px;
  color: #c5c5c5;
}
.absslic-docu a:hover {
  background-color: #541cb8;
}
.parll-right h4 {
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-family: "Eina03SemiBold";
  margin-bottom: 30px;
}
.parll-left img {
  max-width: 90%;
}
.absslic-docu h5 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #000000;
  font-family: "Eina03SemiBold";
  margin: 0px 0 10px;
}
.showing-menus {
  display: none;
}
ul.mobile-logins {
  display: none;
}
span.list-adop {
  font-size: 20px;
}
.tabbing-scroll .sldd-parts .slick-current img {
  transform: unset;
}
.tabbing-scroll .sldd-parts .slick-list {
  padding: 0px !important;
  margin: 0px !important;
}
.flower-parts {
  position: absolute;
  top: -119px;
  right: 19px;
}

// .exclusive-slider
//   .slick-slide.slick-active.slick-current
//   + .slick-active
//   + .slick-slide {
//   transform: scale(0.84) translate(-126px, 0px);
//   z-index: -1;
// }
div.app-comms
  .slick-center.slick-current
  + .slick-slide.slick-active
  + .slick-slide:after {
  content: "";
  position: absolute;
  width: 36px;
  height: 98%;
  right: 19px;
  z-index: 999;
  top: 0;
  background: url(../../../images/home/right-shadow.svg);
  background-repeat: no-repeat;
  left: auto;
}
.exclusive-slider .slick-slide.slick-active.slick-center:before {
  display: none;
}
.app-comms {
  position: relative;
}
.left-shadow {
  position: absolute;
  top: 0;
  z-index: 9;
  padding-top: 93px;
  left: 34px;
}
.left-shadow img {
  height: 346px;
  display: inline-block;
}
.right-shadow {
  position: absolute;
  top: 0;
  z-index: 9;
  padding-top: 93px;
  right: 4px;
}
.right-shadow img {
  height: 346px;
  display: inline-block;
}
.exclusive-slider .slick-next {
  height: 16px;
} /*----------- tabclick ----------*/
.click-dot li a {
  border: 0 !important;
  display: block;
  outline: none;
  line-height: 0;
  font-size: 0 !important;
  color: transparent;
  height: 16px !important;
  width: 16px !important;
  cursor: pointer;
  background-color: transparent !important;
  border-radius: 50% !important;
  z-index: 9;
  padding: 0 !important;
  margin: 0 6px;
}
.click-dot li a.active p:before {
  content: "";
  background-image: linear-gradient(#f7d323, white);
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  z-index: -9 !important;
  top: -5px;
  left: 2px;
  position: absolute;
}
.click-dot li a.active.nav-link p {
  background-color: #8061b3 !important;
}
.click-dot li a p {
  border: 0 !important;
  display: block;
  outline: none;
  line-height: 0;
  font-size: 0 !important;
  color: transparent;
  height: 16px !important;
  width: 16px !important;
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 50% !important;
  z-index: 9;
  padding: 0 !important;
  margin: 0 6px;
}
.click-dot li.nav-item a:before {
  display: none;
}
.shift-cases ul.nav.nav-tabs li a.active:before {
  z-index: 9;
}
.shiftstabing .tab-pane.active {
  animation-name: fadeIn;
  animation-duration: 800ms;
  animation-timing-function: linear;
}
.tabbing-scroll .tab-pane.active {
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: linear;
}
.shiftstabing .tab-pane,
.tabbing-scroll .tab-pane {
  position: relative;
}
@keyframes fadeIn {
  //     from {left: 1000px;}
  //    to {left: 0px;}
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.shiftstabing .tab-content {
  overflow: hidden;
  position: relative;
}
.click-dot {
  position: absolute;
  top: 56%;
  left: 12em;
  right: 0;
  width: 149px;
  margin: 0 auto;
  z-index: 1;
}
.tabbing-scroll .crls-sec span {
  width: 14px;
  height: 14px;
  display: inline-block;
  background-color: transparent;
  opacity: 1;
  border-radius: 50%;
  padding: 12px;
  top: -15px;
  position: relative;
} /*----------- end tabclick ----------*/
@media screen and (max-width: 767px) {
  .app-comms .slick-list:before {
    width: 223px;
    height: 413px;
    left: -9px;
    top: 8px;
  }
  .right-shadow,
  .left-shadow {
    display: none;
  }

  .exclusive-slider .slick-slide {
    top: 0 !important;
  }

  .app-comms {
    padding-top: 0;
  }
  // .exclusive-slider .slick-prev {
  //   left: 34%;
  // }
  // .exclusive-slider .slick-next {
  //   left: 56%;
  // }
  .click-dot {
    width: 100%;
    left: 0;
    text-align: center;
    top: auto;
    bottom: -12px;
  }
  .click-dot li {
    display: inline-block !important;
    width: auto !important;
  }
  .click-dot ul.nav.nav-tabs {
    justify-content: center;
  }
  .free-offers {
    width: 100%;
  }
  .sigfr-width .left-subs {
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .left-shadow,
  .right-shadow {
    display: none;
  }
  .app-comms .slick-list:before {
    content: "";
    width: 277px;
    height: 402px;
    left: -13px;
    top: 6px;
  }
  .exclusive-slider .slick-slide {
    opacity: 0;
  }
  .exclusive-slider .slick-slide.slick-active.slick-center {
    opacity: 1;
  }
  .click-dot {
    top: 80%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .app-comms .slick-list:before {
    height: 485px;
  }
  .exclusive-slider .slick-slide {
    transform: scale(0.84) translate(0px, 0px);
  }
  .left-shadow,
  .right-shadow {
    display: none;
  }
  .click-dot {
    top: 52%;
  }
  .click-dot li.nav-item {
    width: auto !important;
  }
}
.hensive-billing .right-brns {
  width: 100%;
  text-align: center;
}

.try-free button {
  background: #8061b3;
  font-family: "Eina03SemiBold";
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  width: 192px;
  // padding: 8px 0;
  padding: 12px 0;
  display: inline-block;
  transition: 0.2s;
  letter-spacing: 0.8px;
}
.try-free button a {
  color: #ffffff;
}
.try-free button:hover {
  color: #ffffff;
  background-color: #541cb8;
  transition: all 0.3s;
}
.try-free button.btn.demo-but {
  background: transparent;
  color: black;
}
.cookie {
  position: fixed;
  background: #e8e1f3;
  bottom: 0;
  z-index: 99999999;
  width: 100%;
  text-align: center;
  padding: 21px 0;
}
.cookie-img {
  font-size: 14px;
  display: flex;
  justify-content: center;
  width: 629px;
  margin: 0 auto;
}
.cookie-img img {
  width: 35px;
}
.cookie-img p {
  margin: 0px 10px;
  line-height: 20px;
  font-size: 16px;
  font-family: "Eina03Regular";
}
.cookie button {
  height: 32px;
  width: 100px;
  border-radius: 25px;
  border: none;
  background: #8061b3;
  color: #fff;
  line-height: 25px;
  padding: 4px 6px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin: 5px auto;
}
span.close-but i {
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
}
span.close-but {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 26px;
  height: 26px;
  border-radius: 25px;
  border: 1px solid #b53535;
  background: #b53535;
  line-height: normal;
  cursor: pointer;
}
.cookie {
  width: 75%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
// New-Changes
.ttc {
  text-transform: capitalize;
}
.pmb_sec {
  background-color: #f6fafd;
  padding: 20px;
  border-radius: 15px;
}
.pmb h6 {
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  color: #000;
}
.pmb .rowed .powersec {
  color: #000;
}
.pmb .rowed .powersec img {
  margin-right: 10px;
}
.pmb .rowed {
  display: flex;
  justify-content: space-around;
}
.tab-lists-scroll ul.nav.nav-tabs {
  display: block;
}

// .tabbing-scroll
//   .tab-lists-scroll
//   :where(li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6))
//   a.active.nav-link {
//   height: 250px !important;
// }
.tabbing-scroll .tab-lists-scroll li.nav-item {
  border-bottom: 1px solid #ccc;
}
.tabbing-scroll a.active.nav-link .right-dets h4 {
  font-family: "Eina03SemiBold";
  position: relative;
}
.imag_tittle {
  display: flex;
  gap: 10px;
  padding: 0 0 10px;
  align-items: center;
}
.tab-lists-scroll {
  // width: 100%;
  display: flex;
  // flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
}
section.home-banner {
  width: 100%;
  position: relative;
}
.tabbing-scroll .right-dets h4 {
  color: #000000;
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  text-transform: capitalize;
  padding: 0;
  white-space: nowrap;
}
.head_content h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: none;
}
.head_content p {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.tabbing-scroll .tab-lists-scroll li a.nav-link .imag_tittle img {
  display: none;
}
.head_content img {
  max-width: 111%;
  position: relative;
  z-index: 1;
  left: -10px;
  display: none;
}
a.active.nav-link .right-dets .head_content img {
  display: block;
}
.tabbing-scroll .tab-lists-scroll li a.active.nav-link .imag_tittle img {
  display: block;
}
.right-dets .vilot_line {
  max-width: 108%;
  position: relative;
  z-index: 1;
}
.head_content_alt img {
  top: 0;
}
.right-dets:has(.head_content_alt) .imag_tittle {
  margin: 0;
}
.nav > li > a:focus,
.nav-tabs > li > a:hover {
  background: none !important;
}
.nav-tabs {
  border-bottom: 1px solid #818a9100;
}
.right-mannel {
  text-align: center;
}
.right-mannel h4 {
  font-size: 28px;
  color: #000;
  line-height: 26px;
  margin-bottom: 19px;
  font-weight: 500;
}
.right-mannel p {
  width: 600px;
  margin: auto;
  font-size: 16px;
  color: #000;
}
.clients-cates {
  display: flex;
  align-items: start;
}
.clients-cates img {
  margin-right: 15px;
}
.clients-cates h3 {
  width: 100%;
  color: #000000;
  font-family: "Eina03Bold";
  font-size: 34px;
  position: relative;
  line-height: inherit;
}
.clients-cates p {
  width: 100%;
  color: #000000;
  font-size: 16px;
  margin: 0;
}
.clients-mains {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
}

.right-mannel {
  position: relative;
}
.right-mannel img {
  position: absolute;
  top: -20px;
  right: 31%;
}
// Adopting_agency_portal Start
.adopting_agency_portal .get_chat button {
  width: 170px;
  font-size: 14px;
}
.adopting_agency_portal .get_chat button:nth-child(1) {
  background-color: rgb(128, 97, 179);
  color: rgb(255, 255, 255);
}
.adopting_agency_portal .get_chat button:nth-child(1) a:hover,
.adopting_agency_portal .get_chat button:nth-child(1) a {
  color: #fff;
}
.adopting_agency_portal .get_chat button:nth-child(2) {
  border: 1px solid #76bdff;
  margin-left: 15px;
}
.adopting_agency_portal h6 {
  font-size: 22px;
  font-weight: 100;
}
.adopting_agency_portal h5 strong {
  font-weight: 900;
}
.adopting_agency_portal h5 {
  font-size: 28px;
  width: 420px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 18px;
  line-height: 48px;
}
.adopting_agency_portal .rowed {
  display: flex;
  align-items: center;
}
.rowed {
  .aap_user {
    background-color: #fff3da;
    position: absolute;
    right: 0;
    padding: 12px;
    border-radius: 150px 0 0 150px;
    display: flex;
    gap: 10px;
    .app_user {
      min-width: 24%;
      position: relative;
      .user_info {
        position: absolute;
        background: #fff;
        top: -130%;
        width: 350px;
        right: 60px;
        padding: 30px 20px 20px;
        border-radius: 20px;
        box-shadow: 0px 0px 2px #ccd4e0;
        border: 3px solid #ccd4e0;
        display: none;
        .quote-before {
          position: absolute;
          width: 20px;
          height: 20px;
          right: 10px;
          bottom: 10px;
        }
        .quote-after {
          position: absolute;
          width: 20px;
          height: 20px;
          left: 10px;
          top: 7px;
          transform: rotate(180deg);
        }
        p {
          font-size: 15px;
          line-height: 28px;
          color: #000;
          font-style: italic;
          font-weight: 500;
          min-height: 100px;
          position: relative;
        }
        h6 {
          font-size: 16px;
          font-weight: 600;
          color: #000;
          line-height: 21px;
          span {
            display: block;
            font-size: 12px;
            font-weight: 400;
          }
        }
        &::after {
          content: "";
          position: absolute;
          right: 25px;
          bottom: -10px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 10px solid #fff;
        }
        &::before {
          content: "";
          position: absolute;
          right: 21px;
          bottom: -15px;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 14px solid #ccd4e0;
        }
      }

      .user_who {
        width: 175px;
        height: 175px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      &:hover {
        .user_info {
          display: block;
        }
      }
      &:hover {
        .user_who {
          border: 2px solid #4a3a2f;
          // padding: 3px;
        }
      }
    }
  }
}

.adopting_agency_portal {
  position: relative;
}
// Adopting_agency_portal End

//Slider Start

.client-lists {
  width: 500px;
}

.section.exclusive-slider.slider .app-informations .app-comms {
  padding: 0;
}
// .exclusive-slider.slider .slick-slide {
//   width: 560px !important;
// }

// .exclusive-slider.slider .slick-track {
//   width: 6800px !important;
// }
.app-informations {
  width: 100%;
  background: #f4fff7;
  padding: 30px 0;
  border-radius: 10px;
}
.app-informations {
  width: 100%;
  background: #f4fff7;
  padding: 30px 0;
  border-radius: 10px;
}
.excul_lists {
  outline: none;
  width: 90% !important;
  margin: 0 auto;
  display: block !important;
  padding: 30px 0 0;
}
.excul_lists_alt {
  padding: 0;
}
.client_list_img {
  width: 100%;
  height: 275px;
  overflow: hidden;
  border-radius: 10px;
}

.client_list_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.exclusive-slider.slider .excul_lists h4 {
  font-size: 24px;
  color: #000;
  margin-top: 14px;
}

.exclusive-slider.slider .excul_lists p {
  font-size: 17.8px;
  color: #000;
  margin-top: 14px;
}

.app-comms .slick-dots li {
  width: 25px;
  height: 5px;
  margin: 0;
}
.app-comms .slick-dots li button {
  background: #d7d7d7;
  width: 25px;
  height: 5px;
  padding: 0px;
}
.app-comms .slick-dots li:first-child button {
  border-radius: 25px 0 0 25px;
}
.app-comms .slick-dots li:last-child button {
  border-radius: 0 25px 25px 0;
}
.app-comms .slick-dots li.slick-active button {
  background: #9575cd;
}

.app-comms .slick-dots li button:before {
  content: "";
  width: 25px;
  height: 5px;
  display: block;
}

.app-comms .slick-dots {
  position: relative;
  bottom: -20px;
}
.app-comms .slick-slider .slick-arrow {
  width: 20px;
  height: 20px;
  margin: 10px;
  border-top: 2px solid #422c68;
  border-left: 2px solid #422c68;
  font-size: 0;
  z-index: 1;
  &.slick-prev {
    left: -35px;
    transform: rotate(-45deg);
  }
  &.slick-next {
    right: -35px;
    transform: rotate(135deg);
  }
}

// .exclusive-slider .slick-prev {
//   background-color: #4234bd;
//   top: 100%;
//   left: 52%;
//   width: 22px;
//   position: absolute;
//   z-index: 1;
//   height: 16px;
// }
// .exclusive-slider .slick-next {
//   background-color: #ccc;
//   top: 100%;
//   left: 50%;
//   width: 22px;
//   position: absolute;
// }

// .exclusive-slider .slick-next:focus,
// .exclusive-slider .slick-next:hover,
// .exclusive-slider .slick-prev:focus,
// .exclusive-slider .slick-prev:hover {
//   background: #4234bd !important;
//   color: transparent;
//   outline: none;
// }
// .exclusive-slider .slick-prev,
// .exclusive-slider .slick-next {
//   height: 3px;
//   border-radius: 5px;
// }
.exclusive-slider .slick-slide:nth-child(odd) {
  top: 0px;
  position: relative;
}
.exclusive-slider .slick-slide:nth-child(even) {
  position: relative;
  top: 20px;
}
//Slider End

// five_card Start
.five_card .card_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.five_card .card:hover {
  box-shadow: 0px 0px 36px rgba(125, 147, 178, 0.25);
}
.five_card .card {
  padding: 20px !important;
  width: 32%;
  height: auto;
  min-height: 290px;
  margin: 0;
  border: none;
  box-shadow: none;
  overflow: hidden;
  background: #f6fafd;
}
.five_card .card img {
  width: 40px;
}
.five_card .card p {
  margin: 0;
  color: #000;
  font-size: 14px;
  line-height: 27px;
}

.card-hidd {
  position: absolute;
  inset: 0;
  width: 100%;
  transform: translateY(75%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.4s, opacity 0.1s 0.3s;
  padding: 0 20px !important;
}
.card-hidd h6 {
  color: #000;
  line-height: 26px;
  margin: 5px 0 10px;
  font-size: 15px;
  transition: 0.4s ease-in;
}
.card-hidd p {
  opacity: 0;
  transition: 0.4s ease-in;
}
.five_card .card:hover {
  background: #fff;
}
.five_card .card:hover .card-hidd {
  transform: translateY(65px);
  transition: transform 0.4s, opacity 0.1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.five_card .card:hover .card-hidd h6 {
  color: #9575cd;
  transition: 0.4s ease-in;
}
.five_card .card:hover .card-hidd p {
  opacity: 1;
  transition: 0.4s ease-in;
}
// five_card End

// Exclusive App For Employees Start

.exclusive-apps .exc-app-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f9f3ee;
  padding: 20px 60px 0;
  border-radius: 10px;
  position: relative;
}
.exclusive-apps .app-details {
  text-align: center;
}
.exclusive-apps .app-details h3 {
  color: #000;
  font-size: 32px;
}

.exclusive-apps .app-details p {
  font-size: 16px;
  color: #000000;
  line-height: 28px;
  margin: 0;
  padding: 0;
}
.exclusive-apps .app-details button {
  background-color: #5f4b95;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 15px;
  transition: all 0.3s;
}
.exclusive-apps .app-details button:hover {
  background-color: #541cb8;
  transition: all 0.3s;
}
.exclusive-apps .app-details button:hover a {
  color: #fff;
}
.app_phone {
  margin: auto;
  position: relative;
}
// .app_play {
//   position: absolute;
//   right: 20px;
//   bottom: 14px;
// }
.app_play h6 {
  color: #000;
  font-size: 22px;
}
.app_play img {
  margin-right: 15px;
}
// Exclusive App For Employees End

// Footer Start
section.footer-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 50px 0 0 0;
  border-top: 1px solid #ccc;
}
.footer-logwid ul li {
  color: #000000;
  font-size: 14px;
}
// .footer-widths ul li a:hover {
//   color: #d4d4d4;
// }
.footer-logwid ul li span {
  display: inline-table;
}
.footer-widths ul li a {
  color: #000000;
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 0;
}
.footer-lists .footer-widths {
  display: flex;
  flex-wrap: wrap;
}
.footer-widths h5 {
  font-size: 16px;
  color: #000000;
  margin: 0;
}



.powered_tt {
  border-top: none !important;
  padding: 0 !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  color: #000 !important;
  letter-spacing: unset !important;
  a {
      color: #000 !important;
      &:hover {
          color: #000 !important;text-decoration: none !important;
      }
  }
}
// Footer End
.tabbing-scroll .tab-lists-scroll li a.nav-link {
  opacity: 0.5;
}

.tabbing-scroll .tab-lists-scroll li a.nav-link {
  padding: 0.5rem 8px;
  height: 40px;
}
.tabbing-scroll .tab-lists-scroll li a.active.nav-link {
  opacity: 1;
  height: auto;
  overflow: visible;
}

.header-sections {
  ul {
    li {
      .dropdown {
        .dropdown-toggle {
          padding: 0;
          background: transparent;
          border: 0;
          a {
            height: 24px;
            display: inline-flex;
          }
          &:active {
            background: transparent;
            border: 0;
          }
          &::after {
            border-top: 0.3em solid #585858;
            margin: 0 5px 0 -3px;
          }
        }
        &.show {
          padding: 0 !important;
          .dropdown-menu {
            padding: 5px 0 !important;
            .dropdown-item {
              border: none;
              font-size: 14px;
              padding: 0;
              a{
                width: 100%;
                display: block;
                padding: 10px 20px;
                font-size: 15px;
                color: #525252;
              }
              &.active{
                background: transparent;
                a{
                  color: #8061b3;
                }
              }
              &:active {
                background: #8061b3;
                a{
                  color: #fff;
                }
              }
            }
          }
       
        }
        &:has(.dropdown-menu .dropdown-item.active){
          .dropdown-toggle{
            a{
              color: #8061b3;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 3000px) {
  .rowed .aap_user,
  .right_banners {
    position: relative;
  }
  .rowed .aap_user .app_user .user_who {
    width: 160px;
    height: 160px;
  }
}
@media screen and (max-width: 1024px) {
  .rowed .aap_user .app_user .user_info {
    right: -75px;
  }
  .rowed .aap_user .app_user .user_info::after,
  .rowed .aap_user .app_user .user_info::before {
    right: 50%;
    transform: translateX(50%);
  }
}
@media screen and (max-width: 767px) {
  .exclusive-apps .exc-app-info {
    padding: 20px 20px 0;
  }

  .clients-mains {
    width: 100%;
    margin: 20px 0;
  }
  .clients-cates img {
    margin: 0;
  }
  .growing-charges .clients-cates {
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin: 0 0 10px;
  }
  .growing-charges .clients-cates h3 {
    font-size: 25px;
  }
  .try-free {
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .banner-parts .try-free button.btn.tryforfree {
    margin: 0;
  }

  .tabbing-scroll .tab-lists-scroll li a.active.nav-link .imag_tittle img {
    margin-right: 0;
  }
  a.active.nav-link .right-dets h4 {
    font-size: 16px;
  }
  .right-mannel img {
    right: 0;
  }
  .right-mannel p {
    width: 95%;
  }
}

@media screen and (max-width: 990px) {
  .right_banners {
    width: 75%;
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .tabbing-scroll a.active.nav-link .right-dets h4 {
    font-size: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .app_play {
    position: relative;
    inset: 0;
    text-align: center;
    width: 100%;
    padding: 10px 0 20px;
  }
}
