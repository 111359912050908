.knowledge-banner {
  width: 100%;
  padding: 25px 0;
}

.knowledge-banner .row {
  align-items: center;
}

.banner-info h2 {
  color: #424242;
  font-size: 58px;
  margin: 0 0 10px;
  line-height: 1;
}

.banner-info h2 span {
  display: block;
  color: #5f4b95;
  font-size: 40px;
}

.banner-info p {
  color: #424242;
  font-size: 22px;
  width: 90%;
}

.search {
  width: 80%;
  background: #fafafa;
  border: 0.5px solid #e0e0e0;
  border-radius: 50px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.search input {
  width: 100%;
  border: none;
  background: transparent;
  box-shadow: none;
  outline: none;
}
.search button {
  border: none;
  background: none;
}

.banner-img,
.banner-img img {
  width: 100%;
}

/*********Tab************/

.knowledge-tab {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 40px;
  height: 450px;
  overflow-y: auto;
}

.tab-btns {
  width: 25%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tab-contents {
  width: 74%;
  position: sticky;
  height: 450px;
  top: 0;
  overflow-y: auto;
}
.tab-btns, .tab-contents {
  padding: 15px;
  background: #f5f5f5;
  border-radius: 8px;
}
.tab-contents::-webkit-scrollbar {
  width: 0;
}
.tab-btns button {
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 8px;
  padding: 15px;
  text-align: left;
  color: #424242;
  font-size: 16px;
  font-weight: 500;
}

.tab-btns button p {
  margin: 5px 0 0;
  color: #9e9e9e;
  font-size: 13px;
}

.tab-btns button.active {
  background: #5f4b95;
  color: #fff;
}

.tab-btns button.active p {
  color: #fff;
}

.tab-infos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
}
.no-added {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
.tab-info {
  width: 32%;
}

.tab-info .tab-btn {
  width: 100%;
  min-height: 150px;
  padding: 15px;
  background: #fff;
  border: none;
  border-radius: 8px;
  opacity: 1;
  flex: 0 0 32%;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  transition: 0.2s ease-in;
}

.tab-info .tab-btn:hover {
  box-shadow: 1px 2px 5px #dfdfdf;
  transition: 0.2s ease-in;
}

.tab-info .tab-btn h6 {
  color: #424242;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 10px;
  text-align: left;
  min-height: 50px;
}

.tab-info .tab-btn p {
  color: #616161;
  font-size: 13px;
  margin: auto 0 10px;
  word-break: break-word;
  text-align: left;
  line-height: 18px;
}

.tab-info .tab-btn p:last-child {
  margin: auto 0 0;
}

.tab-info p:last-child span {
  background: #9e9e9e;
  padding: 5px 10px;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  font-size: 11px;
}

/*********Inner-Tab************/
.inner-tab {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0 40px;
}

.inner-head {
  width: 100%;
}

.inner-head button {
  background: transparent;
  color: #9e9e9e;
  font-size: 16px;
  border: none;
  padding-inline: 0;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 5px;
}

.inner-head h6 {
  font-size: 24px;
  color: #424242;
}

.inner-head p {
  color: #616161;
  font-size: 16px;
}

.inner-btns {
  width: 25%;
  height: 520px;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  overflow: auto;
}

.inner-btns::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.inner-btns::-webkit-scrollbar-button {
  height: auto;
}

.inner-btns::-webkit-scrollbar-thumb {
  background: #5f4b95;
  border-radius: 10px;
}

.inner-btns::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.inner-btns button {
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 5px 10px 10px;
  text-align: left;
  color: #424242;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1.5px solid #cbd5e0;
}

.inner-btns button img {
  display: none;
}

.inner-btns button p {
  margin: 5px 0 0;
  color: #9e9e9e;
  font-size: 14px;
  display: none;
}

.inner-btns button.active {
  color: #5f4b95;
  font-weight: 500;
  border-bottom: 1.5px solid #5f4b95;
}

.inner-btns button.active img {
  display: block;
  margin: 0 0 10px;
}

.inner-btns button.active p {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inner-content {
  width: 74%;
  padding: 0 15px;
  background: #fff;
  border-radius: 8px;
}

.inner-contents,
.inner-cont {
  width: 100%;
}

.inner-cont h6 {
  color: #424242;
  font-size: 16px;
}

.inner-cont p {
  color: #424242;
  font-size: 14px;
}

.inner-video {
  width: 100%;
  height: 435px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.inner-videobanner {
  width: 100%;
  height: 328px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}

.view_pdf_updateon button {
  color: #fff;
  border: none;
  background-color: #5f4b95;
  border-radius: 3px;
  font-size: 13px;
  padding: 8px 10px;
}
.view_pdf_updateon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media screen and (min-width: 992px) and (max-width: 1023px) {
  .inner-tab {
    margin: 70px 0 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tab-info {
    width: 48%;
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 767px) {
  .knowledge-banner {
    padding: 20px 0;
  }
  .inner-videobanner {
    height: auto;
  }
  .inner-btns,
  .tab-btns {
    width: 100%;
    height: auto;
    white-space: pre;
    flex-direction: row;
    overflow: auto;
    margin: 0 0 20px;
  }

  .inner-btns::-webkit-scrollbar,
  .tab-btns::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .inner-btns::-webkit-scrollbar-button,
  .tab-btns::-webkit-scrollbar-button {
    height: auto;
  }

  .inner-btns::-webkit-scrollbar-thumb,
  .tab-btns::-webkit-scrollbar-thumb {
    background: #5f4b95;
    border-radius: 10px;
  }

  .inner-btns::-webkit-scrollbar-track,
  .tab-btns::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px gray;
    border-radius: 10px;
  }

  .inner-content,
  .tab-contents {
    width: 100%;
  }

  .tab-info {
    width: 48%;
    flex: 0 0 48%;
  }

  .inner-video {
    width: 100%;
    height: 250px;
  }
  .view_pdf_updateon {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .banner-info h2 {
    font-size: 40px;
  }

  .banner-info h2 span {
    font-size: 30px;
  }

  .banner-info p {
    font-size: 18px;
    width: 100%;
  }

  .search {
    width: 100%;
    gap: 10px;
  }

  .tab-info {
    width: 100%;
    flex: 1 1 100%;
    min-height: auto;
  }
}
